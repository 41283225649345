/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import _ from 'lodash';
import {
  createPersonalAccessToken,
  loadPersonalAccessTokenList,
  revokePersonalAccessToken,
} from '@/modules/personalAccessToken/services';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { RootState } from '@/store/type';
import { IError, LoadingState, SavingState } from '@/modules/shared/types/state.type';
import { ActionContext } from 'vuex';
import { PersonalAccessToken, PersonalAccessTokenState } from '../types';

type PersonalAccessTokenActionContext = ActionContext<PersonalAccessTokenState, RootState>

const initialState = () => ({
  revokePersonalAccessToken: null,
  newPersonalAccessToken: {
    name: null,
  },
  personalAccessTokens: [],
  isPersonalAccessTokensModalShowing: false,
  loadPersonalAccessTokensState: setStateLoadingStatusByType(),
  createPersonalAccessTokenState: setStateSavingStatusByType(),
  updatePersonalAccessTokenState: setStateSavingStatusByType(),
  validateError: {
  },
});

const state = initialState();

const getters = {
  isCreatePersonalAccessTokenValid: (state: PersonalAccessTokenState) => {
    if (state.createPersonalAccessTokenState.status === 'saving') {
      return false;
    }
    return state.newPersonalAccessToken.name;
  },
};

const mutations = {
  updateNewPersonalAccessToken(state: PersonalAccessTokenState, personalAccessToken: PersonalAccessToken) {
    state.newPersonalAccessToken = personalAccessToken;
  },
  setLoadPersonalAccessTokenState(state: PersonalAccessTokenState, loadingState: LoadingState) {
    state.loadPersonalAccessTokensState = setStateLoadingStatusByType(loadingState);
  },
  setCreatePersonalAccessTokenState(state: PersonalAccessTokenState, savingState: SavingState) {
    state.createPersonalAccessTokenState = setStateSavingStatusByType(savingState);
  },
  setUploadPersonalAccessTokenState(state: PersonalAccessTokenState, savingState: SavingState) {
    state.updatePersonalAccessTokenState = setStateSavingStatusByType(savingState);
  },
  setPersonalAccessTokens(state: PersonalAccessTokenState, personalAccessTokens: PersonalAccessToken[]) {
    state.personalAccessTokens = personalAccessTokens;
  },
  updatePersonalAccessTokens(state: PersonalAccessTokenState, personalAccessToken: PersonalAccessToken) {
    state.personalAccessTokens = [...state.personalAccessTokens, personalAccessToken];
  },
  setRevokePersonalAccessToken(state: PersonalAccessTokenState, personalAccessToken: PersonalAccessToken) {
    state.revokePersonalAccessToken = personalAccessToken;
  },
  revokePersonalAccessToken(state: PersonalAccessTokenState) {
    if (state.revokePersonalAccessToken) {
      const removeIndex = state.personalAccessTokens.indexOf(state.revokePersonalAccessToken);
      state.personalAccessTokens.splice(removeIndex, 1);
      state.personalAccessTokens = [...state.personalAccessTokens];
    }
  },
  clearRevokePersonalAccessToken(state: PersonalAccessTokenState) {
    state.revokePersonalAccessToken = null;
  },
  setValidateError(state: PersonalAccessTokenState, error: IError) {
    state.validateError = error;
  },
  clearValidateError(state: PersonalAccessTokenState) {
    state.validateError = {};
  },
};

const actions = {
  async loadPersonalAccessTokens({ commit }: PersonalAccessTokenActionContext) {
    commit('setLoadPersonalAccessTokenState', { type: 'loading' });
    try {
      const response = await loadPersonalAccessTokenList();
      commit('setPersonalAccessTokens', response.data);
      commit('setLoadPersonalAccessTokenState', { type: 'success' });
    } catch (error) {
      commit('setLoadPersonalAccessTokenState', { type: 'error', error: formatErrorObject(error, 'Personal Access Token') });
    }
  },
  updateNewPersonalAccessToken({ commit }: PersonalAccessTokenActionContext, personalAccessToken: PersonalAccessToken) {
    commit('updateNewPersonalAccessToken', personalAccessToken);
  },
  async createPersonalAccessToken({ commit, state }: PersonalAccessTokenActionContext) {
    commit('setCreatePersonalAccessTokenState', { type: 'saving' });
    commit('clearValidateError');
    try {
      const response = await createPersonalAccessToken(state.newPersonalAccessToken);
      commit('updateNewPersonalAccessToken', response.data);
      commit('updatePersonalAccessTokens', response.data);
      commit('setCreatePersonalAccessTokenState', { type: 'success' });
    } catch (error: any) {
      commit('setCreatePersonalAccessTokenState', { type: 'error', error: formatErrorObject(error, 'Personal Access Token') });
      commit('setValidateError', error?.response.data);
    }
  },
  backToCreateNewPersonalAccessToken({ commit }: PersonalAccessTokenActionContext) {
    commit('setCreatePersonalAccessTokenState');
    commit('updateNewPersonalAccessToken', { name: null });
  },
  setRevokePersonalAccessToken({ commit }: PersonalAccessTokenActionContext, personalAccessToken: PersonalAccessToken) {
    commit('setRevokePersonalAccessToken', personalAccessToken);
  },
  async revokePersonalAccessToken({ commit, dispatch, state }: PersonalAccessTokenActionContext) {
    commit('setUploadPersonalAccessTokenState', { type: 'saving' });

    const token = _.get(state.revokePersonalAccessToken, 'token');

    try {
      if (token) {
        await revokePersonalAccessToken({ token });
        // check if revokePersonalAccessToken is same with newPersonalAccessToken, then clear NewPersonalAccessToken
        if (token === state.newPersonalAccessToken.token) {
          await dispatch('backToCreateNewPersonalAccessToken');
        }

        commit('revokePersonalAccessToken', token);
        // clear revokePersonalAccessToken
        commit('clearRevokePersonalAccessToken');
        commit('setUploadPersonalAccessTokenState', { type: 'success' });
      }
    } catch (error) {
      commit('setUploadPersonalAccessTokenState', { type: 'error', error: formatErrorObject(error, 'Personal Access Token') });
    }
  },
  clearRevokePersonalAccessToken({ commit }: PersonalAccessTokenActionContext) {
    commit('clearRevokePersonalAccessToken');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
