import type { GlobalState } from '@/store/type';
import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import type { ProjectState } from '@/modules/shared/types/project.type';
import type { ProjectListState } from '@/modules/projectList/types/projectList';
import type { WebhookListState } from '@/modules/webhook';

export const useStore = () => baseUseStore<{
  global: GlobalState;
  project: ProjectState;
  projectList: ProjectListState;
  webhookList: WebhookListState;
}>(storeKey);
