import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "user-role" }
const _hoisted_3 = { class: "option-description" }
const _hoisted_4 = { class: "user-container" }
const _hoisted_5 = { class: "feedback-container" }
const _hoisted_6 = {
  key: 0,
  class: "invalid-email-text"
}
const _hoisted_7 = { key: 1 }

import {
  getMembershipRolesOptions, isMemberInList,
  mappingByteArkAccountToStreamAccount,
} from '@/modules/projectMember/utils';
import type { ObjectWithStringValue } from '@/modules/shared/types/index.type';
import _ from 'lodash';
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import FormLabel from '@/modules/shared/components/molecules/formLabel/FormLabel.vue';
import type { InputMember, MembershipPayload, ProjectMember } from '@/modules/projectMember/types';
import type { LoadingState, StatusState } from '@/modules/shared/types/state.type';
import { emailRgeEx } from '@/modules/shared/utils/validator';
import InviteMemberTagsInput from '@/modules/projectMember/components/molecules/inviteMemberTagsInput/InviteMemberTagsInput.vue';
import { loadTeamMembers as loadTeamMembersAPI } from '@/modules/projectMember';
import PreLoaderCard from '@/modules/shared/components/atoms/preLoaderCard/PreLoaderCard.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import FormSelectOption from '@/modules/shared/components/molecules/formSelectOption/FormSelectOption.vue';
import { useStore } from '../../../store/hooks';

interface AddProjectManageMembershipModalProps {
  memberships: ProjectMember[] | null;
  onCloseModal: () => void;
  onSubmit: (members: MembershipPayload[]) => Promise<void>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddProjectMemberModal',
  props: {
    memberships: {},
    onCloseModal: { type: Function },
    onSubmit: { type: Function }
  },
  setup(__props: any) {

const props = __props;
const { memberships, onCloseModal, onSubmit } = toRefs(props);

const { t } = useI18n();
const store = useStore();

const roleOptions = getMembershipRolesOptions();
const roleDescription: ObjectWithStringValue = {
  'project-manager': t('project.member.role.projectManager.description'),
  uploader: t('project.member.role.uploader.description'),
  viewer: t('project.member.role.viewer.description'),
};

const selectedMembers = ref<InputMember[]>([]);
const loadWorkspaceMembersState = ref<StatusState>({
  status: 'idle',
  error: null,
});
const selectedRoleMember = ref<ObjectWithStringValue | null>(null);
const workspaceMembers = ref<InputMember[]>([]);
const membersThatAreNotInsideThisProject = ref<InputMember[]>([]);
const membersThatAreInsideThisProject = ref<InputMember[]>([]);
const validMembers = ref<InputMember[]>([]);
const isShowScrollShadow = ref<boolean>(false);

const inviteProjectMembershipsState = computed(() => store.state.projectMember.inviteProjectMembershipsState);
const updateProjectMembershipsState = computed(() => store.state.projectMember.updateProjectMembershipsState);
const currentUserWorkspaceName = computed(() => store.state.user.currentUser?.team?.name ?? '');

function getLoadProjectErrorMessage(errorStatus: string) {
  switch (errorStatus) {
    case '403':
      return {
        statusCode: errorStatus,
        title: t('response:error403.title'),
      };
    case '404':
      return {
        statusCode: errorStatus,
        icon: 'fas fa-circle-exclamation',
        title: t('response:error404.title'),
        description: `${t('response:error404.helpText')}
          <a href="mailto:support@byteark.com" target="_top">support@byteark.com</a>
        `,
      };
    default:
      return {
        statusCode: errorStatus,
        icon: 'fas fa-circle-exclamation',
        title: t('response:loadingFailed.title'),
        description: `${t('response:loadingFailed.recheckURL')}
          <a href="mailto:support@byteark.com" target="_top">support@byteark.com</a>
        `,
      };
  }
}

const loadWorkspaceMembersErrorMessage = computed(() => {
  const statusCode = _.get(loadWorkspaceMembersState.value, 'error.response.status', '');
  return getLoadProjectErrorMessage(statusCode);
});

const invalidEmails = computed(() => (
  selectedMembers.value
    .filter((member) => {
      const email = member.account?.email ?? member.text ?? '';
      return !emailRgeEx.test(email) || membersThatAreInsideThisProject.value.find((m) => m.account?.email === member.text) !== undefined;
    })
    .map((member) => member.account?.email ?? member.text)
));

const isAddProjectMembershipValid = computed(() => {
  if (updateProjectMembershipsState.value.status === 'loading') {
    return false;
  }

  if (invalidEmails.value.length > 0) {
    return false;
  }

  if (selectedMembers.value) {
    return selectedMembers.value.length > 0 && !_.isEmpty(selectedRoleMember.value);
  }

  return false;
});

function checkIfIsNotAWorkspaceMember(member: InputMember) {
  // Use keys to add an email || Use autocomplete item to add an email
  return member.notAWorkspaceMember === true || (!member.notAWorkspaceMember && workspaceMembers.value.find((wm) => wm.account?.email === member.text) === undefined);
}

const nonMemberEmails = computed(() => (
  validMembers.value
    .filter(checkIfIsNotAWorkspaceMember)
    .map((member) => member.text)
));

const projectAccessLevelSubtitle = computed(() => (
  `${t('project.manage.members.projectRole.description1')} <a class='doc-link' target="_blank" href='https://docs.byteark.com/th/stream/project-access-levels.html'>${t('project.manage.members.projectRole.description2')}<i class='fa fa-external-link' /></a>`
));

function setLoadTeamMembersState(message: LoadingState) {
  switch (message.type) {
    case 'loading':
      loadWorkspaceMembersState.value.status = 'loading';
      loadWorkspaceMembersState.value.error = null;
      break;
    case 'success':
      loadWorkspaceMembersState.value.status = 'success';
      loadWorkspaceMembersState.value.error = null;
      break;
    case 'error':
      loadWorkspaceMembersState.value.status = 'error';
      loadWorkspaceMembersState.value.error = message.error ?? null;
      break;
    default:
      loadWorkspaceMembersState.value.status = 'idle';
      loadWorkspaceMembersState.value.error = null;
      break;
  }
}

async function loadTeamMembers() {
  setLoadTeamMembersState({ type: 'loading' });
  try {
    const response = await loadTeamMembersAPI();
    const members = mappingByteArkAccountToStreamAccount(response.data?.accounts, memberships.value);

    workspaceMembers.value = members;

    membersThatAreNotInsideThisProject.value = _
      .filter(members, (member) => !isMemberInList(memberships.value, member))
      .map((member) => ({
        ...member,
        isSuspended: member.account?.isEnabled === false,
      }));

    membersThatAreInsideThisProject.value = _.filter(members, (member) => isMemberInList(memberships.value, member));

    setLoadTeamMembersState({ type: 'success' });
  } catch (error) {
    setLoadTeamMembersState({ type: 'error', error: formatErrorObject(error, 'Members') });
  }
}

function onSubmitMemberShips() {
  if (_.isNil(selectedRoleMember.value)) {
    return;
  }

  const projectRole = selectedRoleMember.value.value;

  onSubmit.value(selectedMembers.value.map((member) => ({
    projectRole,
    email: member.text,
    notAWorkspaceMember: checkIfIsNotAWorkspaceMember(member),
    account: member.account,
  })));
}

watch(selectedMembers, (value) => {
  validMembers.value = value.filter((member) => {
    const email = member.account?.email ?? member.text ?? '';
    return emailRgeEx.test(email);
  });

  membersThatAreNotInsideThisProject.value = membersThatAreNotInsideThisProject.value.map((member) => ({
    ...member,
    isAlreadySelected: selectedMembers.value.find((m) => m.text === member.text || m.text === member?.account?.email) !== undefined,
  }));
});

onMounted(() => {
  loadTeamMembers();
  selectedMembers.value = [];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    title: _unref(t)('project.manage.member.table.header.title'),
    primaryButtonText: _unref(t)('invite.submitButton', { count: selectedMembers.value.length }),
    isDisabledPrimaryButton: !isAddProjectMembershipValid.value || inviteProjectMembershipsState.value.status === 'loading',
    isSaving: inviteProjectMembershipsState.value.status === 'saving',
    onClickPrimaryButton: onSubmitMemberShips,
    modalClass: `invite-member-modal ${isShowScrollShadow.value ? 'with-shadow' : ''}`,
    onModalClose: _unref(onCloseModal)
  }, {
    body: _withCtx(() => [
      _createElementVNode("form", {
        id: "form",
        onSubmit: _withModifiers(onSubmitMemberShips, ["prevent"]),
        class: "padding-container",
        novalidate: ""
      }, [
        (loadWorkspaceMembersState.value.status === 'success')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(FormSelectOption, {
                  title: _unref(t)('project.manage.members.permission'),
                  placeholder: _unref(t)('project.manage.members.permissionPlaceholder'),
                  modelValue: selectedRoleMember.value,
                  subtitle: projectAccessLevelSubtitle.value,
                  options: _unref(roleOptions),
                  label: "key",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = (newValue) => (selectedRoleMember.value = newValue))
                }, {
                  option: _withCtx(({ option }) => [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", null, _toDisplayString(option.key), 1),
                      _createElementVNode("div", _hoisted_3, _toDisplayString(roleDescription[option.value]), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["title", "placeholder", "modelValue", "subtitle", "options"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(FormLabel, {
                  class: "form-label",
                  title: _unref(t)('project.manage.member.table.header.email'),
                  description: _unref(t)('project.manage.member.table.header.emailDesc')
                }, null, 8, ["title", "description"]),
                _createVNode(InviteMemberTagsInput, {
                  modelValue: selectedMembers.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedMembers).value = $event)),
                  "members-that-are-inside-this-project": membersThatAreInsideThisProject.value,
                  "members-that-are-not-inside-this-project": membersThatAreNotInsideThisProject.value,
                  onScrollShadowChange: _cache[2] || (_cache[2] = (value) => isShowScrollShadow.value = value)
                }, null, 8, ["modelValue", "members-that-are-inside-this-project", "members-that-are-not-inside-this-project"]),
                _createElementVNode("div", _hoisted_5, [
                  (invalidEmails.value.length > 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_unref(t)('invite.email.invalidEmail', { count: invalidEmails.value.length, email: invalidEmails.value.join(', ') })), 1))
                    : _createCommentVNode("", true),
                  (nonMemberEmails.value.length > 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_unref(t)('invite.email.validEmail', {
                  count: nonMemberEmails.value.length,
                  email: nonMemberEmails.value.join(', '),
                  workspace: currentUserWorkspaceName.value,
                })), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (loadWorkspaceMembersState.value.status === 'loading')
          ? (_openBlock(), _createBlock(PreLoaderCard, { key: 1 }))
          : _createCommentVNode("", true),
        (loadWorkspaceMembersState.value.status === 'error')
          ? (_openBlock(), _createBlock(ErrorMessage, {
              key: 2,
              title: loadWorkspaceMembersErrorMessage.value.title,
              description: loadWorkspaceMembersErrorMessage.value.description,
              statusCode: loadWorkspaceMembersErrorMessage.value.statusCode
            }, null, 8, ["title", "description", "statusCode"]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "primaryButtonText", "isDisabledPrimaryButton", "isSaving", "modalClass", "onModalClose"]))
}
}

})