import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import type {
  TeamFeaturesState, LanguageListState, ProjectState, VideoState,
} from '@/modules/shared';
import type { PlaybackChannelListState } from '@/modules/playbackChannel';
import type { UserState } from '@/modules/user';
import type { BadWordListsState } from '@/modules/badWordList';
import type { CaptionListState, SubtitleState } from '../types';

export const useStore = () => baseUseStore<{
  badWordList: BadWordListsState;
  languageList: LanguageListState;
  video: VideoState;
  captionList: CaptionListState;
  subtitle: SubtitleState;
  teamFeatures: TeamFeaturesState;
  playbackChannelList: PlaybackChannelListState;
  project: ProjectState;
  user: UserState;
}>(storeKey);
