<template>
  <div class="inline-edit-container">
    <div class="inline-edit-wrapper">
      <Input
        v-model="inputValue"
        :isShowErrorMessage="showDefaultError"
        :errorMessage="nameValidationText"
        @keyup.enter="() => onSubmit(inputValue)" />
    </div>
    <div class="button-container">
      <Button
        buttonStyle="primary"
        :disabled="!isEditNameValid"
        @click="() => onSubmit(inputValue)">
        <template v-slot:icon-prefix>
          <i class="fa fa-check" />
        </template>
      </Button>
      <Button buttonStyle="secondary" @click="onClose">
        <template v-slot:icon-prefix>
          <i class="fa fa-xmark" />
        </template>
      </Button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import { InputValue } from '@/modules/shared/types/input.type';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { useI18n } from 'vue-i18n';

interface InlineEditProps {
  value: InputValue;
  onSubmit: (text: string) => void;
  onClose?: () => void;
  validateName?: (text: string) => string;
  validateNameErrorMessage?: {
    type: string;
    default: string;
  };
}

const props = defineProps<InlineEditProps>();
const {
  value,
  onSubmit,
  onClose,
  validateName,
  validateNameErrorMessage,
} = toRefs(props);

const { t } = useI18n();

const inputValue = ref<string>('');
const isTyped = ref<boolean>(false);
const invalidFeedback = ref<string>('');

const isEditNameValid = computed(() => {
  if (!inputValue.value) {
    return false;
  }
  return !invalidFeedback.value;
});

const nameValidationText = computed(() => {
  if (inputValue.value) {
    return validateNameErrorMessage.value || t('validation:name.validateDetault');
  }
  return t('validation:name.required', { item: 'Playback' });
});

const showDefaultError = computed(() => (!inputValue.value || !!invalidFeedback.value) && isTyped.value);

watch(inputValue, (newValue) => {
  isTyped.value = true;

  if (validateName.value) {
    invalidFeedback.value = validateName.value(newValue);
  }
});

onMounted(() => {
  const valueFromProp = _.cloneDeep(value.value);

  if (_.isNil(valueFromProp)) {
    inputValue.value = '';
  } else if (typeof valueFromProp === 'number') {
    inputValue.value = valueFromProp.toString(10);
  } else {
    inputValue.value = valueFromProp;
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/breakpoint';

.inline-edit-wrapper {
  width: 310px;
}

.inline-edit-container {
  display: flex;
  align-items: flex-start;
  min-width: 400px;
  width: 100%;

  @media screen and (max-width: $max-layout-sm) {
    min-width: 150px;
  }
}

.button-container {
  display: flex;
  margin-left: $spacing-base;

  > :first-child {
    margin-right: $spacing-8;
  }

  :deep(button) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px !important;
    height: 33px !important;
  }
}
</style>
