import { axiosAPI, axiosAPIv2 } from '@/plugins/axios';
import type { Project } from '@/modules/shared/types/project.type';
import type { ProjectCreatePayload } from '@/modules/projectCreate/types/projectCreate.type';

export function createProject(payload: ProjectCreatePayload) {
  return axiosAPI.post<Project>('/projects', payload);
}

export function setupLighthouseAfterCreatedProject() {
  return axiosAPIv2.post<Project>('/setup/lighthouse');
}
