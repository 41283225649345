import type { VideoUploadOptions } from '@/modules/shared/types/video.type';
import { transformsVideoFilesToVideoPayload } from '@/modules/videoDetail/utils/videoFileManagement';
import { axiosAPI } from '@/plugins/axios';
import { Filter } from '@/modules/shared/types/axios.type';
import { FileWithValidator } from '@/modules/shared/types/file.type';

export function createVideos(videoFiles: Array<FileWithValidator>, options: VideoUploadOptions) {
  const videos = transformsVideoFilesToVideoPayload(videoFiles, options);
  let payload: Filter = {
    projectKey: options.projectKey,
    autoGenerateCaptionSetting: options.autoGenerateCaptionSetting,
    overlayPresetId: options.overlayPresetId,
    videos,
  };

  if (options.presetId) {
    payload = {
      ...payload,
      presetId: options.presetId,
    };
  }

  return axiosAPI.post('/videos', payload);
}
