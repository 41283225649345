import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import type { Nullable } from '@/modules/shared/types/index.type';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';

interface EmptySearchProps {
  keyword?: Nullable<string>;
  onClearSearch?: () => (void | Promise<void>);
  dataTest?: Nullable<string>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EmptySearch',
  props: {
    keyword: {},
    onClearSearch: {},
    dataTest: { default: 'empty-search-container' }
  },
  setup(__props: any) {

const props = __props;

const {
  keyword,
  onClearSearch,
  dataTest,
} = toRefs(props);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const queryString = computed(() => route.query.q);

const onClickClearSearch = () => {
  if (queryString.value) {
    router.push({ query: undefined });
  }

  if (onClearSearch.value) {
    onClearSearch.value();
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MessageBox, {
    iconClassName: "fas fa-search",
    title: _unref(t)('common:search.resultEmpty.title', { q: _unref(keyword) || queryString.value }),
    description: _unref(t)('common:search.resultEmpty.description'),
    buttonTitle: (queryString.value || _unref(onClearSearch)) ? _unref(t)('common:search.resultEmpty.buttonTitle') : '',
    onClickButton: onClickClearSearch,
    dataTest: _unref(dataTest)
  }, null, 8, ["title", "description", "buttonTitle", "dataTest"]))
}
}

})