<template>
  <Modal
    v-if="!isShowOptionModal"
    size="medium"
    :title="t('video.subtitle.modal.add.title')"
    :primaryButtonText="t('common:button.next')"
    :secondaryButtonText="t('common:button.cancel')"
    :onClickPrimaryButton="onSelectOption"
    :onClickSecondaryButton="onClose"
    :isDisabledPrimaryButton="isDisabledPrimaryButton"
    @modalClose="onClose"
  >
    <template v-slot:body>
      <Field :title="t('video.subtitle.modal.add.choose')">
        <RadioButtonGroup
          :options="addingCaptionOptions"
          :modelValue="selectedOption"
          default-value="upload"
          :onChange="onOptionChange"
        />
      </Field>
    </template>
  </Modal>
  <TypeManuallyVideoCaptionModal
    v-if="isShowOptionModal && selectedOption === 'manual'"
    :onClose="onClose"
    @to-init="backtoFirstStep"
  />
  <UploadVideoCaptionModal
    v-if="isShowOptionModal && selectedOption === 'upload'"
    :onClose="onClose"
    @to-init="backtoFirstStep"
  />
  <AutoGenerateCaptionModal
    v-if="isShowOptionModal && selectedOption === 'auto-gen'"
    :onClose="onClose"
    @to-init="backtoFirstStep"
  />
  <TranslateVideoCaptionModal
    v-if="isShowOptionModal && selectedOption === 'translate'"
    :onClose="onClose"
    @to-init="backtoFirstStep"
  />
</template>

<script lang="ts" setup>
import type {
  RadioButtonGroupOption,
} from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.type';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import type { Nullable } from '@/modules/shared';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import { AutoGenerateCaptionModal } from '@/modules/captionProfile';
import Field from '@/modules/shared/components/molecules/field/Field.vue';
import TypeManuallyVideoCaptionModal from '../typeManuallyVideoCaptionModal/TypeManuallyVideoCaptionModal.vue';
import TranslateVideoCaptionModal from '../translateVideoCaptionModal/TranslateVideoCaptionModal.vue';
import UploadVideoCaptionModal from '../uploadVideoCaptionModal/UploadVideoCaptionModal.vue';
import { useStore } from '../../../store/hooks';

const { t } = useI18n();
const store = useStore();

interface AddVideoCaptionModalProps {
  onClose: () => void;
  prefilledOption: Nullable<string>;
}

const props = defineProps<AddVideoCaptionModalProps>();
const { onClose, prefilledOption } = props;

const teamFeatures = computed(() => store.state.teamFeatures.teamFeatures);
const currentVideo = computed(() => store.state.video.currentVideo);
const isProcessingCompleted = computed(() => currentVideo?.value?.vod?.processingInfo?.state === 'completed');
const audioTracks = computed(() => store.state.video.currentVideo?.vod.outputs?.filter((output) => output?.type === 'audio'));

const hasNonNullAudioTracksBitRate = computed(() => {
  //  After video transcode, Bitrate of audio tracks wil wait for updated
  // Bitrate is required for next step
  if (audioTracks.value && audioTracks?.value?.length > 1) {
    return audioTracks?.value.every(((track) => track.bitRate !== null));
  }
  // single audio tracks
  return true;
});

const addingCaptionOptions = computed<RadioButtonGroupOption[]>(() => [
  {
    label: t('video.subtitle.modal.add.upload.title'),
    description: t('video.subtitle.modal.add.upload.description'),
    value: 'upload',
    disabled: false,
  },
  {
    label: t('video.subtitle.modal.add.typeManually.title'),
    description: t('video.subtitle.modal.add.typeManually.description'),
    value: 'manual',
    disabled: false,
  },
  {
    label: t('video.subtitle.modal.add.autoGenerate.title'),
    description: t('video.subtitle.modal.add.autoGenerate.description'),
    value: 'auto-gen',
    disabled: !teamFeatures.value?.['auto-generate-caption'] || !(isProcessingCompleted?.value) || !(hasNonNullAudioTracksBitRate.value),
    warning: !(isProcessingCompleted?.value) ? t('videoSubtitle.add.disabled.text') : undefined,
  },
  {
    label: t('video.subtitle.modal.add.translate.title'),
    description: t('video.subtitle.modal.add.translate.description'),
    value: 'translate',
    disabled: !teamFeatures.value?.['auto-generate-caption'],
  },
]);

const selectedOption = ref(prefilledOption ?? 'upload');
const onOptionChange = (value: string) => {
  selectedOption.value = value;
};

// second step, show modal of the selected method
const isShowOptionModal = ref(false);

const onSelectOption = () => {
  isShowOptionModal.value = true;
};

const isDisabledPrimaryButton = computed(() => selectedOption.value === 'auto-gen' && !isProcessingCompleted.value);

const backtoFirstStep = () => {
  isShowOptionModal.value = false;
};
</script>
