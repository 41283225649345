<template>
  <div class="file-upload">
    <Button
      type="submit"
      isFullWidth
      buttonStyle="primary"
      :disabled="!isVideoUploadValid()"
      size="large"
      @click="onUploadFiles">
      <template v-slot:content>
        <span v-if="!isButtonClicked" class="button-text">{{$t('glossary:upload')}} {{videoFilesValid.length}} {{$t(`glossary:file${videoFilesValid.length > 1 ? '_plural' : ''}`)}}</span>
        <span v-else>
          <i class="fa-duotone fa-spinner-third fa-spin" /> Preparing {{videoFilesValid.length}} file{{videoFilesValid.length > 1 ? 's' : ''}}...
        </span>
      </template>
    </Button>
  </div>
</template>

<script setup lang="ts">
import _ from 'lodash';
import {
  computed, ref, toRefs, watch,
} from 'vue';
import { GeoblockPolicy } from '@/modules/geoblock/types/Geoblock.type';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { VideoUploadOptions } from '@/modules/shared/types/video.type';
import { useStore } from '../../../store/hooks';

interface VideoUploaderConfirmButtonProps {
  options: VideoUploadOptions;
  disabled?: boolean;
  callbackFunction?: () => any;
  geoblockPolicy: GeoblockPolicy;
  isShowSummaryModalAfterUploading?: boolean;
}

const props = withDefaults(defineProps<VideoUploaderConfirmButtonProps>(), {
  isShowSummaryModalAfterUploading: true,
});

const {
  options, disabled, callbackFunction, geoblockPolicy, isShowSummaryModalAfterUploading,
} = toRefs(props);

const store = useStore();

const isButtonClicked = ref<boolean>(false);

const isEmptyVideoFilesValid = computed(() => store.getters.isEmptyVideoFilesValid);
const uploadVideoState = computed(() => store.state.videoUpload.uploadVideoState);
const videoFilesValid = computed(() => store.getters.videoFilesValid);

function isVideoUploadValid(): boolean {
  if (isButtonClicked.value || disabled.value || _.isEmpty(geoblockPolicy.value)) {
    return false;
  }

  const geoBlockType = _.get(geoblockPolicy.value, 'type', null);
  const geoBlockId = _.get(geoblockPolicy.value, 'ruleId', null);

  if (geoBlockType !== 'customRule' && !geoBlockId) {
    return false;
  }

  return !isEmptyVideoFilesValid.value && !_.isNil(options.value.projectKey);
}

function onUploadFiles(): void {
  isButtonClicked.value = true;
  store.dispatch('createVideos', {
    isShowSummaryModalAfterUploading: isShowSummaryModalAfterUploading.value,
    ...options.value,
  });
}

watch(uploadVideoState, (uploadState) => {
  if (uploadState.status === 'success' && typeof callbackFunction.value === 'function') {
    callbackFunction.value();
  }

  if (uploadState.status === 'error') {
    isButtonClicked.value = false;
  }
});
</script>

<style lang="scss" scoped>
.button-text {
  text-transform: capitalize;
}
</style>
