import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-container" }
const _hoisted_2 = { class: "select-label" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

import { moveVideos } from '@/modules/projectDetails/services';
import ErrorToast from '@/modules/shared/components/molecules/toasts/ErrorToast.vue';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { StatusState } from '@/modules/shared/types/state.type';
import isEmpty from 'lodash/isEmpty';
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import type { Project } from '@/modules/shared/types/project.type';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '../../../store/hooks';

interface MoveVideosModalProps {
  onClose: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MoveVideosModal',
  props: {
    onClose: { type: Function }
  },
  setup(__props: any) {

const props = __props;
const { onClose } = toRefs(props);

const store = useStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const selectedProject = ref<Project | null>(null);
const moveVideosState = ref<StatusState>(setStateLoadingStatusByType());

const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
const videoCount = computed(() => store.state.project.currentProject?.videoCount ?? 0);
const projectName = computed(() => store.state.project.currentProject?.name ?? '');
const projectKey = computed(() => store.state.project.currentProject?.key ?? '');
const projectList = computed(() => store.state.projectList.projectList ?? []);
const options = computed(() => projectList.value?.filter((prj) => prj.key !== projectKey.value));
const loadProjectListState = computed(() => store.state.projectList.loadProjectListState);

const description1Key = computed(() => {
  if (selectedProject.value) {
    return `project.moveVideos.modal.description1Selected${videoCount.value > 1 ? '_plural' : ''}`;
  }

  return `project.moveVideos.modal.description1${videoCount.value > 1 ? '_plural' : ''}`;
});

const confirmButtonKey = computed(() => (
  `project.moveVideos.modal.button${videoCount.value > 1 ? '_plural' : ''}`
));

const isDisabledPrimaryButton = computed(() => (
  loadProjectListState.value.status === 'loading' || !selectedProject.value || moveVideosState.value.status === 'loading'
));

async function onConfirmMoveVideos() {
  if (!selectedProject.value || !projectKey.value) {
    return;
  }

  const destinationProjectKey = selectedProject.value.key;
  moveVideosState.value = setStateLoadingStatusByType({ type: 'loading' });

  try {
    await moveVideos({
      sourceProjectKey: projectKey.value,
      destinationProjectKey,
    });

    store.commit('setAreProjectVideosMoved', true);
    setTimeout(() => store.commit('setAreProjectVideosMoved', false), 5000);

    moveVideosState.value = setStateLoadingStatusByType({ type: 'success' });
    await router.push(`/${teamName.value}/projects/${destinationProjectKey}`);
  } catch (error) {
    console.error(error);
    moveVideosState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Move Videos'),
    });

    setTimeout(() => onClose.value(), 100);
  }
}

onMounted(() => {
  if (isEmpty(projectList.value)) {
    store.dispatch('loadProjectList');
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (moveVideosState.value.status === 'error' && moveVideosState.value.error)
      ? (_openBlock(), _createBlock(ErrorToast, {
          key: 0,
          title: _unref(t)('response:errorTitle'),
          description: `${_unref(t)('response:moveVideosError.title')} (Error code: ${moveVideosState.value.error.statusCode})`,
          duration: 10000
        }, null, 8, ["title", "description"]))
      : _createCommentVNode("", true),
    _createVNode(Modal, {
      title: _unref(t)('project.moveVideos.title'),
      onModalClose: _unref(onClose),
      size: "small",
      primaryButtonText: _unref(t)(confirmButtonKey.value, { count: videoCount.value }),
      onClickPrimaryButton: onConfirmMoveVideos,
      primaryButtonStyle: "warning",
      isDisabledPrimaryButton: isDisabledPrimaryButton.value
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)('project.moveVideos.modal.moveTo')), 1),
            _createVNode(SelectOption, {
              disabled: loadProjectListState.value.status === 'loading',
              placeholder: _unref(t)('project.moveVideos.modal.placeholder'),
              options: options.value,
              modelValue: selectedProject.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedProject).value = $event)),
              searchable: ""
            }, null, 8, ["disabled", "placeholder", "options", "modelValue"])
          ]),
          _createElementVNode("p", {
            class: "description",
            innerHTML: _unref(t)(description1Key.value, { count: videoCount.value, projectName: projectName.value, destinationProjectName: selectedProject.value?.name })
          }, null, 8, _hoisted_3),
          _createElementVNode("p", {
            class: "description",
            innerHTML: _unref(t)('project.moveVideos.modal.description2')
          }, null, 8, _hoisted_4)
        ])
      ]),
      _: 1
    }, 8, ["title", "onModalClose", "primaryButtonText", "isDisabledPrimaryButton"])
  ], 64))
}
}

})