import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-container"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "notes-container"
}

import isNil from 'lodash/isNil';
import {
  computed, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import type { ProjectMember } from '@/modules/projectMember/types';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import InputCopy from '@/modules/shared/components/molecules/inputCopy/InputCopy.vue';
import { getNewInvitationUrl } from '@/modules/projectMember/services';
import type { StatusState } from '@/modules/shared/types/state.type';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateInvitationLinkModal',
  props: {
    member: {},
    onCloseModal: { type: Function }
  },
  setup(__props: any) {

const props = __props;

const {
  member,
  onCloseModal,
} = toRefs(props);

const { t } = useI18n();
const route = useRoute();

const invitationLink = ref<string>('');
const fetchInvitationLinkState = ref<StatusState>(setStateLoadingStatusByType({ type: 'idle' }));

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));

async function fetchInvitationLink() {
  if (isNil(member.value) || !('account' in member.value) || isNil(projectKey.value)) {
    return;
  }

  fetchInvitationLinkState.value = setStateLoadingStatusByType({ type: 'loading' });

  try {
    const response = await getNewInvitationUrl(projectKey.value, {
      email: member.value.account.email,
      projectRole: member.value.projectRole,
    });

    invitationLink.value = response.data.updateUserLink;

    fetchInvitationLinkState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (error) {
    fetchInvitationLinkState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Fetch Invitation Link'),
    });
  }
}


return (_ctx: any,_cache: any) => {
  return (!_unref(isNil)(_unref(member)) && ('account' in _unref(member)))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(Modal, {
          title: _unref(t)('project.manage.members.inviteLink.title'),
          onModalClose: _unref(onCloseModal)
        }, {
          body: _withCtx(() => [
            _createElementVNode("div", null, [
              (fetchInvitationLinkState.value.status === 'idle' || fetchInvitationLinkState.value.status === 'loading')
                ? (_openBlock(), _createBlock(Button, {
                    key: 0,
                    onClick: fetchInvitationLink,
                    isFullWidth: "",
                    isSaving: fetchInvitationLinkState.value.status === 'loading'
                  }, {
                    "icon-prefix": _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("i", { class: "fa fa-link-simple" }, null, -1)
                    ])),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_unref(t)('project.manage.members.inviteLink.button')), 1)
                    ]),
                    _: 1
                  }, 8, ["isSaving"]))
                : _createCommentVNode("", true),
              (fetchInvitationLinkState.value.status === 'success')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(InputCopy, {
                      "element-id": "new-link",
                      modelValue: invitationLink.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((invitationLink).value = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              (fetchInvitationLinkState.value.status !== 'error')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("p", null, [
                      _createElementVNode("strong", null, _toDisplayString(_unref(t)('common:notes')), 1)
                    ]),
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", null, _toDisplayString(_unref(t)('project.manage.members.inviteLink.notes1', { email: _unref(member).account.email })), 1),
                      _createElementVNode("li", null, _toDisplayString(_unref(t)('project.manage.members.inviteLink.notes2')), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            (fetchInvitationLinkState.value.status === 'error')
              ? (_openBlock(), _createBlock(ErrorMessage, {
                  key: 0,
                  "icon-class-name": "fas fa-exclamation-triangle",
                  title: _unref(t)('response:generalError.title'),
                  description: _unref(t)('response:retry'),
                  "status-code": fetchInvitationLinkState.value.error?.statusCode
                }, null, 8, ["title", "description", "status-code"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["title", "onModalClose"])
      ]))
    : _createCommentVNode("", true)
}
}

})