import { deleteBumperByBulkAction, loadBumpers } from '@/modules/bumper/services';
import { Filter } from '@/modules/shared/types/axios.type';
import { ActionContext } from 'vuex';
import _ from 'lodash';
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { RootState } from '@/store/type';
import { BumperListState, Bumper } from '@/modules/bumper/types';
import { LoadingState, SavingState } from '@/modules/shared/types/state.type';

type BumperListActionContext = ActionContext<BumperListState, RootState>;

const initialState = (): BumperListState => ({
  bumperList: [],
  lastPage: 1,
  loadBumperListState: setStateLoadingStatusByType(),
  saveBumperListState: setStateSavingStatusByType(),
});

const state = initialState();

const mutations = {
  setBumperList(state: BumperListState, bumperList: Bumper[]) {
    state.bumperList = bumperList;
  },
  setLoadBumperListState(state: BumperListState, loadingState: LoadingState) {
    state.loadBumperListState = setStateLoadingStatusByType(loadingState);
  },
  setSaveBumperListState(state: BumperListState, savingState: SavingState) {
    state.saveBumperListState = setStateSavingStatusByType(savingState);
  },
  setBumperListLastPage(state: BumperListState, pageNumber: number) {
    state.lastPage = pageNumber;
  },
  clearBumperState() {
    Object.assign(state, _.cloneDeep(initialState()));
  },
};

const actions = {
  async loadBumperList({ commit }: BumperListActionContext, filter: Filter) {
    try {
      commit('setLoadBumperListState', { type: 'loading' });
      const response = await loadBumpers(filter);
      commit('setBumperList', response.data?.data);
      commit('setBumperListLastPage', response.data?.lastPage);
      commit('setLoadBumperListState', { type: 'success' });
    } catch (error) {
      commit('setLoadBumperListState', { type: 'error', error: formatErrorObject(error, 'bumper') });
    }
  },
  async deleteBumperByBulkAction({ commit }: BumperListActionContext, payload: string[]) {
    try {
      commit('setSaveBumperListState', { type: 'loading' });
      await deleteBumperByBulkAction({ bumperIds: payload });
      commit('clearBumperState');
      commit('setSaveBumperListState', { type: 'success' });
    } catch (error) {
      commit('setSaveBumperListState', { type: 'error', error: formatErrorObject(error, 'bumper') });
    }
  },
  clearBumperState({ commit }: BumperListActionContext) {
    commit('clearBumperState');
  },
};

export default {
  state,
  actions,
  mutations,
};
