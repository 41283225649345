<template>
  <Modal
    :title="t('project.manage.member.table.header.title')"
    :primaryButtonText="t('invite.submitButton', { count: selectedMembers.length })"
    :isDisabledPrimaryButton="!isAddProjectMembershipValid || inviteProjectMembershipsState.status === 'loading'"
    :isSaving="inviteProjectMembershipsState.status === 'saving'"
    :onClickPrimaryButton="onSubmitMemberShips"
    :modalClass="`invite-member-modal ${isShowScrollShadow ? 'with-shadow' : ''}`"
    @modalClose="onCloseModal"
  >
    <template v-slot:body>
      <form id="form" v-on:submit.prevent="onSubmitMemberShips" class="padding-container" novalidate>
        <div v-if="loadWorkspaceMembersState.status === 'success'">
          <div class="user-role">
            <FormSelectOption
              :title="t('project.manage.members.permission')"
              :placeholder="t('project.manage.members.permissionPlaceholder')"
              :modelValue="selectedRoleMember"
              :subtitle="projectAccessLevelSubtitle"
              :options="roleOptions"
              label="key"
              @update:modelValue="(newValue) => (selectedRoleMember = newValue)"
            >
              <template #option="{ option }">
                <div>
                  <div>{{ option.key }}</div>
                  <div class="option-description">{{ roleDescription[option.value] }}</div>
                </div>
              </template>
            </FormSelectOption>
          </div>
          <div class="user-container">
            <FormLabel
              class="form-label"
              :title="t('project.manage.member.table.header.email')"
              :description="t('project.manage.member.table.header.emailDesc')"
            />
            <InviteMemberTagsInput
              v-model="selectedMembers"
              :members-that-are-inside-this-project="membersThatAreInsideThisProject"
              :members-that-are-not-inside-this-project="membersThatAreNotInsideThisProject"
              @scroll-shadow-change="(value) => isShowScrollShadow = value"
            />
            <div class="feedback-container">
              <p v-if="invalidEmails.length > 0" class="invalid-email-text">
                {{ t('invite.email.invalidEmail', { count: invalidEmails.length, email: invalidEmails.join(', ') }) }}
              </p>
              <p v-if="nonMemberEmails.length > 0">
                {{ t('invite.email.validEmail', {
                  count: nonMemberEmails.length,
                  email: nonMemberEmails.join(', '),
                  workspace: currentUserWorkspaceName,
                }) }}
              </p>
            </div>
          </div>
        </div>
        <PreLoaderCard v-if="loadWorkspaceMembersState.status === 'loading'" />
        <ErrorMessage v-if="loadWorkspaceMembersState.status === 'error'" :title="loadWorkspaceMembersErrorMessage.title"
                      :description="loadWorkspaceMembersErrorMessage.description"
                      :statusCode="loadWorkspaceMembersErrorMessage.statusCode" />
      </form>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import {
  getMembershipRolesOptions, isMemberInList,
  mappingByteArkAccountToStreamAccount,
} from '@/modules/projectMember/utils';
import type { ObjectWithStringValue } from '@/modules/shared/types/index.type';
import _ from 'lodash';
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import FormLabel from '@/modules/shared/components/molecules/formLabel/FormLabel.vue';
import type { InputMember, MembershipPayload, ProjectMember } from '@/modules/projectMember/types';
import type { LoadingState, StatusState } from '@/modules/shared/types/state.type';
import { emailRgeEx } from '@/modules/shared/utils/validator';
import InviteMemberTagsInput from '@/modules/projectMember/components/molecules/inviteMemberTagsInput/InviteMemberTagsInput.vue';
import { loadTeamMembers as loadTeamMembersAPI } from '@/modules/projectMember';
import PreLoaderCard from '@/modules/shared/components/atoms/preLoaderCard/PreLoaderCard.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import FormSelectOption from '@/modules/shared/components/molecules/formSelectOption/FormSelectOption.vue';
import { useStore } from '../../../store/hooks';

interface AddProjectManageMembershipModalProps {
  memberships: ProjectMember[] | null;
  onCloseModal: () => void;
  onSubmit: (members: MembershipPayload[]) => Promise<void>;
}

const props = defineProps<AddProjectManageMembershipModalProps>();
const { memberships, onCloseModal, onSubmit } = toRefs(props);

const { t } = useI18n();
const store = useStore();

const roleOptions = getMembershipRolesOptions();
const roleDescription: ObjectWithStringValue = {
  'project-manager': t('project.member.role.projectManager.description'),
  uploader: t('project.member.role.uploader.description'),
  viewer: t('project.member.role.viewer.description'),
};

const selectedMembers = ref<InputMember[]>([]);
const loadWorkspaceMembersState = ref<StatusState>({
  status: 'idle',
  error: null,
});
const selectedRoleMember = ref<ObjectWithStringValue | null>(null);
const workspaceMembers = ref<InputMember[]>([]);
const membersThatAreNotInsideThisProject = ref<InputMember[]>([]);
const membersThatAreInsideThisProject = ref<InputMember[]>([]);
const validMembers = ref<InputMember[]>([]);
const isShowScrollShadow = ref<boolean>(false);

const inviteProjectMembershipsState = computed(() => store.state.projectMember.inviteProjectMembershipsState);
const updateProjectMembershipsState = computed(() => store.state.projectMember.updateProjectMembershipsState);
const currentUserWorkspaceName = computed(() => store.state.user.currentUser?.team?.name ?? '');

function getLoadProjectErrorMessage(errorStatus: string) {
  switch (errorStatus) {
    case '403':
      return {
        statusCode: errorStatus,
        title: t('response:error403.title'),
      };
    case '404':
      return {
        statusCode: errorStatus,
        icon: 'fas fa-circle-exclamation',
        title: t('response:error404.title'),
        description: `${t('response:error404.helpText')}
          <a href="mailto:support@byteark.com" target="_top">support@byteark.com</a>
        `,
      };
    default:
      return {
        statusCode: errorStatus,
        icon: 'fas fa-circle-exclamation',
        title: t('response:loadingFailed.title'),
        description: `${t('response:loadingFailed.recheckURL')}
          <a href="mailto:support@byteark.com" target="_top">support@byteark.com</a>
        `,
      };
  }
}

const loadWorkspaceMembersErrorMessage = computed(() => {
  const statusCode = _.get(loadWorkspaceMembersState.value, 'error.response.status', '');
  return getLoadProjectErrorMessage(statusCode);
});

const invalidEmails = computed(() => (
  selectedMembers.value
    .filter((member) => {
      const email = member.account?.email ?? member.text ?? '';
      return !emailRgeEx.test(email) || membersThatAreInsideThisProject.value.find((m) => m.account?.email === member.text) !== undefined;
    })
    .map((member) => member.account?.email ?? member.text)
));

const isAddProjectMembershipValid = computed(() => {
  if (updateProjectMembershipsState.value.status === 'loading') {
    return false;
  }

  if (invalidEmails.value.length > 0) {
    return false;
  }

  if (selectedMembers.value) {
    return selectedMembers.value.length > 0 && !_.isEmpty(selectedRoleMember.value);
  }

  return false;
});

function checkIfIsNotAWorkspaceMember(member: InputMember) {
  // Use keys to add an email || Use autocomplete item to add an email
  return member.notAWorkspaceMember === true || (!member.notAWorkspaceMember && workspaceMembers.value.find((wm) => wm.account?.email === member.text) === undefined);
}

const nonMemberEmails = computed(() => (
  validMembers.value
    .filter(checkIfIsNotAWorkspaceMember)
    .map((member) => member.text)
));

const projectAccessLevelSubtitle = computed(() => (
  `${t('project.manage.members.projectRole.description1')} <a class='doc-link' target="_blank" href='https://docs.byteark.com/th/stream/project-access-levels.html'>${t('project.manage.members.projectRole.description2')}<i class='fa fa-external-link' /></a>`
));

function setLoadTeamMembersState(message: LoadingState) {
  switch (message.type) {
    case 'loading':
      loadWorkspaceMembersState.value.status = 'loading';
      loadWorkspaceMembersState.value.error = null;
      break;
    case 'success':
      loadWorkspaceMembersState.value.status = 'success';
      loadWorkspaceMembersState.value.error = null;
      break;
    case 'error':
      loadWorkspaceMembersState.value.status = 'error';
      loadWorkspaceMembersState.value.error = message.error ?? null;
      break;
    default:
      loadWorkspaceMembersState.value.status = 'idle';
      loadWorkspaceMembersState.value.error = null;
      break;
  }
}

async function loadTeamMembers() {
  setLoadTeamMembersState({ type: 'loading' });
  try {
    const response = await loadTeamMembersAPI();
    const members = mappingByteArkAccountToStreamAccount(response.data?.accounts, memberships.value);

    workspaceMembers.value = members;

    membersThatAreNotInsideThisProject.value = _
      .filter(members, (member) => !isMemberInList(memberships.value, member))
      .map((member) => ({
        ...member,
        isSuspended: member.account?.isEnabled === false,
      }));

    membersThatAreInsideThisProject.value = _.filter(members, (member) => isMemberInList(memberships.value, member));

    setLoadTeamMembersState({ type: 'success' });
  } catch (error) {
    setLoadTeamMembersState({ type: 'error', error: formatErrorObject(error, 'Members') });
  }
}

function onSubmitMemberShips() {
  if (_.isNil(selectedRoleMember.value)) {
    return;
  }

  const projectRole = selectedRoleMember.value.value;

  onSubmit.value(selectedMembers.value.map((member) => ({
    projectRole,
    email: member.text,
    notAWorkspaceMember: checkIfIsNotAWorkspaceMember(member),
    account: member.account,
  })));
}

watch(selectedMembers, (value) => {
  validMembers.value = value.filter((member) => {
    const email = member.account?.email ?? member.text ?? '';
    return emailRgeEx.test(email);
  });

  membersThatAreNotInsideThisProject.value = membersThatAreNotInsideThisProject.value.map((member) => ({
    ...member,
    isAlreadySelected: selectedMembers.value.find((m) => m.text === member.text || m.text === member?.account?.email) !== undefined,
  }));
});

onMounted(() => {
  loadTeamMembers();
  selectedMembers.value = [];
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/themes/helpers';

.form-control-prefix {
  text-transform: lowercase;
}

.btn-close-container {
  border: none;
}

.user-container {
  padding-bottom: $spacing-8;
}

.user-role-label {
  margin-bottom: $spacing-8;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  padding: 12px;
  display: flex;
  justify-content: flex-end;
}

:global(.invite-member-modal .modal-body) {
  padding: 0;
  margin-right: 4px;
  overflow-y: auto;
}

:global(.invite-member-modal.with-shadow) {
  overflow: hidden;
}

:global(.invite-member-modal .modal-footer) {
  box-shadow: 0 -10px 12px rgba(0, 0, 0, 0);
  transition: box-shadow ease-in 130ms;
}

:global(.invite-member-modal.with-shadow .modal-footer) {
  position: relative;
  box-shadow: 0 -10px 12px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

:deep(.form-select-option) {
  margin-bottom: $spacing-base;
}

.padding-container {
  padding: 16px 24px;
  height: 440px;
  overflow: visible !important;
}

.feedback-container {
  margin-top: $spacing-base;

  p {
    word-break: break-all;
  }

  .invalid-email-text {
    color: #E14242;
    margin-bottom: 8px;
  }
}

.multiselect__option--highlight {
  .option-description {
    color: white;
  }
}

.option-description {
  color: $grey-600;
  margin-top: $spacing-8;
  font-weight: 400 !important;
}

:deep(.doc-link) {
  color: white;
  text-decoration: underline;
  text-underline-offset: 20%;

  i {
    margin-left: $spacing-4;
  }
}
</style>
