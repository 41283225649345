<template>
  <Modal
    size="large"
    :title="t('secureAndProtect.title')"
    :isShowModalFooter="false"
    @modalClose="() => emit('close')">
    <template v-slot:body>
      <DataTable responsiveLayout="a">
        <template v-slot:table-header>
          <Row>
            <Column>
              <HeaderCell :text="t('secureAndProtect.table.topic')"/>
            </Column>
            <Column>
              <HeaderCell :text="t('secureAndProtect.table.condition')"/>
            </Column>
            <Column>
              <HeaderCell :text="t('secureAndProtect.table.location')"/>
            </Column>
          </Row>
        </template>
        <template v-slot:table-content>
          <Row>
            <Column>
              <TextCell
                :primaryText="t('project.setup.secureAndProtect.allowedBlocked.title')"
                :subText="t('project.setup.secureAndProtect.allowedBlocked.description')"/>
            </Column>
            <Column>
              <TextCell :text="t('project.setup.secureAndProtect.allowedBlocked.when')"/>
            </Column>
            <Column>
              <Button
                buttonStyle="link-primary"
                @click="onClickLink(`/${teamName}/manage/playback-channels`)"
              >
                {{t('project.setup.secureAndProtect.allowedBlocked.where')}}
              </Button>
            </Column>
          </Row>
          <Row>
            <Column>
              <TextCell
                :primaryText="t('project.setup.secureAndProtect.signedURL.title')"
                :subText="t('project.setup.secureAndProtect.signedURL.description')"/>
            </Column>
            <Column>
              <Button
                buttonStyle="link-primary"
                url="https://docs.byteark.com/th/stream/"
                isExternalUrl
              >
                {{t('project.setup.secureAndProtect.signedURL.when')}}
              </Button>
            </Column>
            <Column>
              <Button
                buttonStyle="link-primary"
                @click="onClickLink(`/${teamName}/manage/playback-channels`)"
              >
                {{t('project.setup.secureAndProtect.signedURL.where')}}
              </Button>
            </Column>
          </Row>
          <Row>
            <Column>
              <TextCell
                :primaryText="t('project.setup.secureAndProtect.clearKey.title')"
                :subText="t('project.setup.secureAndProtect.clearKey.description')"/>
            </Column>
            <Column>
              <Button
                buttonStyle="link-primary"
                url="https://docs.byteark.com/th/stream/"
                isExternalUrl
              >
                {{t('project.setup.secureAndProtect.clearKey.when')}}
              </Button>
            </Column>
            <Column>
              <Button
                buttonStyle="link-primary"
                @click="onClickLink(`/${teamName}/manage/clear-key`)"
              >
                {{t('project.setup.secureAndProtect.clearKey.where')}}
              </Button>
            </Column>
          </Row>
        </template>
      </DataTable>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';

const emit = defineEmits<{close: []}>();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const teamName = computed(() => route.params.teamName);

function onClickLink(routeName: string) {
  const routeData = router.resolve(routeName);
  window.open(routeData.href, '_blank');
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

:deep(.modal-content) {
  background-color: #F8F9FA;
}

/* :deep(.modal-body) {
  padding-bottom: $spacing-base * 1.5;
} */

.table-container {
  border: 1px solid #C5CBD6;
  border-radius: 5px;
  background-color: $white;
}

.info-table {
  border: none;
  border-spacing: 0;
  margin-bottom: 0;
  width: 100%;
  .table-row {
    td {
      padding: 12px;
      border: 1px solid #E5E7EB;
      border-left: none;
      border-bottom: none;

      color: #1B263A;

      height: 90px;
      vertical-align: middle;

      a {
        text-decoration: underline;
        text-underline-offset: 20%;
      }

      .description {
        font-weight: $font-weight-base;
      }

      &.title-cell {
        font-weight: $font-weight-bold;
      }

      &:last-child {
        border-right: none;
      }
    }

    &.highlight {
      td {
        background-color: rgba(0, 166, 237, 0.1);
      }
    }

    &:first-child {
      td {
        border-top: none;
      }
    }

    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  .title-row {
    td {
      font-size: $font-size-base * 1.15;
    }
  }
}

</style>
