/* eslint-disable no-shadow */
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { LoadingState, SavingState } from '@/modules/shared/types/state.type';
import { Webhook, WebhookDeliveryList, WebhookState } from '../types/Webhook.type';
import {
  getWebhookDeliveries,
  updateWebhookById,
  addWebhook,
  loadWebhookById,
  deleteWebhook,
} from '../services';

type WebhookActionContext = ActionContext<WebhookState, RootState>

const initialState = (): WebhookState => ({
  webhookDeliveryList: null,
  currentWebhook: null,
  loadWebhookState: setStateLoadingStatusByType(),
  loadWebhookDeliveryState: setStateLoadingStatusByType(),
  saveWebhookState: setStateSavingStatusByType(),
});

const state = initialState();

const mutations = {
  setCurrentWebhook(state: WebhookState, webhook: Webhook) {
    state.currentWebhook = webhook;
  },
  setWebhookDeliveryList(state: WebhookState, deliveryList: WebhookDeliveryList) {
    state.webhookDeliveryList = deliveryList;
  },
  setLoadWebhookState(state: WebhookState, loadingState: LoadingState) {
    state.loadWebhookState = setStateLoadingStatusByType(loadingState);
  },
  setLoadWebhookDeliveryState(state: WebhookState, loadingState: LoadingState) {
    state.loadWebhookDeliveryState = setStateLoadingStatusByType(loadingState);
  },
  setSaveWebhookState(state: WebhookState, savingState: SavingState) {
    state.saveWebhookState = setStateSavingStatusByType(savingState);
  },
  destroyWebhookData() {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadWebhook({ commit }: WebhookActionContext, webhookId: string) {
    try {
      commit('destroyWebhookData');
      commit('setLoadWebhookState', { type: 'loading' });
      const response = await loadWebhookById(webhookId);
      commit('setCurrentWebhook', response.data);
      commit('setLoadWebhookState', { type: 'success' });
    } catch (error) {
      commit('setLoadWebhookState', { type: 'error', error: formatErrorObject(error, 'webhook') });
    }
  },
  async loadWebhookDeliveryList({ commit }: WebhookActionContext, { filter = {}, webhookId }: { filter: { q?: string}, webhookId: string}) {
    try {
      commit('setLoadWebhookDeliveryState', { type: 'loading' });
      const response = await getWebhookDeliveries({
        webhookId,
        ...filter,
      });
      commit('setWebhookDeliveryList', response.data);
      commit('setLoadWebhookDeliveryState', { type: 'success' });
    } catch (error) {
      commit('setLoadWebhookDeliveryState', { type: 'error', error: formatErrorObject(error, 'webhook') });
    }
  },
  async addNewWebhook({ commit }: WebhookActionContext, webhook: Webhook) {
    try {
      commit('setSaveWebhookState', { type: 'saving' });
      const response = await addWebhook(webhook);
      commit('setCurrentWebhook', response.data);
      commit('setSaveWebhookState', { type: 'success' });
    } catch (error) {
      commit('setSaveWebhookState', { type: 'error', error: formatErrorObject(error, 'webhook') });
    }
  },
  async saveWebhookDetail({ commit }: WebhookActionContext, webhook: Webhook) {
    try {
      commit('setSaveWebhookState', { type: 'saving' });
      // eslint-disable-next-line no-underscore-dangle
      const response = await updateWebhookById(webhook._id, webhook);
      commit('setCurrentWebhook', response.data);
      commit('setSaveWebhookState', { type: 'success' });
    } catch (error) {
      commit('setSaveWebhookState', { type: 'error', error: formatErrorObject(error, 'webhook') });
    }
  },
  async deleteWebhookById({ commit }: WebhookActionContext, id: string) {
    try {
      commit('setSaveWebhookState', { type: 'saving' });
      // eslint-disable-next-line no-underscore-dangle
      await deleteWebhook(id);
      commit('destroyWebhookData');
      commit('setSaveWebhookState', { type: 'success' });
    } catch (error) {
      commit('setSaveWebhookState', { type: 'error', error: formatErrorObject(error, 'webhook') });
    }
  },
  setCurrentWebhook({ commit }: WebhookActionContext, webhook: Webhook) {
    commit('setCurrentWebhook', webhook);
  },
  destroyWebhookData({ commit }: WebhookActionContext) {
    commit('destroyWebhookData');
  },
};

export default {
  state,
  actions,
  mutations,
};
