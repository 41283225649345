import _ from 'lodash';
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { RootState } from '@/store/type';
import {
  BumperDisplay,
  BumperDisplayPosition,
  BumperDisplayState,
  BumperSource,
  SelectedContent,
  SummaryBumperContent,
} from '@/modules/bumperDisplay/types';
import { ActionContext } from 'vuex';
import type { Bumper } from '@/modules/bumper';
import { LoadingState, SavingState } from '@/modules/shared/types/state.type';
import {
  createBumperDisplays,
  deleteBumperDisplayById,
  loadBumperDisplayDetail,
  saveBumperDisplay,
} from '../services';

type BumperDisplayActionContext = ActionContext<BumperDisplayState, RootState>;

const state: BumperDisplayState = {
  bumperDisplayDetail: null,
  loadBumperDisplayDetailState: setStateLoadingStatusByType(),
  selectedContent: {
    type: 'project',
    projectKey: null,
    content: null,
  },
  isSettingDisplay: false,
  saveBumperDisplayState: setStateSavingStatusByType(),
  deleteBumperDisplayState: setStateLoadingStatusByType(),
  intro1: [],
  intro2: [],
  outro1: [],
  outro2: [],
  bumperSources: [
    { positionName: 'intro1', sourceType: 'parent' },
    { positionName: 'intro2', sourceType: 'parent' },
    { positionName: 'outro1', sourceType: 'parent' },
    { positionName: 'outro2', sourceType: 'parent' },
  ],
  parentBumperDisplay: null,
  showSaveErrorAlertBox: {
    intro1: false,
    intro2: false,
    outro1: false,
    outro2: false,
  },
  newBumperDisplayId: null,
};

const initialState = _.cloneDeep(state);

const parseToParentBumperFormat = (bumperDisplayDetail: BumperDisplay, displayId: string) => {
  const { shouldAppearOnContent } = bumperDisplayDetail;
  const { content, type } = shouldAppearOnContent;
  const { name } = content;
  return {
    bumperDisplaySource: { name },
    bumperDisplaySourceType: type,
    bumperDisplaySourceId: displayId,
  };
};

const mutations = {
  setBumperDisplayDetail(state: BumperDisplayState, bumperDisplay: BumperDisplay) {
    state.bumperDisplayDetail = bumperDisplay;
  },
  setLoadBumperDisplayDetailState(state: BumperDisplayState, loadingState: LoadingState) {
    state.loadBumperDisplayDetailState = setStateLoadingStatusByType(loadingState);
  },
  setSelectedContent(state: BumperDisplayState, content: SelectedContent) {
    state.selectedContent = content;
  },
  setIsSettingDisplay(state: BumperDisplayState, boolean: boolean) {
    state.isSettingDisplay = boolean;
  },
  setSaveBumperDisplayState(state: BumperDisplayState, savingState: SavingState) {
    state.saveBumperDisplayState = setStateSavingStatusByType(savingState);
  },
  setDeleteBumperDisplayState(state: BumperDisplayState, savingState: SavingState) {
    state.deleteBumperDisplayState = setStateSavingStatusByType(savingState);
  },
  setDisplayIntro1(state: BumperDisplayState, array: Bumper[]) {
    state.intro1 = array;
  },
  setDisplayIntro2(state: BumperDisplayState, array: Bumper[]) {
    state.intro2 = array;
  },
  setDisplayOutro1(state: BumperDisplayState, array: Bumper[]) {
    state.outro1 = array;
  },
  setDisplayOutro2(state: BumperDisplayState, array: Bumper[]) {
    state.outro2 = array;
  },
  setBumperSources(state: BumperDisplayState, bumperSources: BumperSource[]) {
    state.bumperSources = bumperSources;
  },
  setParentBumperDisplay(state: BumperDisplayState, value: SummaryBumperContent | null) {
    state.parentBumperDisplay = value;
  },
  clearBumperDisplayData() {
    Object.assign(state, _.cloneDeep(initialState));
  },
  clearSelectedContent() {
    state.selectedContent = {
      type: 'project',
      projectKey: null,
      content: null,
    };
  },
  setShowSaveErrorAlertBox(
    state: BumperDisplayState,
    { positionName, value }: { positionName: string; value: boolean },
  ) {
    state.showSaveErrorAlertBox[positionName] = value;
  },
  setNewBumperDisplayId(state: BumperDisplayState, newId: string) {
    state.newBumperDisplayId = newId;
  },
};

const actions = {
  async loadBumperDisplayDetail({ commit }: BumperDisplayActionContext, displayId: string) {
    try {
      commit('clearBumperDisplayData');
      commit('setLoadBumperDisplayDetailState', { type: 'loading' });
      const response = await loadBumperDisplayDetail(displayId);
      commit('setBumperDisplayDetail', response.data);
      commit('setBumperSources', response.data?.bumperSources);
      commit(
        'setParentBumperDisplay',
        response.data?.shouldAppearOnContent?.content?.parentBumperDisplay,
      );

      if (response.data?.shouldAppearOnContent) {
        const content = response.data?.shouldAppearOnContent;

        commit('setSelectedContent', {
          type: content.type,
          content,
          projectKey: content.content?.project?.key,
        });
      }
      commit('setLoadBumperDisplayDetailState', { type: 'success' });
    } catch (error) {
      commit('setLoadBumperDisplayDetailState', {
        type: 'error',
        error: formatErrorObject(error, 'BumperDisplay'),
      });
    }
  },
  async loadBumperDisplayDetailFromParent(
    { commit }: BumperDisplayActionContext,
    displayId: string,
  ) {
    try {
      commit('setLoadBumperDisplayDetailState', { type: 'loading' });
      const response = await loadBumperDisplayDetail(displayId);
      commit('setBumperDisplayDetail', response.data);
      commit('setParentBumperDisplay', parseToParentBumperFormat(response.data, displayId));

      commit('setLoadBumperDisplayDetailState', { type: 'success' });
    } catch (error) {
      commit('setLoadBumperDisplayDetailState', {
        type: 'error',
        error: formatErrorObject(error, 'BumperSchdeule'),
      });
    }
  },
  async createBumperDisplay({ commit }: BumperDisplayActionContext, payload: BumperDisplay) {
    try {
      commit('setSaveBumperDisplayState', { type: 'saving' });

      const response = await createBumperDisplays(payload);
      commit('setNewBumperDisplayId', response.data?.id);
      commit('setSaveBumperDisplayState', { type: 'success' });
    } catch (error: any) {
      commit('setSaveBumperDisplayState', {
        type: 'error',
        error: error.response.data,
      });
    }
  },
  async saveBumperDisplay({ commit }: BumperDisplayActionContext, payload: BumperDisplay) {
    try {
      commit('setSaveBumperDisplayState', { type: 'saving' });
      await saveBumperDisplay(payload.id, payload);
      commit('setSaveBumperDisplayState', { type: 'success' });
    } catch (error: any) {
      commit('setSaveBumperDisplayState', {
        type: 'error',
        error: error.response.data,
      });
    }
  },
  async deleteBumperDisplay({ commit }: BumperDisplayActionContext, id: string) {
    try {
      commit('setDeleteBumperDisplayState', { type: 'loading' });
      await deleteBumperDisplayById(id);
      commit('setDeleteBumperDisplayState', { type: 'success' });
    } catch (error) {
      commit('setDeleteBumperDisplayState', {
        type: 'error',
        error: formatErrorObject(error, 'BumperDisplay'),
      });
    }
  },
  setSelectedContent({ commit }: BumperDisplayActionContext, content: SelectedContent) {
    commit('setSelectedContent', content);
  },
  setIsSettingDisplay({ commit }: BumperDisplayActionContext, boolean: boolean) {
    commit('setIsSettingDisplay', boolean);
  },
  /* eslint-disable */
  onMoveDisplay(
    { state, commit }: BumperDisplayActionContext,
    {
      fromCategory,
      toCategory,
      element,
      newIndex,
      oldIndex,
    }: {
      fromCategory: string;
      toCategory: string;
      element: Bumper;
      newIndex: number;
      oldIndex: number;
    }
  ) {
    let targetObject;

    if (fromCategory === toCategory) {
      if (fromCategory === 'Intro 1') {
        targetObject = state.intro1;
        targetObject.splice(oldIndex, 1);
        targetObject.splice(newIndex, 0, element);
        commit('setDisplayIntro1', targetObject);
      }
      if (fromCategory === 'Intro 2') {
        targetObject = state.intro2;
        targetObject.splice(oldIndex, 1);
        targetObject.splice(newIndex, 0, element);
        commit('setDisplayIntro2', targetObject);
      }
      if (fromCategory === 'Outro 1') {
        targetObject = state.outro1;
        targetObject.splice(oldIndex, 1);
        targetObject.splice(newIndex, 0, element);
        commit('setDisplayOutro1', targetObject);
      }
      if (fromCategory === 'Outro 2') {
        targetObject = state.outro2;
        targetObject.splice(oldIndex, 1);
        targetObject.splice(newIndex, 0, element);
        commit('setDisplayOutro2', targetObject);
      }
      return;
    }

    if (toCategory === 'Intro 1') {
      targetObject = state.intro1;
      targetObject = [...targetObject.slice(0, newIndex), element, ...targetObject.slice(newIndex)];
      commit('setDisplayIntro1', targetObject);
    }

    if (toCategory === 'Intro 2') {
      targetObject = state.intro2;
      targetObject = [...targetObject.slice(0, newIndex), element, ...targetObject.slice(newIndex)];
      commit('setDisplayIntro2', targetObject);
    }

    if (toCategory === 'Outro 1') {
      targetObject = state.outro1;
      targetObject = [...targetObject.slice(0, newIndex), element, ...targetObject.slice(newIndex)];
      commit('setDisplayOutro1', targetObject);
    }

    if (toCategory === 'Outro 2') {
      targetObject = state.outro2;
      targetObject = [...targetObject.slice(0, newIndex), element, ...targetObject.slice(newIndex)];
      commit('setDisplayOutro2', targetObject);
    }

    if (fromCategory === 'Intro 1') {
      const updatedIntro1 = state.intro1;
      updatedIntro1.splice(oldIndex, 1);
      commit('setDisplayIntro1', updatedIntro1);
    }

    if (fromCategory === 'Intro 2') {
      const updatedIntro2 = state.intro2;
      updatedIntro2.splice(oldIndex, 1);
      commit('setDisplayIntro2', updatedIntro2);
    }

    if (fromCategory === 'Outro 1') {
      const updatedOutro1 = state.outro1;
      updatedOutro1.splice(oldIndex, 1);
      commit('setDisplayOutro1', updatedOutro1);
    }

    if (fromCategory === 'Outro 2') {
      const updatedOutro2 = state.outro2;
      updatedOutro2.splice(oldIndex, 1);
      commit('setDisplayOutro2', updatedOutro2);
    }
  },
  clearBumperDisplayData({ commit }: BumperDisplayActionContext) {
    commit('clearBumperDisplayData');
  },
  clearSelectedContent({ commit }: BumperDisplayActionContext) {
    commit('clearBumperSelectedContent');
  },
  setBumperSources({ commit }: BumperDisplayActionContext, bumperSources: BumperSource[]) {
    commit('setBumperSources', bumperSources);
  },
  setBumperSourceByPositionName(
    { state, commit }: BumperDisplayActionContext,
    { positionName, sourceType }: { positionName: BumperDisplayPosition; sourceType: string }
  ) {
    const bumperSourceIndex = _.findIndex(state.bumperSources, { positionName });
    const tempBumperSources = _.cloneDeep(state.bumperSources);

    if (bumperSourceIndex >= 0) {
      tempBumperSources[bumperSourceIndex] = { positionName, sourceType };
    }
    commit('setBumperSources', tempBumperSources);
  },
  setShowSaveErrorAlertBox(
    { commit }: BumperDisplayActionContext,
    { positionName, value }: { positionName: string; value: boolean }
  ) {
    commit('setShowSaveErrorAlertBox', { positionName, value });
  },
};

export default {
  state,
  actions,
  mutations,
};
