import type { CaptionProfilePublishStatusSetting } from '@/modules/captionProfile/types';

export const serviceNameDict = {
  transcribe: {
    aws: 'Amazon Transcribe',
    google: 'Google Speech-to-Text',
    autosub: 'ByteArk AI Subtitle Generator',
  },
  translate: {
    google: 'Google Translate',
  },
};

export const captionPublishConditionTranslationKey: Record<CaptionProfilePublishStatusSetting, string> = {
  draft: 'video.subtitle.status.setting.draft.title',
  publish: 'video.subtitle.status.setting.publish.title',
  publishIfNoBadWord: 'video.subtitle.status.setting.publishIfNoBadWord.title',
};
