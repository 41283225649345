/* eslint-disable no-shadow */
import _ from 'lodash';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { loadPlaybackChannelDetail } from '@/modules/shared';
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import type { ActionContext } from 'vuex';
import type { RootState } from '@/store/type';
import type { LoadingState, SavingState } from '@/modules/shared/types/state.type';
import type { UrlSigningKey } from '@/modules/urlSigningKey';
import type {
  AccessControlPolicy,
  PlaybackChannel,
  PlaybackChannelState,
} from '../types/playbackChannel.type';
import {
  addPlaybackChannelUrlSigningKey, addPlaybackDomain, createPlaybackChannel,
  removePlaybackChannelUrlSigningKey,
  removePlaybackDomain,
  updatePlaybackChannelAccessControlPolicy, updatePlaybackChannelName,
  updatePlaybackChannelUrlSignedUrl,
} from '../services/api';

type PlaybackChannelActionContext = ActionContext<PlaybackChannelState, RootState>

const initialState = (): PlaybackChannelState => ({
  playbackChannel: null,
  loadPlaybackChannelState: setStateLoadingStatusByType(),
  savePlaybackChannelState: setStateSavingStatusByType(),
  errorSection: null,
});

const state = initialState();

const getters = {
  playbackChannelUrlSigningKeys: (state: PlaybackChannelState) => _.get(state, 'playbackChannel.signedUrl.urlSigningKeys', []),
  playbackChannelUrlSigningKeyIds: (state: PlaybackChannelState) => _.get(state, 'playbackChannel.signedUrl.urlSigningKeyIds', []),
};

const mutations = {
  setPlaybackChannel(state: PlaybackChannelState, playbackChannel: PlaybackChannel) {
    state.playbackChannel = { ...state.playbackChannel, ...playbackChannel };
  },
  setLoadPlaybackChannelState(state: PlaybackChannelState, loadingState: LoadingState) {
    state.loadPlaybackChannelState = setStateLoadingStatusByType(loadingState);
  },
  setSavePlaybackChannelState(state: PlaybackChannelState, savingState: SavingState) {
    state.savePlaybackChannelState = setStateSavingStatusByType(savingState);
  },
  setSaveErrorSection(state: PlaybackChannelState, sectionName: string) {
    state.errorSection = sectionName;
  },
  removeUrlSigningKey(state: PlaybackChannelState, urlSigningKeyId: string) {
    const playbackChannelUrlSigningKeys: UrlSigningKey[] = _.get(state, 'playbackChannel.signedUrl.urlSigningKeys', []);
    const playbackChannelUrlSigningKeyIds: string[] = _.get(state, 'playbackChannel.signedUrl.urlSigningKeyIds', []);

    const index = playbackChannelUrlSigningKeyIds.indexOf(urlSigningKeyId);
    playbackChannelUrlSigningKeyIds.splice(index, 1);

    _.remove(playbackChannelUrlSigningKeys, (urlSigningKey: UrlSigningKey) => urlSigningKey.id === urlSigningKeyId);

    if (state.playbackChannel) {
      state.playbackChannel = {
        ...state.playbackChannel,
        signedUrl: {
          ...state.playbackChannel?.signedUrl,
          urlSigningKeys: playbackChannelUrlSigningKeys,
          urlSigningKeyIds: playbackChannelUrlSigningKeyIds,
        },
      };
    }
  },
  destroyPlaybackChannel(state: PlaybackChannelState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  setPlaybackChannel({ commit }: PlaybackChannelActionContext, playbackChannel: PlaybackChannel) {
    commit('setPlaybackChannel', playbackChannel);
  },
  async loadPlaybackChannelDetail({ commit }: PlaybackChannelActionContext, playbackChannelId: string) {
    try {
      commit('setLoadPlaybackChannelState', { type: 'loading' });
      const response = await loadPlaybackChannelDetail(playbackChannelId);
      commit('setPlaybackChannel', response.data);
      commit('setLoadPlaybackChannelState', { type: 'success' });
    } catch (error: any) {
      commit('setLoadPlaybackChannelState', { type: 'error', error: formatErrorObject(error, 'loadPlaybackChannel') });
    }
  },
  async createPlaybackChannel({ commit }: PlaybackChannelActionContext, name: string) {
    try {
      commit('setSavePlaybackChannelState', { type: 'saving' });
      const response = await createPlaybackChannel({ name });
      commit('setPlaybackChannel', response.data);
      commit('setSavePlaybackChannelState', { type: 'success' });
    } catch (error) {
      commit('setSavePlaybackChannelState', { type: 'error', error: formatErrorObject(error, 'Playback Channel') });
    }
  },
  async addPlaybackDomain({ commit }: PlaybackChannelActionContext, domain: string) {
    try {
      if (state.playbackChannel) {
        commit('setSavePlaybackChannelState', { type: 'saving' });
        const response = await addPlaybackDomain(state.playbackChannel.id, { playbackDomains: [domain] });
        commit('setPlaybackChannel', response.data);
        commit('setSavePlaybackChannelState', { type: 'success' });
        commit('setSaveErrorSection', null);
      }
    } catch (error) {
      commit('setSavePlaybackChannelState', { type: 'error', error: formatErrorObject(error, 'Playback Channel') });
      commit('setSaveErrorSection', 'domain');
    }
  },
  async removePlaybackDomain({ commit }: PlaybackChannelActionContext, domain: string) {
    try {
      if (state.playbackChannel) {
        commit('setSavePlaybackChannelState', { type: 'saving' });
        const response = await removePlaybackDomain(state.playbackChannel.id, { playbackDomains: [domain] });
        commit('setPlaybackChannel', response.data);
        commit('setSavePlaybackChannelState', { type: 'success' });
        commit('setSaveErrorSection', null);
      }
    } catch (error) {
      commit('setSavePlaybackChannelState', { type: 'error', error: formatErrorObject(error, 'Playback Channel') });
      commit('setSaveErrorSection', 'domain');
    }
  },
  async updatePlaybackChannelAccessControlPolicy({ state, commit }: PlaybackChannelActionContext, accessControlPolicy: AccessControlPolicy) {
    try {
      if (state.playbackChannel) {
        commit('setSavePlaybackChannelState', { type: 'saving' });
        const response = await updatePlaybackChannelAccessControlPolicy(state.playbackChannel.id, accessControlPolicy);
        commit('setPlaybackChannel', response.data);
        commit('setSavePlaybackChannelState', { type: 'success' });
        commit('setSaveErrorSection', null);
      }
    } catch (error) {
      commit('setSavePlaybackChannelState', { type: 'error', error: formatErrorObject(error, 'Playback Channel') });
      commit('setSaveErrorSection', 'accessControlPolicy');
    }
  },
  async addPlaybackChannelUrlSigningKey({ state, commit }: PlaybackChannelActionContext, urlSigningKeyIds: string[]) {
    try {
      if (state.playbackChannel) {
        commit('setSavePlaybackChannelState', { type: 'saving' });
        const response = await addPlaybackChannelUrlSigningKey(state.playbackChannel.id, { urlSigningKeyIds });
        commit('setPlaybackChannel', response.data);
        commit('setSavePlaybackChannelState', { type: 'success' });
        commit('setSaveErrorSection', null);
      }
    } catch (error) {
      commit('setSavePlaybackChannelState', { type: 'error', error: formatErrorObject(error, 'Playback Channel') });
      commit('setSaveErrorSection', 'signUrl');
    }
  },
  async updatePlaybackChannelUrlSignedUrl({ state, commit }: PlaybackChannelActionContext, signedUrl: UrlSigningKey) {
    try {
      if (state.playbackChannel) {
        commit('setSavePlaybackChannelState', { type: 'saving' });
        const response = await updatePlaybackChannelUrlSignedUrl(state.playbackChannel.id, signedUrl);
        commit('setPlaybackChannel', response.data);
        commit('setSavePlaybackChannelState', { type: 'success' });
        commit('setSaveErrorSection', null);
      }
    } catch (error) {
      commit('setSavePlaybackChannelState', { type: 'error', error: formatErrorObject(error, 'Playback Channel') });
      commit('setSaveErrorSection', 'signUrl');
    }
  },
  async removePlaybackChannelUrlSigningKey({ state, commit }: PlaybackChannelActionContext, urlSigningKeyId: string) {
    try {
      if (state.playbackChannel) {
        commit('setSavePlaybackChannelState', { type: 'saving' });
        await removePlaybackChannelUrlSigningKey(state.playbackChannel.id, urlSigningKeyId);
        commit('removeUrlSigningKey', urlSigningKeyId);
        commit('setSavePlaybackChannelState', { type: 'success' });
        commit('setSaveErrorSection', null);
      }
    } catch (error) {
      commit('setSavePlaybackChannelState', { type: 'error', error: formatErrorObject(error, 'Playback Channel') });
      commit('setSaveErrorSection', 'signUrl');
    }
  },
  async updatePlaybackChannelName({ state, commit }: PlaybackChannelActionContext, name: string) {
    try {
      if (state.playbackChannel) {
        commit('setSavePlaybackChannelState', { type: 'saving' });
        const response = await updatePlaybackChannelName(state.playbackChannel.id, { name });
        commit('setPlaybackChannel', response.data);
        commit('setSavePlaybackChannelState', { type: 'success' });
        commit('setSaveErrorSection', null);
      }
    } catch (error) {
      commit('setSavePlaybackChannelState', { type: 'error', error: formatErrorObject(error, 'Playback Channel') });
      commit('setSaveErrorSection', 'name');
    }
  },
  destroyPlaybackChannel({ commit }: PlaybackChannelActionContext) {
    commit('destroyPlaybackChannel');
  },
  // deletePlaybackDomain({ commit }, domain) {

  // },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
