import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import type {
  RadioButtonGroupOption,
} from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.type';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import type { Nullable } from '@/modules/shared';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import { AutoGenerateCaptionModal } from '@/modules/captionProfile';
import Field from '@/modules/shared/components/molecules/field/Field.vue';
import TypeManuallyVideoCaptionModal from '../typeManuallyVideoCaptionModal/TypeManuallyVideoCaptionModal.vue';
import TranslateVideoCaptionModal from '../translateVideoCaptionModal/TranslateVideoCaptionModal.vue';
import UploadVideoCaptionModal from '../uploadVideoCaptionModal/UploadVideoCaptionModal.vue';
import { useStore } from '../../../store/hooks';

interface AddVideoCaptionModalProps {
  onClose: () => void;
  prefilledOption: Nullable<string>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddVideoCaptionModal',
  props: {
    onClose: { type: Function },
    prefilledOption: {}
  },
  setup(__props: any) {

const { t } = useI18n();
const store = useStore();

const props = __props;
const { onClose, prefilledOption } = props;

const teamFeatures = computed(() => store.state.teamFeatures.teamFeatures);
const currentVideo = computed(() => store.state.video.currentVideo);
const isProcessingCompleted = computed(() => currentVideo?.value?.vod?.processingInfo?.state === 'completed');
const audioTracks = computed(() => store.state.video.currentVideo?.vod.outputs?.filter((output) => output?.type === 'audio'));

const hasNonNullAudioTracksBitRate = computed(() => {
  //  After video transcode, Bitrate of audio tracks wil wait for updated
  // Bitrate is required for next step
  if (audioTracks.value && audioTracks?.value?.length > 1) {
    return audioTracks?.value.every(((track) => track.bitRate !== null));
  }
  // single audio tracks
  return true;
});

const addingCaptionOptions = computed<RadioButtonGroupOption[]>(() => [
  {
    label: t('video.subtitle.modal.add.upload.title'),
    description: t('video.subtitle.modal.add.upload.description'),
    value: 'upload',
    disabled: false,
  },
  {
    label: t('video.subtitle.modal.add.typeManually.title'),
    description: t('video.subtitle.modal.add.typeManually.description'),
    value: 'manual',
    disabled: false,
  },
  {
    label: t('video.subtitle.modal.add.autoGenerate.title'),
    description: t('video.subtitle.modal.add.autoGenerate.description'),
    value: 'auto-gen',
    disabled: !teamFeatures.value?.['auto-generate-caption'] || !(isProcessingCompleted?.value) || !(hasNonNullAudioTracksBitRate.value),
    warning: !(isProcessingCompleted?.value) ? t('videoSubtitle.add.disabled.text') : undefined,
  },
  {
    label: t('video.subtitle.modal.add.translate.title'),
    description: t('video.subtitle.modal.add.translate.description'),
    value: 'translate',
    disabled: !teamFeatures.value?.['auto-generate-caption'],
  },
]);

const selectedOption = ref(prefilledOption ?? 'upload');
const onOptionChange = (value: string) => {
  selectedOption.value = value;
};

// second step, show modal of the selected method
const isShowOptionModal = ref(false);

const onSelectOption = () => {
  isShowOptionModal.value = true;
};

const isDisabledPrimaryButton = computed(() => selectedOption.value === 'auto-gen' && !isProcessingCompleted.value);

const backtoFirstStep = () => {
  isShowOptionModal.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isShowOptionModal.value)
      ? (_openBlock(), _createBlock(Modal, {
          key: 0,
          size: "medium",
          title: _unref(t)('video.subtitle.modal.add.title'),
          primaryButtonText: _unref(t)('common:button.next'),
          secondaryButtonText: _unref(t)('common:button.cancel'),
          onClickPrimaryButton: onSelectOption,
          onClickSecondaryButton: _unref(onClose),
          isDisabledPrimaryButton: isDisabledPrimaryButton.value,
          onModalClose: _unref(onClose)
        }, {
          body: _withCtx(() => [
            _createVNode(Field, {
              title: _unref(t)('video.subtitle.modal.add.choose')
            }, {
              default: _withCtx(() => [
                _createVNode(RadioButtonGroup, {
                  options: addingCaptionOptions.value,
                  modelValue: selectedOption.value,
                  "default-value": "upload",
                  onChange: onOptionChange
                }, null, 8, ["options", "modelValue"])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        }, 8, ["title", "primaryButtonText", "secondaryButtonText", "onClickSecondaryButton", "isDisabledPrimaryButton", "onModalClose"]))
      : _createCommentVNode("", true),
    (isShowOptionModal.value && selectedOption.value === 'manual')
      ? (_openBlock(), _createBlock(TypeManuallyVideoCaptionModal, {
          key: 1,
          onClose: _unref(onClose),
          onToInit: backtoFirstStep
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (isShowOptionModal.value && selectedOption.value === 'upload')
      ? (_openBlock(), _createBlock(UploadVideoCaptionModal, {
          key: 2,
          onClose: _unref(onClose),
          onToInit: backtoFirstStep
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (isShowOptionModal.value && selectedOption.value === 'auto-gen')
      ? (_openBlock(), _createBlock(_unref(AutoGenerateCaptionModal), {
          key: 3,
          onClose: _unref(onClose),
          onToInit: backtoFirstStep
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (isShowOptionModal.value && selectedOption.value === 'translate')
      ? (_openBlock(), _createBlock(TranslateVideoCaptionModal, {
          key: 4,
          onClose: _unref(onClose),
          onToInit: backtoFirstStep
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})