<template>
  <div v-if="video && currentUser" class="video-status-container">
    <div class="video-status video-pending" v-if="isVideoUploadingByOther(video, currentUser)">
      {{$t('common:form.isUploadingFiles')}}
    </div>
    <div class="video-status video-pending" v-if="isVideoUploadingByOther(video, currentUser) && isVideoUploadQueued(video)">
      {{$t('video.uploader.enqueued')}}
    </div>
    <div class="video-status video-retry" v-if="isVideoUploadRetry(video) && isVideoUploadByKeyObject(video)">
      {{$t('video.uploader.failed.retrying', {item: video.remainingRetryDelay})}}
    </div>
    <div class="video-status video-failed" v-if="isVideoUploadFailed(video)">
      <span class="text-danger">{{$t('video.uploader.failed.helpText')}}</span>
    </div>
    <div class="video-status video-uploading" v-if="isVideoUploading(video)">
      <div class="progress-upload-percent">
        {{$t('common:form.isUploadingFiles')}}
      </div>
    </div>
    <div class="video-status video-processing-info" v-if="isVideoSourceIngression(video)">
      <span v-if="!isVideoProbeError(video)">
        <span v-if="!isVideoProcessedOverTime(video, deadlineTime)">
          {{$t('video.upload.checkingFile')}}
        </span>
        <span class="warning-status" v-if="isVideoProcessedOverTime(video, deadlineTime)">
          {{$t('video.upload.checkingFileOverTime')}}
        </span>
      </span>
      <span class="text-danger" v-if="isVideoProbeError(video)">
        <span v-if="isVideoProbeErrorFile(video)">
          {{$t('video.upload.uploadedFileError.title')}}
        </span>
        <span v-if="!isVideoProbeErrorFile(video)">
          {{$t('video.upload.checkingFileError.helpText')}}
          <a href="mailto:support@byteark.com" target="_top">support@byteark.com</a>
        </span>
        <Badge
          :title="`Code : ${getVideoProbeErrorType(video)}`"
          size="small"
          badgeStyle="danger"
        />
      </span>
    </div>
    <div class="video-status video-processing-info" v-if="isVideoProcessing(video)">
      {{$t('video.processingFiles')}}
    </div>
    <div class="video-status video-processing-info" v-if="isVideoProcessing(video) && isVideoPartiallyCompleted(video)">
      <div class="progress-circle-container">
        <vue-circle
          :progress="50"
          :size="16"
          :reverse="false"
          line-cap="round"
          :fill="fill"
          empty-fill="rgba(0, 0, 0, .1)"
          :animation-start-value="0.0"
          :start-angle="-90"
          insert-mode="append"
          :thickness="3"
          :show-percent="false"
          class="circle-desktop" />
        <vue-circle
          :progress="50"
          :size="11"
          :reverse="false"
          line-cap="round"
          :fill="fill"
          empty-fill="rgba(0, 0, 0, .1)"
          :animation-start-value="0.0"
          :start-angle="-90"
          insert-mode="append"
          :thickness="2"
          :show-percent="false"
          class="circle-mobile" />
        <div class="process-label">{{$t('video.processedFiles')}} {{getVideoTotalCompletedOutputs(video)}} / {{getVideoTotalExpectedOutputs(video)}} {{$t('glossary:video')}}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import type { Video } from '@/modules/shared/types/video.type';
import type { Nullable } from '@/modules/shared/types/index.type';
import { isVideoUploadByKeyObject } from '@/modules/shared/utils/typeGuard';
import type { VideoUploadByKeyObject } from '@/modules/videoUpload';
import {
  getVideoTotalExpectedOutputs,
  getVideoTotalCompletedOutputs,
  getVideoProbeErrorType,
  isVideoSourceIngression,
  isVideoProbeError,
  isVideoProbeErrorFile,
  isVideoProcessing,
  isVideoPartiallyCompleted,
  isVideoUploadQueued,
  isVideoUploading,
  isVideoUploadRetry,
  isVideoUploadFailed,
  isVideoUploadingByOther,
  isVideoProcessedOverTime,
} from '@/modules/videoDetail/utils/videoManagement';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import { useStore } from '../../../store/hooks';

interface VideoItemStatusProps {
  video: Nullable<Video | VideoUploadByKeyObject>;
}

const fill = { color: '#2EA2E5' };

const props = defineProps<VideoItemStatusProps>();
const { video } = toRefs(props);

const store = useStore();

const deadlineTime = computed(() => store.state.videoUpload.deadlineTime);
const currentUser = computed(() => store.state.user.currentUser);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.video-status-container {
  width: 100%;
}

.video-status {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: $spacing-base * 0.5 2px;
  font-size: $font-size-base * 0.95;
  font-weight: $font-weight-base;
  text-transform: none;
  flex-direction: column;
  text-align: center;

  &.video-detail {
    max-height: 40px;
    overflow: hidden;
  }

  &.video-pending {
    font-size: $font-size-base * 0.875;
  }

  &.video-uploading {
    font-size: $font-size-base * 0.875;
    display: flex;
    align-items: center;

    :deep(.wrapper) {
      display: flex;
    }

    .progress-container {
      margin-top: $spacing-base * 0.25;
      height: 4px;
      flex: 1 0 auto;
      border-radius: 2px;
      background-color: $grey-200;
      position: relative;

      .progress-bar {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: $success;
        transition: all 0.2s ease-out;
      }
    }

    .progress-upload-percent {
      display: flex;
      margin-left: $spacing-8;
    }
  }

  &.video-processing-info {
    display: flex;
    font-size: $font-level-8;
    align-items: center;

    .warning-status {
      color: darken($warning, 3%);
    }

    .finish-uploaded {
      margin-right: $spacing-base * 0.25;
      display: flex;
      align-items: center;

      .icon {
        color: $success;
        font-size: $font-size-base;
        margin-right: 4px;
      }
    }

    .progress-circle-container {
      margin-left: $spacing-base;
      display: flex;
      align-items: center;

      .circle {
        line-height: 1rem;
      }

      .circle-mobile {
        display: none;
      }

      .process-label {
        margin-left: $spacing-base * 0.25;
        font-size: $font-level-8;
      }
    }
  }

  &.video-status-mobile {
    display: none;
  }
}

@media screen and (max-width: $max-layout-sm) {
  .video-status {
    padding-top: $spacing-base * 0.25;
    line-height: initial;
    font-size: $font-level-8 * 0.875;

    &.video-detail {
      display: none;
    }

    &.video-pending {
      font-size: $font-level-8 * 0.875;
    }

    &.video-status-mobile {
      display: flex;

      &.video-uploading {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    &.video-uploading {
      display: none;
      align-items: center;

      &:not(.video-status-mobile) .progress-container {
        display: none;
      }

      .progress-upload-percent {
        margin-left: 0;
        font-size: $font-level-8 * 0.875;
      }
    }

    &.video-processing-info {
      flex-direction: column;
      align-items: flex-start;

      .finish-uploaded {
        margin-right: unset;
        display: flex;
        align-items: center;
        font-size: $font-level-8 * 0.875;
        margin-bottom: $spacing-base * 0.25;

        .icon {
          font-size: $font-level-8;
        }
      }

      .progress-circle-container {
        margin-left: unset;

        display: flex;
        align-items: center;

        .circle-desktop {
          display: none;
        }

        .circle-mobile {
          display: flex;
        }

        .process-label {
          margin-left: $spacing-base * 0.25;
          font-size: $font-level-8 * 0.875;
        }
      }
    }
  }
}

</style>
