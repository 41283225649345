import isEmpty from 'lodash/isEmpty';
import {
  ActionContext, ActionTree, GetterTree, MutationTree,
} from 'vuex';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';

import { LoadingState } from '@/modules/shared/types/state.type';
import { Country } from '@/modules/shared/types/country.type';
import { RootState } from '@/store/type';
import {
  GeoblockRule,
  GeoblockFilterProps,
} from '../types/Geoblock.type';
import { loadCountryList, loadGeoblockRules } from '../services';

export interface GeoblockRuleListState {
  geoblockRules: GeoblockRule[] | null;
  countryList: Country[];
  lastPage: number;
  loadGeoblockRulesState: {
    status: string;
    error: any;
  };
  loadCountryListState: {
    status: string;
    error: any;
  };
}

type GeoblockRuleContext = ActionContext<GeoblockRuleListState, RootState>;

const state: GeoblockRuleListState = {
  geoblockRules: [],
  countryList: [],
  lastPage: 0,
  loadGeoblockRulesState: setStateLoadingStatusByType(),
  loadCountryListState: setStateLoadingStatusByType(),
};

const getters: GetterTree<GeoblockRuleListState, RootState> = {
  isEmptyGeoblockRules: (state: GeoblockRuleListState): boolean => isEmpty(state.geoblockRules),
  isEmptyCountryList: (state: GeoblockRuleListState): boolean => isEmpty(state.countryList),
};

const mutations: MutationTree<GeoblockRuleListState> = {
  setGeoblockRules(state: GeoblockRuleListState, geoblockRules: GeoblockRule[]) {
    state.geoblockRules = geoblockRules;
  },
  setGeoblockRulesLastPage(state: GeoblockRuleListState, lastPage: number) {
    state.lastPage = lastPage;
  },
  setCountryList(state: GeoblockRuleListState, countryList: Country[]) {
    state.countryList = countryList;
  },
  destroyGeoblockRules(state: GeoblockRuleListState) {
    state.geoblockRules = null;
  },
  setLoadGeoblockRulesState(state: GeoblockRuleListState, loadingState: LoadingState) {
    state.loadGeoblockRulesState = setStateLoadingStatusByType(loadingState);
  },
  setLoadCountryListState(state: GeoblockRuleListState, loadingState: LoadingState) {
    state.loadCountryListState = setStateLoadingStatusByType(loadingState);
  },
};

const actions: ActionTree<GeoblockRuleListState, RootState> = {
  async loadGeoblockRules({ commit }: GeoblockRuleContext, filter: GeoblockFilterProps) {
    commit('setLoadGeoblockRulesState', { type: 'loading' });
    try {
      const response = await loadGeoblockRules(filter);
      commit('setGeoblockRules', response.data.data);
      commit('setGeoblockRulesLastPage', response.data.lastPage);
      commit('setLoadGeoblockRulesState', { type: 'success' });
    } catch (error) {
      commit('setLoadGeoblockRulesState', { type: 'error', error: formatErrorObject(error, 'Geoblock Rule') });
    }
  },
  async loadCountryList({ commit }: GeoblockRuleContext) {
    commit('setLoadCountryListState', { type: 'loading' });
    try {
      const response = await loadCountryList();
      commit('setCountryList', response.data);
      commit('setLoadCountryListState', { type: 'success' });
    } catch (error) {
      commit('setLoadCountryListState', { type: 'error', error: formatErrorObject(error, 'Country List') });
    }
  },
  destroyGeoblockRules({ commit }: GeoblockRuleContext) {
    commit('destroyGeoblockRules');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
