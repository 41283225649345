import { useSlots as _useSlots, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "input-wrapper" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["required", "data-test", "placeholder", "disabled", "value", "type", "min", "id", "readonly", "maxlength", "autofocus"]
const _hoisted_4 = {
  key: 1,
  class: "fa fa-warning text-danger"
}
const _hoisted_5 = { key: 2 }

import { computed, VNode, toRefs } from 'vue';
import { InputProps } from '@/modules/shared/types/input.type';


export default /*@__PURE__*/_defineComponent({
  __name: 'Input',
  props: {
    suffixText: {},
    prefixText: {},
    required: { type: Boolean, default: false },
    modelValue: {},
    id: {},
    isSearchInput: { type: Boolean },
    isShowErrorMessage: { type: Boolean },
    isShowCharacterCount: { type: Boolean },
    onSearch: {},
    onBlur: {},
    onChange: {},
    onInput: {},
    onKeyDown: {},
    onPressedEnter: {},
    maxWidth: {},
    disabled: { type: Boolean },
    min: {},
    max: {},
    placeholder: {},
    autofocus: { type: Boolean },
    readonly: { type: Boolean },
    inputType: { default: 'text' },
    dataTest: {}
  },
  emits: ["update:modelValue", "focus"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const {
  suffixText,
  prefixText,
  modelValue,
  id,
  isSearchInput,
  isShowErrorMessage,
  isShowCharacterCount,
  onSearch,
  onBlur,
  onChange,
  onPressedEnter,
  onInput,
  maxWidth,
  readonly,
  disabled,
  min,
  max,
  placeholder,
  autofocus,
  inputType,
  dataTest,
  required,
} = toRefs(props);

const emit = __emit;

const slots = _useSlots();

const characterCountText = computed(() => {
  if (!modelValue.value) {
    return `0 / ${max.value}`;
  }

  if (typeof modelValue.value === 'string') {
    return `${modelValue.value.length ?? 0} / ${max.value}`;
  }

  return `${modelValue.value ?? 0} / ${max.value}`;
});

function onInputChange(event: Event) {
  if (event.target && event.target instanceof HTMLInputElement) {
    emit('update:modelValue', event.target.value);
  }

  if (typeof onInput.value === 'function') {
    onInput.value(event);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["component-container", [_unref(inputType)]]),
    style: _normalizeStyle({ 'max-width': _unref(maxWidth) })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_unref(prefixText))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "prefix-container",
            innerHTML: _unref(prefixText)
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["input-container", { 'has-error': _unref(isShowErrorMessage), disabled: _unref(disabled), 'has-prefix': _unref(prefixText)}])
      }, [
        _createElementVNode("input", {
          class: _normalizeClass(["input", { 'has-icon': slots['icon'] }]),
          required: _unref(required),
          "data-test": _unref(dataTest),
          placeholder: _unref(placeholder),
          disabled: _unref(disabled),
          value: _unref(modelValue),
          type: _unref(inputType),
          min: _unref(min),
          id: _unref(id),
          readonly: _unref(readonly),
          maxlength: _unref(max),
          autofocus: _unref(autofocus),
          onKeydown: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_unref(onPressedEnter) && _unref(onPressedEnter)(...args)), ["enter"])),
          onBlur: _cache[1] || (_cache[1] = (event) => {
            if (typeof _unref(onBlur) === 'function') {
              _unref(onBlur)(event)
            }
            emit('focus', false)
          }),
          onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(onChange) && _unref(onChange)(...args))),
          onFocus: _cache[3] || (_cache[3] = ($event: any) => {emit('focus', true);}),
          onInput: onInputChange
        }, null, 42, _hoisted_3),
        (_unref(isShowErrorMessage) || _unref(suffixText) || _unref(isSearchInput) || _unref(isShowCharacterCount))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['suffix-container', { 'search': _unref(isSearchInput) }])
            }, [
              (_unref(isSearchInput) && !_unref(isShowErrorMessage))
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: "fa fa-magnifying-glass search-icon",
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_unref(onSearch) && _unref(onSearch)(...args)))
                  }))
                : (_unref(isShowErrorMessage) && !_unref(disabled))
                  ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                  : (_unref(isShowCharacterCount) && _unref(max))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(characterCountText.value), 1))
                    : (_unref(suffixText))
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 3,
                          class: _normalizeClass({'has-error':_unref(isShowErrorMessage)})
                        }, "- " + _toDisplayString(" ") + _toDisplayString(_unref(suffixText)), 3))
                      : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ])
  ], 6))
}
}

})