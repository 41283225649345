/* eslint-disable no-shadow */
import { loadPlayers } from '@/modules/videoEmbedPlayer';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { IPlayer, FilterPlayer, PlayerListState } from '@/modules/videoEmbedPlayer/types/Player.type';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { LoadingState } from '@/modules/shared/types/state.type';
import {
  setStateLoadingStatusByType,
} from '../../shared/utils/stateManagement';

type PlayerListContext = ActionContext<PlayerListState, RootState>

const initialState = () => ({
  playerList: null,
  loadPlayerListState: setStateLoadingStatusByType(),
});

const state: PlayerListState = initialState();

const mutations = {
  setPlayerList(state: PlayerListState, playerList: IPlayer[]) {
    state.playerList = playerList;
  },
  setLoadPlayerListState(state: PlayerListState, loadingState: LoadingState) {
    state.loadPlayerListState = setStateLoadingStatusByType(loadingState);
  },
  destroyPlayerList(state: PlayerListState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadPlayerList({ commit }: PlayerListContext, filter: FilterPlayer) {
    try {
      commit('setLoadPlayerListState', { type: 'loading' });
      const response = await loadPlayers({ ...filter, limit: 100 });
      commit('setPlayerList', response.data.data);
      commit('setLoadPlayerListState', { type: 'success' });
    } catch (error) {
      // TODO: Change payload to error
      commit('setLoadPlayerListState', { type: 'error', error: formatErrorObject(error, 'Player') });
      // commit('setLoadPlayerListErrorMessage', statusCode);
    }
  },
  destroyPlayerList({ commit }: PlayerListContext) {
    commit('destroyPlayerList');
  },
};

export default {
  state,
  mutations,
  actions,
};
