import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { toRefs } from 'vue';
import Popper from 'vue3-popper';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { ButtonStyle } from '@/modules/shared/types/button.type';
import { Nullable } from '@/modules/shared/types/index.type';

export interface DropdownProps {
  text?: Nullable<string>;
  iconClassName?: Nullable<string>;
  iconPrefixClassName?: Nullable<string>;
  buttonClassName?: Nullable<string>;
  buttonStyle?: ButtonStyle;
  buttonText?: Nullable<string>;
  // menuAlignment?: 'left' | 'right';
  // TODO : Make style with large, small sizing..
  // size?: 'small' | 'medium' | 'large';
  dataTest?: string;
  withArrow?: boolean;
  offsetSkid?: string;
  onOpenDropdown?: () => void;
  onCloseDropdown?: () => void;
  show?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Dropdown',
  props: {
    text: {},
    iconClassName: { default: 'fas fa-ellipsis-vertical' },
    iconPrefixClassName: {},
    buttonClassName: { default: 'btn-outline-secondary' },
    buttonStyle: { default: 'text-secondary' },
    buttonText: {},
    dataTest: { default: 'dropdown' },
    withArrow: { type: Boolean, default: true },
    offsetSkid: { default: `${-50 + 10}` },
    onOpenDropdown: {},
    onCloseDropdown: {},
    show: { type: Boolean, default: undefined }
  },
  setup(__props: any) {

const props = __props;

const {
  text,
  iconClassName,
  iconPrefixClassName,
  buttonClassName,
  buttonStyle,
  buttonText,
  // menuAlignment,
  dataTest,
  withArrow,
  onOpenDropdown,
  onCloseDropdown,
  show,
} = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Popper), {
    arrow: _unref(withArrow),
    placement: "bottom",
    class: "dropdown",
    offsetSkid: _ctx.offsetSkid,
    offsetDistance: (-3 + 10).toString(),
    zIndex: "5",
    "onOpen:popper": _unref(onOpenDropdown),
    "onClose:popper": _unref(onCloseDropdown),
    show: _unref(show),
    locked: ""
  }, {
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    default: _withCtx(() => [
      _createVNode(Button, {
        type: "button",
        dataTest: `${_unref(dataTest)}-popper-button`,
        buttonClasses: `btn-dropdown ${_unref(text) ? '' : 'btn-icon'} ${_unref(buttonClassName) ? _unref(buttonClassName) : ''}`,
        buttonStyle: _unref(buttonStyle)
      }, _createSlots({ _: 2 }, [
        (_unref(iconPrefixClassName))
          ? {
              name: "icon-prefix",
              fn: _withCtx(() => [
                _createElementVNode("i", {
                  class: _normalizeClass(_unref(iconPrefixClassName))
                }, null, 2)
              ]),
              key: "0"
            }
          : undefined,
        (_unref(buttonText))
          ? {
              name: "content",
              fn: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(buttonText)), 1)
              ]),
              key: "1"
            }
          : undefined,
        (_unref(iconClassName))
          ? {
              name: "icon-suffix",
              fn: _withCtx(() => [
                _createElementVNode("i", {
                  class: _normalizeClass(_unref(iconClassName))
                }, null, 2)
              ]),
              key: "2"
            }
          : undefined
      ]), 1032, ["dataTest", "buttonClasses", "buttonStyle"])
    ]),
    _: 3
  }, 8, ["arrow", "offsetSkid", "offsetDistance", "onOpen:popper", "onClose:popper", "show"]))
}
}

})