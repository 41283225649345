import qs from 'qs';
import { axiosAPI, axiosAPIv2 } from '@/plugins/axios';
import type { Filter, ListApiResponse } from '@/modules/shared/types/axios.type';
import type {
  Caption,
  CaptionDraft,
  UpdateCaptionDraftStatusPayload,
  UploadNewCaptionPayload,
} from '../types';

// caption draft
export function getCaptionDraftList(videoKey: string, filter?: Filter) {
  const query = qs.stringify({ videoKey, ...filter }, {
    addQueryPrefix: true,
    skipNulls: true,
    arrayFormat: 'comma',
    encode: false,
  });
  return axiosAPI.get<ListApiResponse<CaptionDraft>>(`/caption-drafts${query}`);
}

export function getCaptionDraftById(id: string) {
  return axiosAPI.get<CaptionDraft>(`/caption-drafts/${id}`);
}

export function createCaptionDraft(videoKey: string, revisionKey: string, payload: CaptionDraft) {
  return axiosAPI.post('/caption-drafts', { ...payload, videoKey, revisionKey });
}

interface CreateTypeManuallyPayload {
  publishStatusSetting: 'draft',
  autoGenerateCaptionSettingId: null,
  audioVariantKey: null,
  captionDrafts: Partial<CaptionDraft>[],
}

export function createCaptionDraftTypeManually(videoKey: string, payload: CreateTypeManuallyPayload) {
  return axiosAPIv2.post('/caption-drafts/manual', { ...payload, videoKey });
}

interface SaveCaptionDraftPayload {
  content: {
    startTime: number,
    endTime: number,
    text: string,
    cueSettings: string | null,
  }[];
}

export function saveCaptionDraft(captionDraftKey: string, payload: SaveCaptionDraftPayload) {
  return axiosAPIv2.put(`/caption-drafts/${captionDraftKey}/save-draft`, payload);
}

export function getCaptionDraftByKey(captionDraftKey: string) {
  return axiosAPI.get(`/caption-drafts/${captionDraftKey}`);
}

export function updateCaptionDraftName(captionDraftKey: string, name: string) {
  return axiosAPI.put(`/caption-drafts/${captionDraftKey}/name`, { name });
}

export function updateCaptionDraftLanguage(captionDraftKey: string, languageCode: string) {
  return axiosAPI.put(`/caption-drafts/${captionDraftKey}/lang`, { languageCode });
}

export function updateCaptionDraftStatus(captionDraftKey: string, payload: UpdateCaptionDraftStatusPayload) {
  return axiosAPIv2.put(`/caption-drafts/${captionDraftKey}/status`, payload);
}

export function deleteCaptionDraftByKey(captionDraftKey: string) {
  return axiosAPI.delete(`/caption-drafts/${captionDraftKey}`);
}

interface UploadCaptionDraftPayload {
  videoKey: string;
  revisionKey: string;
  languageCode: string;
  file: File;
  customName: string;
  publishStatusSetting: string;
}

export function uploadCaptionDraft(payload: UploadCaptionDraftPayload) {
  const formData = new FormData();
  formData.append('captionFile', payload.file);
  formData.append('videoKey', payload.videoKey);
  formData.append('revisionKey', payload.revisionKey);
  formData.append('customName', payload.customName);
  formData.append('languageCode', payload.languageCode);
  formData.append('publishStatusSetting', payload.publishStatusSetting);

  return axiosAPI.post('/caption-drafts/upload', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export function downloadCaptionFile(captionDraftKey: string) {
  return axiosAPI.get(`/caption-drafts/${captionDraftKey}/download`);
}

export function updateCaptionDraftFile(captionDraftKey: string, captionFile: File) {
  const formData = new FormData();
  formData.append('captionFile', captionFile);
  return axiosAPI.put(`/caption-drafts/${captionDraftKey}/upload-file`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export function duplicateCaptionDraft(captionDraftKey: string) {
  return axiosAPIv2.post<CaptionDraft>(`/caption-drafts/duplicate/${captionDraftKey}`);
}

// vod caption
export function getVODCaptionList(videoKey: string, revisionKey: string) {
  return axiosAPI.get(`/videos/${videoKey}/vods/${revisionKey}/captions`);
}

export function uploadNewVideoCaption(videoKey: string, vodKey: string, payload: UploadNewCaptionPayload) {
  const formData = new FormData();
  formData.append('languageCode', payload.languageCode);
  formData.append('customName', payload.customName);
  formData.append('captionFile', payload.file);

  return axiosAPI.post(`/videos/${videoKey}/vods/${vodKey}/captions`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export function updateVideoCaption(videoKey: string, vodKey: string, captionKey: string, payload: Caption) {
  return axiosAPI.put(`/videos/${videoKey}/vods/${vodKey}/captions/${captionKey}`, payload);
}

export function updateVideoCaptionStatus(videoKey: string, vodKey: string, captionKey: string, payload: Caption) {
  return axiosAPI.put(`/videos/${videoKey}/vods/${vodKey}/captions/${captionKey}/status`, payload);
}

export function deleteVideoCaption(videoKey: string, vodKey: string, captionKey: string) {
  return axiosAPI.delete(`/videos/${videoKey}/vods/${vodKey}/captions/${captionKey}`);
}
