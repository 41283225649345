<template>
  <ErrorToast
    v-if="deleteVideosState.status === 'error' && deleteVideosState.error"
    :title="t('response:errorTitle')"
    :description="`${t('response:deleteVideosError.title')} (Error code: ${deleteVideosState.error.statusCode})`"
    :duration="10000"
  />
  <Modal
    :title="t('project.deleteVideos.title')"
    @modalClose="onClose"
    size="small"
    :primaryButtonText="t(confirmButtonKey, { count: videoCount })"
    :onClickPrimaryButton="onConfirmDeleteVideos"
    primaryButtonStyle="danger"
    :isDisabledPrimaryButton
  >
    <template v-slot:body>
      <div class="content-container">
        <Alert
          priority="danger"
          :alertText="t('project.deleteVideos.modal.alert', { count: videoCount, projectName })"
        />
        <p class="description">
          {{ t('project.deleteVideos.modal.description1') }}
          <router-link :to="{ name: 'recentlyDeleted' }">
            {{ t('project.deleteVideos.modal.description2') }}
          </router-link>
          {{ ' ' }}
          <span v-html="t('project.deleteVideos.modal.description3')"></span>
        </p>
        <div>
          <p class="input-label">
            {{ t('project.deleteVideos.modal.description4') }}
            “<span class="code">delete all videos</span>”
            {{ t('project.deleteVideos.modal.description5') }}
          </p>
          <Input v-model="confirmText" :onPressedEnter="onConfirmDeleteVideos" />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import ErrorToast from '@/modules/shared/components/molecules/toasts/ErrorToast.vue';
import {
  computed, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { StatusState } from '@/modules/shared/types/state.type';
import { deleteAllVideos } from '../../../services';
import { useStore } from '../../../store/hooks';

interface deleteVideosModalProps {
  onClose: () => void;
}

const props = defineProps<deleteVideosModalProps>();
const { onClose } = toRefs(props);

const store = useStore();
const router = useRouter();
const { t } = useI18n();

const confirmText = ref<string>('');
const deleteVideosState = ref<StatusState>(setStateLoadingStatusByType());

const videoCount = computed(() => store.state.project.currentProject?.videoCount ?? 0);
const projectName = computed(() => store.state.project.currentProject?.name ?? '');
const projectKey = computed(() => store.state.project.currentProject?.key ?? '');
const loadProjectListState = computed(() => store.state.projectList.loadProjectListState);

const confirmButtonKey = computed(() => (
  `project.deleteVideos.modal.button${videoCount.value > 1 ? '_plural' : ''}`
));

const isDisabledPrimaryButton = computed(() => (
  loadProjectListState.value.status === 'loading'
  || confirmText.value !== 'delete all videos'
  || deleteVideosState.value.status === 'loading'
));

async function onConfirmDeleteVideos() {
  if (isDisabledPrimaryButton.value || !projectKey.value) {
    return;
  }

  deleteVideosState.value = setStateLoadingStatusByType({ type: 'loading' });

  try {
    await deleteAllVideos(projectKey.value);
    store.commit('setAreProjectVideosDeleted', true);
    setTimeout(() => store.commit('setAreProjectVideosDeleted', false), 5000);

    deleteVideosState.value = setStateLoadingStatusByType({ type: 'success' });
    await router.push({ name: 'recentlyDeleted' });
  } catch (error) {
    console.error(error);
    deleteVideosState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Delete Videos'),
    });

    setTimeout(() => onClose.value(), 100);
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables';

:deep(.alert-container) {
  margin-bottom: 0;
}

.description {
  :deep(strong) {
    font-weight: 500;
  }
}

.content-container {
  display: flex;
  flex-flow: column;
  gap: 24px;
}

.input-label {
  margin-bottom: $spacing-4;

  .code {
    font-family: $font-family-Monospace;
    color: #E83E8C;
  }
}
</style>
