import qs from 'qs';
import { axiosAPI } from '@/plugins/axios';
import { qsOption } from '@/services/constants';
import type { Filter } from '@/modules/shared/types/axios.type';
import type { ExportFileListResponse } from '../types';

export function loadProjectExportFiles(projectKey: string, filter: Filter) {
  const query = qs.stringify(filter, qsOption);
  return axiosAPI.get<ExportFileListResponse>(`/projects/${projectKey}/videos/download${query}`);
}
