import { useSlots as _useSlots, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "geoblock-rule" }
const _hoisted_2 = {
  key: 0,
  class: "title"
}
const _hoisted_3 = {
  key: 1,
  class: "rules"
}
const _hoisted_4 = {
  key: 0,
  class: "rule-item"
}
const _hoisted_5 = { class: "rule-item-content" }
const _hoisted_6 = {
  key: 0,
  class: "key",
  "data-test": "allow-some-label"
}
const _hoisted_7 = {
  key: 1,
  class: "key",
  "data-test": "deny-all-label"
}
const _hoisted_8 = {
  key: 1,
  class: "rule-item"
}
const _hoisted_9 = { class: "rule-item-content" }
const _hoisted_10 = {
  class: "key",
  "data-test": "deny-some-label"
}
const _hoisted_11 = {
  key: 2,
  class: "rules",
  "data-test": "allow-all-label"
}
const _hoisted_12 = { class: "rule-item" }

import _ from 'lodash';
import {
  computed, onMounted, toRefs, VNode,
} from 'vue';
import { useI18n } from 'vue-i18n';
import CountryBadge from '@/modules/shared/components/molecules/countryBadges/CountryBadges.vue';
import type { Nullable } from '@/modules/shared/types/index.type';
import type { GeoblockRule, GeoblockRuleInformation } from '../../../types/Geoblock.type';
import { useStore } from '../../../store/hooks';

interface GeoblockRuleInformationProps {
  geoblockRule?: Nullable<GeoblockRule | GeoblockRuleInformation>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GeoblockRuleInformation',
  props: {
    geoblockRule: {}
  },
  setup(__props: any) {

const props = __props;
const { geoblockRule } = toRefs(props);

const slot = _useSlots();

const { t } = useI18n();
const store = useStore();

const countryList = computed(() => store.state.geoblockRuleList.countryList);
const allowCountryCodes = computed(() => _.get(geoblockRule.value, 'allowCountryCodes', []));
const denyCountryCodes = computed(() => _.get(geoblockRule.value, 'denyCountryCodes', []));
const isAllowCountryCodesEmpty = computed(() => _.isEmpty(allowCountryCodes.value));
const isDenyCountryCodesEmpty = computed(() => _.isEmpty(denyCountryCodes.value));

function getCountryFullName(countryCode: string) {
  const country = _.find(countryList.value, { countryCode });
  return _.get(country, 'countryNameEN', '');
}

const geoblockRuleAllowCountry = computed(() => _.map(allowCountryCodes.value, getCountryFullName));
const geoblockRuleDenyCountry = computed(() => _.map(denyCountryCodes.value, getCountryFullName));

function isAllowAllCountries() {
  if (isAllowCountryCodesEmpty.value && isDenyCountryCodesEmpty.value) {
    return true;
  }
  return false;
}

// This method will be remove after we refactor allowCountryCodes conditions
function isDisallowAllCountries(value: string[]) {
  if (value && value[0] === 'XX') {
    return true;
  }
  return false;
}

onMounted(() => {
  if (!countryList.value?.length) {
    store.dispatch('loadCountryList');
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "geoblock-title"),
    (!slot['geoblock-title'])
      ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_unref(geoblockRule)?.name ?? ''), 1))
      : _createCommentVNode("", true),
    (!isAllowAllCountries())
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!isAllowCountryCodesEmpty.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  (!isDisallowAllCountries(allowCountryCodes.value))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Allowed (" + _toDisplayString(allowCountryCodes.value.length) + ") ", 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, " All countries are blocked ")),
                  (!isDisallowAllCountries(allowCountryCodes.value))
                    ? (_openBlock(), _createBlock(CountryBadge, {
                        key: 2,
                        countries: geoblockRuleAllowCountry.value
                      }, null, 8, ["countries"]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          (!isDenyCountryCodesEmpty.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, " Blocked (" + _toDisplayString(denyCountryCodes.value.length) + ") ", 1),
                  (!isDisallowAllCountries(allowCountryCodes.value))
                    ? (_openBlock(), _createBlock(CountryBadge, {
                        key: 0,
                        countries: geoblockRuleDenyCountry.value
                      }, null, 8, ["countries"]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (isAllowAllCountries())
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(t)('geoBlock.allowAll.subTitle')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})