<template>
  <div v-if="selectedContent" class="set-bumper-display-section">
    <div class="background-mask" />
    <ChooseBumperModal
      v-if="isShowChooseBumperModal"
      @modalClose="onCloseChooseBumperModal"
      @submit="(submitValue) => onSelectedBumper(submitValue)"
      :disabled-categories="disabledBumperPosition"
      :category-name="addToCategoryName"
      :is-already-choose-category="Boolean(addToCategoryName.length)"
    />
    <SelectBumperContentModal
      v-if="isShowSelectBumperContentModal && selectedContent.projectKey"
      :selected-content-type="selectedContent.type"
      :projectKey="selectedContent.projectKey"
      @modalClose="() => isShowSelectBumperContentModal = false"
      @update:projectKey="(projectKey) => onChangeContentProject(projectKey)"
      :submit-button-title="t('bumperSchedule.create.selectContent')"
      :onSubmit="onContentChange"
      :secondaryButtonTitle="t('common:button.cancel')"
    />
    <div class="content-container">
      <Button @click="handleClose" buttonStyle="link-secondary" buttonClasses="back-link">
        <template v-slot:icon-prefix>
          <i class="fas fa-chevron-left"></i>
        </template>
        {{t('common:button.back')}}
      </Button>
      <h2 class="title">{{ isEditMode ? t('common:button.edit') : t('bumperSchedule.schedule.set') }} Bumper Insertion</h2>
      <div class="section-container section-use-in-content">
        <div class="title-container">
          <div class="section-title">{{ t('bumperSchedule.schedule.useInContent') }}</div>
        </div>
        <div v-if="!selectedContent?.content" class="video-item-empty"></div>
        <div v-if="selectedContent?.content" class="video-item">
          <div class="video-info">
            <div class="video-image">
              <!-- content.content is from bumper content
              selectedContent.content is from video object after changing content -->
              <ImageView
                :source="getVideoUploadCoverImage({ video: selectedContent.content.content, currentUser })"
                imageSize="small"
              />
              <div class="video-duration" v-if="selectedContent.content.content?.duration">
                {{ getVideoDuration(selectedContent.content.content) }}
              </div>
            </div>
            <div class="content-title-container">
              <a
                :href="getContentURL(selectedContent)"
                target="_blank"
                class="content-title"
              >
                {{ contentTitle }}
              </a>
              <span
                v-if="
                  selectedContent.type === 'project' &&
                    Boolean(selectedContent.content?.content?.videoCount)
                "
                class="count"
              >
                ({{ selectedContent.content.content.videoCount }}
                {{
                  t(
                    `glossary:video${
                      selectedContent.content.content.videoCount > 1 ? '_plural' : ''
                    }`
                  )
                }})
              </span>
              <Badge
                :title="
                  selectedContent?.type !== 'contentCollection'
                    ? selectedContent?.type
                    : 'collection'
                "
                badgeStyle="info"
              />
            </div>
          </div>
          <Button type="button" buttonStyle="secondary" @click="onClickChange">
            {{ t('common:button.change') }}
          </Button>
        </div>
      </div>
      <PreLoaderSection v-if="loadBumperDisplayDetailState?.status === 'loading'" />
      <div
        v-if="loadBumperDisplayDetailState?.status !== 'loading'"
        id="schedules"
        class="section-container"
      >
        <div class="title-container">
          <div class="section-title">Bumper Insertion</div>
          <Button v-if="!isEmpty" @click="() => onClickAddBumper('')" buttonStyle="secondary">
            <template v-slot:icon-prefix>
              <i class="fas fa-plus" />
            </template>
            {{ t('bumper.add') }}
          </Button>
        </div>
        <Section class="bumper-display-container">
          <div>
            <div class="tab-container">
              <TabList>
                <router-link
                  v-for="tab in bumperStatusTabs"
                  :key="tab.key"
                  :to="{ query: { tab: tab.key } }"
                  :class="{
                    active:
                      tab.key === 'active,scheduled'
                        ? !query?.tab || query?.tab === tab.key
                        : query?.tab === tab.key,
                  }"
                  active-class=""
                  exact-active-class=""
                  class="tab-item"
                >
                  <span class="tab-item-menu">
                    {{ tab.title }}
                  </span>
                </router-link>
              </TabList>
            </div>
            <div class="description">{{ t('bumperSchedule.schedule.description') }}</div>
            <div
              v-if="!isEditMode || loadBumperDisplayDetailState?.status === 'success'"
              class="bumper-group-container"
            >
              <BumperDraggableGroup
                name="Intro 1"
                @add="onClickAddBumper('Intro 1')"
                :parentBumper="parentBumperDisplay"
                :inheritedBumpers="getParentBumpers('intro1')"
                :value="filteredIntro1"
              >
                Intro 1
                <Tooltip>
                  <i class="fas fa-info-circle"></i>
                  <template v-slot:tooltip-text>
                    <div class="tooltip-text">
                      Intro 1:
                      <ul class="bumper-feature">
                        <li v-html="t('bumperSchedule.position.intro')" />
                        <li v-html="t('bumperSchedule.position.nonAds')" />
                        <li v-html="t('bumperSchedule.position.nonSkippable')" />
                      </ul>
                    </div>
                  </template>
                </Tooltip>
              </BumperDraggableGroup>
              <BumperDraggableGroup
                name="Intro 2"
                @add="onClickAddBumper('Intro 2')"
                :parentBumper="parentBumperDisplay"
                :inheritedBumpers="getParentBumpers('intro2')"
                :value="filteredIntro2"
              >
                Intro 2
                <Tooltip>
                  <i class="fas fa-info-circle"></i>
                  <template v-slot:tooltip-text>
                    <div class="tooltip-text">
                      Intro 2:
                      <ul class="bumper-feature">
                        <li v-html="t('bumperSchedule.position.intro')" />
                        <li v-html="t('bumperSchedule.position.ads')" />
                        <li v-html="t('bumperSchedule.position.skippable')" />
                      </ul>
                    </div>
                  </template>
                </Tooltip>
              </BumperDraggableGroup>
              <BumperDraggableGroup
                name="Outro 1"
                @add="onClickAddBumper('Outro 1')"
                :parentBumper="parentBumperDisplay"
                :inheritedBumpers="getParentBumpers('outro1')"
                :value="filteredOutro1"
              >
                Outro 1
                <Tooltip>
                  <i class="fas fa-info-circle"></i>
                  <template v-slot:tooltip-text>
                    <div class="tooltip-text">
                      Outro 1:
                      <ul class="bumper-feature">
                        <li v-html="t('bumperSchedule.position.outro')" />
                        <li v-html="t('bumperSchedule.position.nonAds')" />
                        <li v-html="t('bumperSchedule.position.nonSkippable')" />
                      </ul>
                    </div>
                  </template>
                </Tooltip>
              </BumperDraggableGroup>
              <BumperDraggableGroup
                name="Outro 2"
                @add="onClickAddBumper('Outro 2')"
                :parentBumper="parentBumperDisplay"
                :inheritedBumpers="getParentBumpers('outro2')"
                :value="filteredOutro2"
              >
                Outro 2
                <Tooltip>
                  <i class="fas fa-info-circle"></i>
                  <template v-slot:tooltip-text>
                    <div class="tooltip-text">
                      Outro 2:
                      <ul class="bumper-feature">
                        <li>{{t('bumperSchedule.position.outro')}}</li>
                        <li>
                          {{t('bumperSchedule.position.nonAds')}}
                        </li>
                        <li>{{t('bumperSchedule.position.nonSkippable')}}</li>
                      </ul>
                    </div>
                  </template>
                </Tooltip>
              </BumperDraggableGroup>
            </div>
          </div>
        </Section>
      </div>
      <div v-if="loadBumperDisplayDetailState?.status !== 'loading'" class="footer-container">
        <span class="saving-status error" v-if="saveBumperDisplayState?.status == 'error'">
          <i class="fa fa-xmark" />
          {{ formatErrorCode(saveBumperDisplayState.error) }}
        </span>
        <span class="saving-status success" v-if="saveBumperDisplayState?.status == 'success'">
          <i class="fas fa-check" />
          {{ t('common:button.saved') }}
        </span>
        <Button buttonStyle="text-secondary" @click="handleClose">
          {{ t('common:button.cancel') }}
        </Button>
        <Button
          :disabled="isEmpty || saveBumperDisplayState?.status === 'saving'"
          :isSaving="saveBumperDisplayState?.status === 'saving'"
          @click="onConfirm"
        >
          {{ isEditMode ? t('common:button.save') : t('bumperSchedule.schedule.add') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import {
  computed, onMounted, onUnmounted, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { loadBumperDisplays } from '@/modules/bumperDisplay/services';
import type { Bumper } from '@/modules/bumper/types';
import type {
  BumperDisplay,
  BumperDisplayContentsPayload,
  BumperDisplayPayload,
  BumperSource, ChooseBumperModalSubmitPayload, SelectedContent,
} from '@/modules/bumperDisplay/types';
import { getBumperStatusTabs } from '@/modules/bumper';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { useBumperDisplay } from '@/modules/bumperDisplay/utils';
import {
  getVideoDuration,
  getVideoUploadCoverImage,
} from '@/modules/videoDetail/utils/videoManagement';
import TabList from '@/modules/shared/components/molecules/tabList/TabList.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import ChooseBumperModal from '@/modules/bumperDisplay/components/organisms/chooseBumperModal/ChooseBumperModal.vue';
import BumperDraggableGroup from '@/modules/bumperDisplay/components/organisms/bumperDraggableGroup/BumperDraggableGroup.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import SelectBumperContentModal from '@/modules/bumperDisplay/components/organisms/selectBumperContentModal/SelectBumperContentModal.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import { useStore } from '../../../store/hooks';

interface SetBumperDisplayModalProps {
  onClose?: () => void;
  idToEdit?: string;
}

const props = defineProps<SetBumperDisplayModalProps>();
const { onClose, idToEdit } = toRefs(props);

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const bumperStatusTabs = getBumperStatusTabs();

const filteredIntro1 = ref<Array<Bumper>>([]);
const filteredIntro2 = ref<Array<Bumper>>([]);
const filteredOutro1 = ref<Array<Bumper>>([]);
const filteredOutro2 = ref<Array<Bumper>>([]);
const isShowChooseBumperModal = ref<boolean>(false);
const isShowSelectBumperContentModal = ref<boolean>(false);
const addToCategoryName = ref<string>('');
const selectedProjectKey = ref<string | null>(null);
const isLoadFromParent = ref<boolean | null>(null);

const intro1 = computed(() => store.state.bumperDisplay.intro1);
const intro2 = computed(() => store.state.bumperDisplay.intro2);
const outro1 = computed(() => store.state.bumperDisplay.outro1);
const outro2 = computed(() => store.state.bumperDisplay.outro2);
const currentUser = computed(() => store.state.user.currentUser);
const selectedContent = computed(() => store.state.bumperDisplay.selectedContent);
const bumperDisplayDetail = computed(() => store.state.bumperDisplay.bumperDisplayDetail);
const loadBumperDisplayDetailState = computed(() => store.state.bumperDisplay.loadBumperDisplayDetailState);
const saveBumperDisplayState = computed(() => store.state.bumperDisplay.saveBumperDisplayState);
const parentBumperDisplay = computed(() => store.state.bumperDisplay.parentBumperDisplay);
const bumperSources = computed<BumperSource[]>(() => store.state.bumperDisplay.bumperSources);
const showSaveErrorAlertBox = computed(() => store.state.bumperDisplay.showSaveErrorAlertBox);
const newBumperDisplayId = computed(() => store.state.bumperDisplay.newBumperDisplayId);

const currentTab = computed(() => getQueryAsString(route.query, 'tab'));

function filterBumperByStatus(bumperList: Bumper[], tabKey: string | null): Bumper[] {
  if (!tabKey || tabKey === 'active,scheduled') {
    return bumperList.filter((item) => item.visibility?.status === 'active' || item.visibility?.status === 'scheduled');
  }
  return bumperList.filter((item) => item.visibility?.status === tabKey);
}

const teamName = computed(() => route.params.teamName);
const query = computed(() => route.query);
const hash = computed(() => route.hash);

const scheduleId = computed(() => getQueryAsString(route.params, 'scheduleId') || idToEdit.value);
const isEditMode = computed(() => Boolean(scheduleId.value) || Boolean(idToEdit.value));
const isEmpty = computed(() => _.isEmpty(intro1.value) && _.isEmpty(intro2.value) && _.isEmpty(outro1.value) && _.isEmpty(outro2.value));

const contentTitle = computed(() => {
  let { content } = selectedContent.value;

  if (selectedContent.value.content?.content) {
    content = selectedContent.value.content.content;
  }

  return content.title || content.name;
});

const disabledBumperPosition = computed(() => {
  const disabledBumperList: string[] = [];
  if (bumperSources.value.length) {
    bumperSources.value.forEach((bumper) => {
      if (bumper.sourceType === 'parent' || bumper.sourceType === 'disabled') {
        disabledBumperList.push(bumper.positionName);
      }
    });
  }
  return disabledBumperList;
});

function getContentURL({ content, projectKey, type }: SelectedContent): string {
  switch (type) {
    case 'project':
      return `/${teamName.value}/projects/${content.content.key}`;
    case 'contentCollection':
      return `/${teamName.value}/projects/${projectKey}/collections/${content.contentId}`;
    default:
      return `/${teamName.value}/projects/${projectKey}/videos/${content.content.key}/information`;
  }
}

function onClickChange() {
  isShowSelectBumperContentModal.value = true;
}

function onClickAddBumper(categoryName = '') {
  addToCategoryName.value = categoryName;
  isShowChooseBumperModal.value = true;
}

function setPositions() {
  const { categorizedBumpers } = useBumperDisplay(bumperDisplayDetail.value);

  store.commit('setDisplayIntro1', categorizedBumpers.intro1);
  store.commit('setDisplayIntro2', categorizedBumpers.intro2);
  store.commit('setDisplayOutro1', categorizedBumpers.outro1);
  store.commit('setDisplayOutro2', categorizedBumpers.outro2);
}

function onSelectedBumper(submitValue: ChooseBumperModalSubmitPayload) {
  if (submitValue.type === 'intro1') {
    store.commit('setDisplayIntro1', [...intro1.value, submitValue.bumper]);
  }
  if (submitValue.type === 'intro2') {
    store.commit('setDisplayIntro2', [...intro2.value, submitValue.bumper]);
  }
  if (submitValue.type === 'outro1') {
    store.commit('setDisplayOutro1', [...outro1.value, submitValue.bumper]);
  }
  if (submitValue.type === 'outro2') {
    store.commit('setDisplayOutro2', [...outro2.value, submitValue.bumper]);
  }
  isShowChooseBumperModal.value = false;
  // Switch to All tab to prevent confusing that the added bumper didn't appear on a certain tab.
  router.push({ query: { tab: 'all' } });
}

function getPositionArrayBySourceType(sourceType: 'custom' | 'parent') {
  return [
    { positionName: 'intro1', sourceType },
    { positionName: 'intro2', sourceType },
    { positionName: 'outro1', sourceType },
    { positionName: 'outro2', sourceType },
  ];
}

function validateBumperContents(payload: BumperDisplayPayload) {
  let hasError = false;
  bumperSources.value.forEach((bumperSource) => {
    if (bumperSource.sourceType === 'custom') {
      const hasBumperData = _.find(payload.bumperContents, { positionName: bumperSource.positionName });

      if (!hasBumperData) {
        store.dispatch('setShowSaveErrorAlertBox', { positionName: bumperSource.positionName, value: true });
        hasError = true;
      }
    }
  });
  return hasError;
}

async function onContentChange(selected: SelectedContent) {
  store.dispatch('setSelectedContent', selected);
  store.commit('setSaveBumperDisplayState', { type: 'idle' });

  store.dispatch('setBumperSources', getPositionArrayBySourceType('parent'));
  isShowSelectBumperContentModal.value = false;
}

function getBumperContentPayload(positionName: string, data: Bumper[] | BumperDisplay[]): BumperDisplayContentsPayload[] {
  const bumperSource = bumperSources.value?.find((source) => source.positionName === positionName);

  if (bumperSource && bumperSource.sourceType === 'custom') {
    return data.map((item, index) => (
      {
        positionName,
        bumperId: item.id,
        index,
      }
    ));
  }

  return [];
}

async function onConfirm() {
  const { content, type } = selectedContent.value;

  const intro1BumperContents = getBumperContentPayload('intro1', intro1.value);
  const intro2BumperContents = getBumperContentPayload('intro2', intro2.value);
  const outro1BumperContents = getBumperContentPayload('outro1', outro1.value);
  const outro2BumperContents = getBumperContentPayload('outro2', outro2.value);

  const payloadContent = content.name || content.title ? content : content.content; // content.content is Edit Mode
  const payload: BumperDisplayPayload = {
    name: payloadContent?.name || payloadContent?.title,
    shouldAppearOnContent: {
      type,
      contentId: content.contentId || payloadContent?.id,
    },
    bumperContents: [
      ...intro1BumperContents,
      ...intro2BumperContents,
      ...outro1BumperContents,
      ...outro2BumperContents,
    ],
    bumperSources: bumperSources.value,
    id: '',
  };

  validateBumperContents(payload);

  if (isEditMode.value && scheduleId.value) {
    payload.id = scheduleId.value;
    await store.dispatch('saveBumperDisplay', payload);
  } else {
    await store.dispatch('createBumperDisplay', payload);
  }
}

function formatErrorCode(error: unknown): string {
  const { content, type } = selectedContent.value;
  const errorPosition = Object.keys(showSaveErrorAlertBox.value).find((position) => showSaveErrorAlertBox.value[position]);

  if (_.isNil(error)) {
    return '';
  }

  if (_.isObject(error) && 'streamErrorCode' in error) {
    switch (error.streamErrorCode) {
      case 'upsert-bumper-display-0001':
        return t('bumperSchedule.schedule.error.content', { contentType: type === 'contentCollection' ? 'Collection' : type.charAt(0).toUpperCase() + type.slice(1), contentName: content.content.name || content.content.title });
      case 'upsert-bumper-display-0002':
        switch (errorPosition) {
          case 'intro1':
            return t('bumperSchedule.schedule.error.custom.empty', { position: 'Intro 1' });
          case 'intro2':
            return t('bumperSchedule.schedule.error.custom.empty', { position: 'Intro 2' });
          case 'outro1':
            return t('bumperSchedule.schedule.error.custom.empty', { position: 'Outro 1' });
          default:
            return t('bumperSchedule.schedule.error.custom.empty', { position: 'Outro 2' });
        }
      default:
        return '';
    }
  }

  return '';
}

function onCloseChooseBumperModal() {
  isShowChooseBumperModal.value = false;
}

function handleClose() {
  if (typeof onClose.value === 'function') {
    onClose.value();
  } else {
    store.dispatch('clearBumperDisplayData');
    store.dispatch('loadBumperDisplayList');
    router.push({ name: 'manageBumperDisplay' });
  }
}

async function loadFromParent() {
  const contentCollectionId = selectedContent.value?.content?.content?.contentCollectionId;
  const { projectKey } = selectedContent.value;

  if (!_.isNil(projectKey)) {
    const projectScheduleResponse = await loadBumperDisplays({ projectKey });
    const bumperDisplays = projectScheduleResponse?.data?.data;

    if (!_.isNil(bumperDisplays) && !_.isEmpty(bumperDisplays)) {
      await store.dispatch('loadBumperDisplayDetailFromParent', bumperDisplays[0].id);
      isLoadFromParent.value = true;
    }

    return;
  }

  if (!_.isNil(contentCollectionId)) {
    const collectionScheduleResponse = await loadBumperDisplays({ appearOnContentId: contentCollectionId });
    const bumperDisplays = collectionScheduleResponse?.data?.data;

    if (!_.isNil(bumperDisplays) && !_.isEmpty(bumperDisplays)) {
      await store.dispatch('loadBumperDisplayDetailFromParent', bumperDisplays[0].id);
      isLoadFromParent.value = true;
    }

    return;
  }

  await store.dispatch('setBumperSources', getPositionArrayBySourceType('custom'));
  isLoadFromParent.value = true;
}

function getParentBumpers(positionName: string): Bumper[] {
  if (!isLoadFromParent.value) {
    return bumperDisplayDetail.value?.parentBumperContents
      ?.filter((content) => content.positionName === positionName)
      ?.map((content) => content.bumper) ?? [];
  }

  return bumperDisplayDetail.value?.summaryBumperContents
    ?.filter((content) => content.positionName === positionName)
    ?.map((content) => content.bumper) ?? [];
}

function onChangeContentProject(projectKey: string) {
  selectedProjectKey.value = projectKey;
}

function onTabChanged() {
  if (currentTab.value === 'all') {
    filteredIntro1.value = [...intro1.value];
    filteredIntro2.value = [...intro2.value];
    filteredOutro1.value = [...outro1.value];
    filteredOutro2.value = [...outro2.value];
  } else {
    filteredIntro1.value = filterBumperByStatus(intro1.value, currentTab.value);
    filteredIntro2.value = filterBumperByStatus(intro2.value, currentTab.value);
    filteredOutro1.value = filterBumperByStatus(outro1.value, currentTab.value);
    filteredOutro2.value = filterBumperByStatus(outro2.value, currentTab.value);
  }
}

watch(intro1, () => {
  const tab = currentTab.value;

  if (tab === 'all') {
    filteredIntro1.value = [...intro1.value];
  } else {
    filteredIntro1.value = filterBumperByStatus(intro1.value, tab);
  }
}, { deep: true });

watch(intro2, () => {
  const tab = currentTab.value;

  if (tab === 'all') {
    filteredIntro2.value = [...intro2.value];
  } else {
    filteredIntro2.value = filterBumperByStatus(intro2.value, tab);
  }
}, { deep: true });

watch(outro1, () => {
  const tab = currentTab.value;

  if (tab === 'all') {
    filteredOutro1.value = [...outro1.value];
  } else {
    filteredOutro1.value = filterBumperByStatus(outro1.value, tab);
  }
}, { deep: true });

watch(outro2, () => {
  const tab = currentTab.value;

  if (tab === 'all') {
    filteredOutro2.value = [...outro2.value];
  } else {
    filteredOutro2.value = filterBumperByStatus(outro2.value, tab);
  }
}, { deep: true });

watch(bumperDisplayDetail, () => {
  if (loadBumperDisplayDetailState.value.status === 'success') {
    setPositions();
  }
});

watch(selectedContent, (value, oldValue) => {
  if (oldValue.content && value.content) {
    const prevProject = oldValue?.projectKey;
    const currentProject = value?.projectKey;

    const prevCollection = oldValue?.content?.content?.contentCollectionId;
    const currentCollection = value?.content?.content?.contentCollectionId;
    if (prevProject !== currentProject || (prevCollection && currentCollection && prevCollection !== currentCollection)) {
      loadFromParent();
    }
  }
});

watch(saveBumperDisplayState, () => {
  if (saveBumperDisplayState.value?.status === 'success' && newBumperDisplayId.value) {
    router.push(`/${teamName.value}/manage/bumper-insertion/${newBumperDisplayId.value}`);
  }
});

watch(currentTab, onTabChanged);

onMounted(async () => {
  document.body.style.overflowY = 'hidden';
  if (!isEditMode.value) {
    if (selectedContent.value.type === 'project') {
      store.dispatch('setBumperSources', getPositionArrayBySourceType('custom'));
    } else {
      // get scheduleDetail from parent on create mode
      await loadFromParent();
    }
  } else {
    await store.dispatch('loadBumperDisplayDetail', scheduleId.value);
  }

  if (!currentTab.value) {
    router.push({
      hash: hash.value,
      query: { ...query.value, tab: 'active,scheduled' },
      replace: true,
    });
  } else {
    onTabChanged();
  }

  if (hash.value) {
    const targetElement = document.getElementById(hash.value.substring(1));
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
});

onUnmounted(() => {
  document.body.style.overflowY = 'auto';
  store.dispatch('clearBumperDisplayData');
  store.commit('setSaveBumperDisplayState', { type: 'idle' });
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/breakpoint';

.tab-container {
  background-color: #fff;
  margin-bottom: 8px;

  .tab {
    .tab-item {
      .tab-item-menu {
        display: block;
        font-weight: 500;
      }

      &:hover {
        .tab-item-menu {
          color: $ci-primary;
        }
      }
    }
  }
}

:deep(.list-header-container) {
  border-bottom: none;

  .list-item-container {
    background-color: #eef0f2;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    .list-item {
      color: #6c757d !important;
    }
  }
}

:deep(.list-body-container) {
  .list-item-container {
    display: block;
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: #fbfbfc;

    .list-item-detail {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

:deep(.popper) {
  max-width: 350px !important;
}

:deep(.tooltip-text) {
  text-align: left;
  font-size: $font-size-base;
  .bumper-feature {
    padding-left: 16px;
    max-width: 100%;
    overflow: hidden;
    white-space: normal;
    margin-top: $spacing-4;
  }
}

.set-bumper-display-section {
  position: fixed;
  top: 8px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 8px);
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  scroll-padding-top: 24px;
  z-index: 101;
  background-color: #f8f9fa;
  // background: #f8f9fa url('@/assets/images/bumper-bg.svg');
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
}

.background-mask{
  overflow: hidden;
  position: fixed;
  opacity: 0.6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: #f8f9fa url('@/assets/images/bumper-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.content-container {
  max-width: 1000px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.title {
  color: $grey-900;
  margin-top: 48px;
  margin-bottom: 32px;
}

.form-label {
  color: $grey-900;
  font-weight: 500;
}

.title-container {
  display: flex;
  margin-bottom: $spacing-base;
  justify-content: space-between;

  .btn {
    margin-left: auto;
  }
}

.section-title {
  color: $grey-900;
  font-weight: 700;
  font-size: $font-level-4;
  margin-bottom: 0;
}

.fa-info-circle {
  margin-left: 8px
}

.video-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  width: 100%;
  padding: $spacing-32;
  overflow: hidden;
  border-radius: 5px;

  .video-info {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    padding-right: $spacing-base;
    width: 100%;
  }

  .content-title-container {
    font-weight: 700;
    color: $grey-900;
    margin-bottom: 0;
    margin-left: 16px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .content-title {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    }

     a{
      color: $grey-900;
      &:hover{
        text-decoration: underline;
        text-underline-offset: 20%;
      }
    }

  }

  .badge {
    margin-left: $spacing-8;
    text-transform: capitalize;
  }

  .count {
    color: #b1b9c0;
    font-weight: 400;
    margin-left: 2px;
    text-transform: capitalize;
  }

  .video-image {
    cursor: pointer;
    position: relative;
    flex: 1 0 120px;
    max-width: 120px;

    .video-duration {
      position: absolute;
      bottom: 0;
      right: 0;
      color: $grey-200;
      font-size: $font-level-7;
      background-color: rgba(0, 0, 0, 0.75);
      padding: 4px 6px;
    }
  }

  .btn-light {
    background-color: #f8f9fa;
    color: #6c757d;
    font-weight: 500;
  }
}

.video-item-empty {
  background-color: #fff;
  height: 91.5px;
}

.section-container {
  margin-bottom: $spacing-48;
}

.bumper-display-container {
  .description {
    margin: $spacing-32 0;
  }
}

.empty-container {
  border-radius: 5px;
  padding: 16px;
  text-align: center;

  .empty-title {
    color: $grey-900;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .btn .fas {
    margin-right: 4px;
  }
}

.footer-container {
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-end;
  gap: $spacing-12;
}

.bumper-group-container {
  > :deep(.bumper-draggable-group) {
    margin-bottom: $spacing-48;
  }
}

.saving-status{
  display: flex;
  align-items: center;

  i {
    margin-right: $spacing-8;
  }

  &.error{
    color: $danger;
  }

  &.success{
    color: $success;
  }

}

@media screen and (max-width: $max-layout-lg) {
  .content-container {
    padding: $spacing-base;
  }
}

@media screen and (max-width: $max-layout-md) {
  .video-item {
    padding: $spacing-24;
    flex-direction: column;

    :deep(button) {
      margin-top: $spacing-base;
      width: 100%;
    }
  }
  .video-info {
    padding-right: 0;
  }
  .content-title-container {
    flex-direction: column;
    align-items: flex-start !important;

    .badge {
      margin-top: $spacing-8;
      margin-left: 0;
    }
  }
}
</style>
