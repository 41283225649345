import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "button-text-full" }
const _hoisted_2 = { class: "button-text-short" }
const _hoisted_3 = {
  key: 0,
  class: "preset-link"
}

import _ from 'lodash';
import {
  computed,
  onMounted,
  ref,
  useAttrs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import type { Preset } from '@/modules/preset';
import type { StatusState } from '@/modules/shared/types/state.type';
import { loadPresetDetail } from '@/modules/preset';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import ItemInformationCard from '@/modules/shared/components/atoms/itemInformationCard/ItemInformationCard.vue';
import PresetSummaryInfo from '@/modules/preset/components/molecules/presetSummaryInfo/PresetSummaryInfo.vue';
import ChangePresetModal from '@/modules/projectDefaultUploadSetting/components/organisms/changePresetModal/ChangePresetModal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectManageUploadSettingPresetSection',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();

const { canUpdateProject, isManager } = useAttrs();

const togglePresetModal = ref(false);
const presetData = ref<Preset | null>(null);
const loadProjectPresetState = ref<StatusState>(setStateLoadingStatusByType());

// Store
const store = useStore();
const currentProject = computed(() => store.state.project.currentProject);
const saveProjectState = computed(() => store.state.project.saveProjectState);

// Computed
const teamName = computed(() => route.params.teamName);

// Methods
const onTogglePresetModal = () => {
  togglePresetModal.value = !togglePresetModal.value;
};
const loadProjectPreset = async (presetId: string) => {
  try {
    loadProjectPresetState.value = setStateLoadingStatusByType({ type: 'loading' });
    const response = await loadPresetDetail(presetId);
    presetData.value = response?.data;
    loadProjectPresetState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (error) {
    loadProjectPresetState.value = setStateLoadingStatusByType({ type: 'error', error: formatErrorObject(error, 'Preset') });
    // handle
  }
};
const onChangePresetData = async (selectedPreset: Preset) => {
  if (_.isNil(currentProject.value)) {
    return;
  }

  const payload = {
    projectKey: currentProject.value.key,
    data: { ...currentProject.value, presetId: selectedPreset.id },
  };
  await store.dispatch('editProject', payload);
  if (saveProjectState.value?.status === 'success') {
    onTogglePresetModal();
  }
  loadProjectPreset(currentProject.value?.presetId);
};

onMounted(() => {
  if (!_.isNil(currentProject.value)) {
    loadProjectPreset(currentProject.value?.presetId);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (togglePresetModal.value)
      ? (_openBlock(), _createBlock(ChangePresetModal, {
          key: 0,
          presetData: presetData.value,
          saveState: saveProjectState.value,
          onClose: onTogglePresetModal,
          onSubmit: onChangePresetData
        }, null, 8, ["presetData", "saveState"]))
      : _createCommentVNode("", true),
    (loadProjectPresetState.value.status === 'success' && presetData.value)
      ? (_openBlock(), _createBlock(Section, {
          key: 1,
          title: _unref(t)('project.manage.setting.preset.title')
        }, _createSlots({
          action: _withCtx(() => [
            (_unref(canUpdateProject))
              ? (_openBlock(), _createBlock(Button, {
                  key: 0,
                  type: "button",
                  buttonStyle: "secondary",
                  buttonClasses: "change-preset-button",
                  onClick: onTogglePresetModal
                }, {
                  content: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(t)('teamSettings.preset.change')), 1),
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('common:button.change')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            _createVNode(ItemInformationCard, {
              title: presetData.value.name
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(presetData.value.profiles, (profile) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "preset-profile-wrapper",
                    key: profile.id
                  }, [
                    _createVNode(PresetSummaryInfo, { profile: profile }, null, 8, ["profile"])
                  ]))
                }), 128))
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 2
        }, [
          (_unref(isManager))
            ? {
                name: "section-footer-with-border",
                fn: _withCtx(() => [
                  (presetData.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_router_link, {
                          class: "link",
                          to: `/${teamName.value}/manage/presets/${presetData.value.id}`
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)('project.manage.preset.goToSettings')) + " ", 1),
                            _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-chevron-right" }, null, -1))
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["title"]))
      : _createCommentVNode("", true),
    (loadProjectPresetState.value.status === 'loading')
      ? (_openBlock(), _createBlock(PreLoaderSection, {
          key: 2,
          options: { height: '300' }
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})