/* eslint-disable no-shadow */
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { getCustomImageFields } from '@/modules/customImageField/services';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { RootState } from '@/store/type';
import { CustomImageField, CustomImageFieldListState } from '@/modules/customImageField';
import { LoadingState } from '@/modules/shared/types/state.type';
import { ActionContext } from 'vuex';

type CustomImageActionContext = ActionContext<CustomImageFieldListState, RootState>;

const initialState = (): CustomImageFieldListState => ({
  customImageFieldList: null,
  loadCustomImageFieldListState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setCustomImageFieldList(state: CustomImageFieldListState, customImageFieldList: CustomImageField[]) {
    state.customImageFieldList = customImageFieldList;
  },
  setLoadCustomImageFieldListState(state: CustomImageFieldListState, loadingState: LoadingState) {
    state.loadCustomImageFieldListState = setStateLoadingStatusByType(loadingState);
  },
  destroyCustomImageFieldList(state: CustomImageFieldListState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadCustomImageFieldList({ commit }: CustomImageActionContext) {
    try {
      commit('setLoadCustomImageFieldListState', { type: 'loading' });
      const response = await getCustomImageFields();
      commit('setCustomImageFieldList', response.data);
      commit('setLoadCustomImageFieldListState', { type: 'success' });
    } catch (error) {
      commit('setLoadCustomImageFieldListState', { type: 'error', error: formatErrorObject(error, 'Custom Image Field') });
    }
  },
  loadCustomImageFieldListPromise({ commit }: CustomImageActionContext) {
    commit('setLoadCustomImageFieldListState', { type: 'loading' });

    return new Promise((resolve, reject) => {
      getCustomImageFields()
        .then((response) => {
          commit('setCustomImageFieldList', response.data);
          commit('setLoadCustomImageFieldListState', { type: 'success' });
          resolve(response);
        })
        .catch((error) => {
          commit('setLoadCustomImageFieldListState', { type: 'error', error: formatErrorObject(error, 'Custom Image Field') });
          reject(error);
        });
    });
  },
  destroyCustomImageFieldList({ commit }: CustomImageActionContext) {
    commit('destroyCustomImageFieldList');
  },
};

export default {
  state,
  mutations,
  actions,
};
