<template>
  <VideoUploadDefaultBox
    :label="t('video.geoBlock.title')"
    :class="{ 'no-padding-content': isAdvanceOptionShowing }"
    :isEditing="isAdvanceOptionShowing"
    @onClickEdit="onToggleAdvanceOption">
    <GeoblockRuleInformation
      v-if="!isAdvanceOptionShowing"
      :geoblockRule="geoblockRule" />
    <VideoGeoblockRuleOptionField
      v-if="isAdvanceOptionShowing && geoblockRule"
      :geoblockRule="currentGeoblockRule"
      :defaultGeoblockRule="defaultGeoblockRule"
      :geoblockPolicy="geoblockPolicy"
      :loadGeoblockRuleState="loadGeoblockRuleState"
      @update:geoblockPolicy="onUpdatedGeoblockPolicy" />
  </VideoUploadDefaultBox>
</template>

<script setup lang="ts">
import _ from 'lodash';
import {
  computed,
  onMounted,
  ref,
  Ref,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import type { BaseGeoblockPolicy } from '@/modules/geoblock/types/Geoblock.type';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import {
  GeoblockRule,
  GeoblockPolicy,
  BaseGeoblockRule,
  GeoblockRuleInformation,
  loadGeoblockRuleById,
  getGeoblockRuleInformation,
} from '@/modules/geoblock';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { StatusState } from '@/modules/shared/types/state.type';
import VideoGeoblockRuleOptionField from '../videoGeoblockRuleOptionField/VideoGeoblockRuleOptionField.vue';
import VideoUploadDefaultBox from '../../organisms/videoUploadDefaultBox/VideoUploadDefaultBox.vue';

interface UploadVideoGeoblockRuleSettingProps {
  defaultGeoblockRule?: Nullable<GeoblockRule | Omit<GeoblockRule, 'isTeamDefault'>>;
  geoblockPolicy?: Nullable<GeoblockPolicy | BaseGeoblockPolicy>;
}

const props = defineProps<UploadVideoGeoblockRuleSettingProps>();
const {
  defaultGeoblockRule: defaultGeoblockRuleFromProps,
  geoblockPolicy,
} = toRefs(props);

// False positive: defaultGeoblockRule is just an interface property, not a duplicated key.
// eslint-disable-next-line vue/no-dupe-keys
const defaultGeoblockRule: BaseGeoblockRule | GeoblockRule = defaultGeoblockRuleFromProps.value ?? {
  allowCountryCodes: [],
  denyCountryCodes: [],
};

const { t } = useI18n();
const emits = defineEmits<{(e: 'update:geoblockPolicy', value: GeoblockPolicy | BaseGeoblockPolicy): void}>();

// data
const isAdvanceOptionShowing = ref(false);
const currentGeoblockRule: Ref<GeoblockRule | null> = ref(null);
const loadGeoblockRuleState = ref<StatusState>(setStateLoadingStatusByType());

// computed
const geoblockRule = computed(() => {
  const geoblockRuleData = currentGeoblockRule.value || defaultGeoblockRule;
  return getGeoblockRuleInformation({
    geoblockRule: geoblockRuleData,
    geoblockPolicy: geoblockPolicy.value,
  });
});

const geoblockPolicyRuleId = computed(() => _.get(geoblockPolicy.value, 'ruleId', null));

// methods
const loadGeoblockRuleItemById = async (geoblockRuleId: string) => {
  loadGeoblockRuleState.value = setStateLoadingStatusByType({ type: 'loading' });
  try {
    const response = await loadGeoblockRuleById(geoblockRuleId);
    currentGeoblockRule.value = response.data;
    loadGeoblockRuleState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (e) {
    loadGeoblockRuleState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(e, 'GeoblockRuleById'),
    });
  }
};

const onToggleAdvanceOption = () => {
  isAdvanceOptionShowing.value = !isAdvanceOptionShowing.value;
};

const onUpdatedGeoblockPolicy = (policy: GeoblockPolicy | BaseGeoblockPolicy) => {
  emits('update:geoblockPolicy', policy);
  if (policy?.ruleId) {
    loadGeoblockRuleItemById(policy?.ruleId);
  }
};

onMounted(() => {
  if (geoblockPolicyRuleId.value) {
    loadGeoblockRuleItemById(geoblockPolicyRuleId.value);
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
.title {
  margin-bottom: $spacing-base;
  font-size: $font-level-4;
  font-weight: $font-weight-base;
  color: $grey-800;
  text-transform: capitalize;
}
.no-padding-content {
  :deep(.content){
    padding: 0;
  }
}

// modify padding for this section
:deep(.option-wrapper) {
  padding: $spacing-24 $spacing-32 !important;
}
</style>
