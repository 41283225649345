import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "file-upload" }
const _hoisted_2 = {
  key: 0,
  class: "button-text"
}
const _hoisted_3 = { key: 1 }

import _ from 'lodash';
import {
  computed, ref, toRefs, watch,
} from 'vue';
import { GeoblockPolicy } from '@/modules/geoblock/types/Geoblock.type';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { VideoUploadOptions } from '@/modules/shared/types/video.type';
import { useStore } from '../../../store/hooks';

interface VideoUploaderConfirmButtonProps {
  options: VideoUploadOptions;
  disabled?: boolean;
  callbackFunction?: () => any;
  geoblockPolicy: GeoblockPolicy;
  isShowSummaryModalAfterUploading?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoUploaderConfirmButton',
  props: {
    options: {},
    disabled: { type: Boolean },
    callbackFunction: {},
    geoblockPolicy: {},
    isShowSummaryModalAfterUploading: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;

const {
  options, disabled, callbackFunction, geoblockPolicy, isShowSummaryModalAfterUploading,
} = toRefs(props);

const store = useStore();

const isButtonClicked = ref<boolean>(false);

const isEmptyVideoFilesValid = computed(() => store.getters.isEmptyVideoFilesValid);
const uploadVideoState = computed(() => store.state.videoUpload.uploadVideoState);
const videoFilesValid = computed(() => store.getters.videoFilesValid);

function isVideoUploadValid(): boolean {
  if (isButtonClicked.value || disabled.value || _.isEmpty(geoblockPolicy.value)) {
    return false;
  }

  const geoBlockType = _.get(geoblockPolicy.value, 'type', null);
  const geoBlockId = _.get(geoblockPolicy.value, 'ruleId', null);

  if (geoBlockType !== 'customRule' && !geoBlockId) {
    return false;
  }

  return !isEmptyVideoFilesValid.value && !_.isNil(options.value.projectKey);
}

function onUploadFiles(): void {
  isButtonClicked.value = true;
  store.dispatch('createVideos', {
    isShowSummaryModalAfterUploading: isShowSummaryModalAfterUploading.value,
    ...options.value,
  });
}

watch(uploadVideoState, (uploadState) => {
  if (uploadState.status === 'success' && typeof callbackFunction.value === 'function') {
    callbackFunction.value();
  }

  if (uploadState.status === 'error') {
    isButtonClicked.value = false;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Button, {
      type: "submit",
      isFullWidth: "",
      buttonStyle: "primary",
      disabled: !isVideoUploadValid(),
      size: "large",
      onClick: onUploadFiles
    }, {
      content: _withCtx(() => [
        (!isButtonClicked.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('glossary:upload')) + " " + _toDisplayString(videoFilesValid.value.length) + " " + _toDisplayString(_ctx.$t(`glossary:file${videoFilesValid.value.length > 1 ? '_plural' : ''}`)), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa-duotone fa-spinner-third fa-spin" }, null, -1)),
              _createTextVNode(" Preparing " + _toDisplayString(videoFilesValid.value.length) + " file" + _toDisplayString(videoFilesValid.value.length > 1 ? 's' : '') + "... ", 1)
            ]))
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}
}

})