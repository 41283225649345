import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, renderList as _renderList, normalizeClass as _normalizeClass, createSlots as _createSlots, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = { class: "page" }
const _hoisted_3 = {
  key: 0,
  class: "page-navigation"
}
const _hoisted_4 = {
  key: 2,
  class: "page-header"
}
const _hoisted_5 = {
  key: 3,
  class: "page-header"
}
const _hoisted_6 = { class: "page-title-container" }
const _hoisted_7 = { class: "page-title" }
const _hoisted_8 = { class: "page-action" }
const _hoisted_9 = {
  key: 0,
  class: "page-project-name"
}
const _hoisted_10 = {
  key: 1,
  class: "page-description"
}
const _hoisted_11 = {
  key: 2,
  class: "page-title-badge"
}
const _hoisted_12 = { class: "main-content" }
const _hoisted_13 = {
  key: 0,
  class: "right-top",
  id: "right-top"
}
const _hoisted_14 = { class: "page-footer" }
const _hoisted_15 = { class: "app-revision-container" }
const _hoisted_16 = ["innerHTML"]

import _ from 'lodash';
import {
  ref, computed, watch, onMounted, toRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { useBreakpoints } from '@/composables/useBreakpoint';
import { getCurrentYear } from '@/modules/shared/utils/dateFormatter';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import PreloaderBar from '@/modules/shared/components/atoms/preloaderBar/PreloaderBar.vue';
import type { PageBadge } from '@/modules/shared/types/page.type';

interface PageProps {
  title?: string;
  subtitle?: string;
  projectName?: string;
  projectKey?: string;
  badgeList?: PageBadge[];
  isShowPageHeader?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Page',
  props: {
    title: {},
    subtitle: {},
    projectName: {},
    projectKey: {},
    badgeList: {},
    isShowPageHeader: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;
const {
  title,
  subtitle,
  projectName,
  projectKey,
  badgeList,
  isShowPageHeader,
} = toRefs(props);

const router = useRouter();
const route = useRoute();
const store = useStore();
const { width } = useBreakpoints();

const isScrolling = ref<boolean>(false);
const topContentHeight = ref<number>(0);
const isLGorMDscreen = ref<boolean>(false);

const isPageLoading = computed(() => store.state.global?.isPageLoading);
const appRevision = computed(() => {
  const REVISION = process.env.VUE_APP_REVISION.replace(/"/g, '');
  const BRANCH = process.env.VUE_APP_BRANCH.replace(/"/g, '');
  const COMMITHASH = process.env.VUE_APP_COMMITHASH.replace(/"/g, '');

  if (process.env.NODE_ENV === 'production') {
    return `Version ${REVISION}#${COMMITHASH}`;
  }

  return `Working on Branch ${BRANCH}#${COMMITHASH}`;
});

const onBadgeClick = ({ path, onClick }: PageBadge) => {
  if (typeof onClick === 'function') {
    onClick();
  } else if (path) {
    router.push(path);
  }
};

const getTopContentHeight = () => {
  const element = document.getElementById('right-top');
  // Use "any" here because "instanceof" needs an "any" variable to work.
  const elementChild = _.get<HTMLElement, any>(element, 'children[0]');

  if (elementChild instanceof HTMLElement) {
    topContentHeight.value = elementChild.offsetHeight;
  }
};

onMounted(() => {
  getTopContentHeight();
  window.addEventListener('resize', () => getTopContentHeight());
});

watch(
  () => width.value,
  () => {
    if (width.value >= 576 && width.value <= 992 && !isLGorMDscreen.value) {
      isLGorMDscreen.value = true;
    } else if ((width.value > 992 || width.value < 576) && isLGorMDscreen.value) {
      isLGorMDscreen.value = false;
    }
  },
  { immediate: true },
);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["page-content", { 'page-scrolling': isScrolling.value }])
      }, [
        (_ctx.$slots['page-navigation'])
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "page-navigation")
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots['page-header'])
          ? _renderSlot(_ctx.$slots, "page-header", { key: 1 })
          : _createCommentVNode("", true),
        (isPageLoading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(PreloaderBar, {
                height: "30px",
                width: "150px"
              }),
              _createVNode(PreloaderBar, {
                height: "20px",
                width: "200px"
              }),
              _createVNode(PreloaderBar, {
                height: "20px",
                width: "250px"
              })
            ]))
          : _createCommentVNode("", true),
        (_unref(isShowPageHeader) && !isPageLoading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _renderSlot(_ctx.$slots, "page-title", {}, () => [
                    _createTextVNode(_toDisplayString(_unref(title) ?? ''), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _renderSlot(_ctx.$slots, "page-action")
                ])
              ]),
              (_unref(projectName))
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_unref(projectKey))
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: `/${_unref(route).params?.teamName}/projects/${_unref(projectKey)}`
                        }, {
                          default: _withCtx(() => [
                            _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-folder-open" }, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_unref(projectName)), 1)
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fas fa-folder-open" }, null, -1)),
                          _createTextVNode(" " + _toDisplayString(_unref(projectName)), 1)
                        ], 64))
                  ]))
                : _createCommentVNode("", true),
              (_unref(subtitle))
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(subtitle)), 1))
                : _createCommentVNode("", true),
              (_unref(badgeList))
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(badgeList), (badge, index) => {
                      return (_openBlock(), _createBlock(Badge, {
                        key: index,
                        title: badge.title,
                        badgeStyle: badge.badgeStyle,
                        theme: badge.theme,
                        size: "small",
                        class: _normalizeClass({ clickable: badge.path || typeof badge.onClick === 'function' }),
                        onClick: () => onBadgeClick(badge)
                      }, _createSlots({ _: 2 }, [
                        (badge.iconClassName)
                          ? {
                              name: "icon-prefix",
                              fn: _withCtx(() => [
                                _createElementVNode("i", {
                                  class: _normalizeClass(badge.iconClassName)
                                }, null, 2)
                              ]),
                              key: "0"
                            }
                          : undefined
                      ]), 1032, ["title", "badgeStyle", "theme", "class", "onClick"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["page-body", { 'grid-layout': _ctx.$slots['right-top'] }])
        }, [
          _createElementVNode("div", _hoisted_12, [
            _renderSlot(_ctx.$slots, "default")
          ]),
          (_ctx.$slots['right-top'])
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _renderSlot(_ctx.$slots, "right-top")
              ]))
            : _createCommentVNode("", true),
          (_ctx.$slots['right-bottom'])
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "right-bottom",
                id: "right-bottom",
                style: _normalizeStyle({ height: isLGorMDscreen.value ? `${topContentHeight.value}px` : 'inherit' })
              }, [
                _renderSlot(_ctx.$slots, "right-bottom")
              ], 4))
            : _createCommentVNode("", true)
        ], 2)
      ], 2),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("p", null, [
            _createTextVNode(" © " + _toDisplayString(_unref(getCurrentYear)()) + " ByteArk Stream | All Rights Reserved | ", 1),
            _createElementVNode("span", { innerHTML: appRevision.value }, null, 8, _hoisted_16)
          ])
        ])
      ])
    ])
  ]))
}
}

})