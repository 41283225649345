import qs from 'qs';
import { axiosKeyServerAPI } from '@/plugins/axios';
import type { Filter, ListApiResponse } from '@/modules/shared/types/axios.type';
import { KeyServer } from '../types/KeyServer.type';

export function loadKeyServersAPI(filter?: Filter) {
  const query = qs.stringify({
    ...(filter || {}),
    encryptionType: 'clearkey',
  }, { addQueryPrefix: true, skipNulls: true });
  return axiosKeyServerAPI.get<ListApiResponse<KeyServer[]>>(`/key-server${query}`);
}
