import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user-info-container"
}
const _hoisted_2 = {
  key: 0,
  class: "title"
}
const _hoisted_3 = { class: "profile-item" }
const _hoisted_4 = { class: "profile-text" }
const _hoisted_5 = {
  key: 0,
  class: "username",
  "data-test": "user-info-item-name"
}
const _hoisted_6 = {
  key: 1,
  class: "date",
  "data-test": "user-info-item-date"
}
const _hoisted_7 = {
  key: 2,
  class: "date",
  "data-test": "user-info-item-date"
}

import { toRefs } from 'vue';
import Logo from '@/modules/shared/components/atoms/logo/Logo.vue';
import { formatVideoDateTime } from '@/modules/shared/utils/dateFormatter';
import type { Nullable } from '@/modules/shared/types/index.type';
import type { User } from '@/modules/user';

interface UserInfoItemProps {
  data: Nullable<User>;
  title?: string;
  dateTime?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserInfoItem',
  props: {
    data: {},
    title: {},
    dateTime: {}
  },
  setup(__props: any) {

const props = __props;
const { data, title, dateTime } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_unref(data))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(title))
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_unref(title)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(Logo, {
            class: "logo",
            user: _unref(data)
          }, null, 8, ["user"]),
          _createElementVNode("div", _hoisted_4, [
            (_unref(data).name)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_unref(data).name), 1))
              : _createCommentVNode("", true),
            (_unref(dateTime))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('common:form.on')) + " " + _toDisplayString(_unref(formatVideoDateTime)(_unref(dateTime))), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('common:form.on')) + " " + _toDisplayString(_unref(formatVideoDateTime)(_unref(data).updatedAt)), 1))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})