<!-- eslint-disable max-len -->
<template>
  <Modal
    :title="t('project.manage.setting.subtitleProfile.choose')"
    :isDisabledPrimaryButton="!captionProfileId"
    :isLoading="loadCaptionProfileListState.status === 'loading'"
    :isSaving="saveProjectState.status === 'saving'"
    :onClickPrimaryButton="onClickSave"
    :onClickSecondaryButton="() => emits('modalClose')"
    @modalClose="() => emits('modalClose')">
    <template v-slot:body>
      <div v-if="loadCaptionProfileListState.status === 'success'">
        <div class="subtitle-profile-lst" v-if="captionProfileList?.length">
          <RadioButtonChoice
            v-for="(profile, index) in captionProfileList"
            :modelValue="captionProfileId === profile.id"
            :data="profile.id"
            :key="index"
            @update:data="onSelectCaptionProfile">
            <div class="profile-name">{{ profile.name }}</div>
            <div class="information-row">
              <div class="information-group">
                <div v-if="profile.transcribe?.services?.length > 1" class="label">
                  Transcribe Services ({{profile.transcribe.services.length}})
                </div>
                <div v-else class="label">
                  Transcribe Service ({{profile.transcribe.services.length}})
                </div>
                <div class="service-list">
                  <div class="value" v-for="(service, index) in profile.transcribe?.services" :key="index">
                    {{serviceNameDict.transcribe[service]}}
                  </div>
                </div>
              </div>
              <div class="information-group">
                <div class="label">Status</div>
                <div class="value">
                  {{t(captionPublishConditionTranslationKey[profile.publishStatusSetting])}}
                </div>
              </div>
            </div>
            <div class="information-row">
              <div class="information-group">
                <div class="label">Auto-translate {{profile.translate?.languages?.length ? `(${profile.translate?.languages?.length})` : ''}}</div>
                <div class="language-list" v-if="profile.translate?.languages?.length">
                  <div class="badge-list" v-for="(language, index) in profile.translate?.languages" :key="index">
                    <Badge :title="language.name"/>
                  </div>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </RadioButtonChoice>
        </div>
        <div class="empty-state" v-else>
          <svg width="40" height="33.33" viewBox="0 0 24 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.234 0.13333H1.76597C1.63588 0.132097 1.50683 0.156656 1.38629 0.205587C1.26575 0.254519 1.15609 0.326853 1.06367 0.418411C0.971241 0.509969 0.897875 0.618935 0.847809 0.739013C0.797742 0.85909 0.771967 0.987899 0.771973 1.118V15.0153C0.771967 15.1454 0.797742 15.2742 0.847809 15.3943C0.897875 15.5144 0.971241 15.6234 1.06367 15.7149C1.15609 15.8065 1.26575 15.8788 1.38629 15.9277C1.50683 15.9767 1.63588 16.0012 1.76597 16H15.696L17.8146 19.3553C17.8657 19.4323 17.9351 19.4955 18.0165 19.5392C18.0979 19.5829 18.1889 19.6058 18.2813 19.6058C18.3737 19.6058 18.4647 19.5829 18.5461 19.5392C18.6275 19.4955 18.6969 19.4323 18.748 19.3553L20.8666 16H22.234C22.3641 16.0012 22.4931 15.9767 22.6137 15.9277C22.7342 15.8788 22.8439 15.8065 22.9363 15.7149C23.0287 15.6234 23.1021 15.5144 23.1521 15.3943C23.2022 15.2742 23.228 15.1454 23.228 15.0153V1.118C23.228 0.987899 23.2022 0.85909 23.1521 0.739013C23.1021 0.618935 23.0287 0.509969 22.9363 0.418411C22.8439 0.326853 22.7342 0.254519 22.6137 0.205587C22.4931 0.156656 22.3641 0.132097 22.234 0.13333ZM6.86664 9H15.2666V10.4H6.86664V9ZM3.13331 9H5.46664V10.4H3.13331V9ZM12.4666 13.2H3.13331V11.8H12.4666V13.2ZM17.1333 13.2H13.8666V11.8H17.1333V13.2ZM20.8666 10.4H16.6666V9H20.8666V10.4Z" fill="#343A40"/>
          </svg>
          <h4 class="empty-title">{{t('video.subtitleProfile.empty.title')}}</h4>
          <div>{{t('project.manage.setting.subtitleProfile.empty.description')}}</div>
          <Button buttonStyle="text-primary">
            <template v-slot:icon-prefix>
              <i class="fa fa-plus" />
            </template>
            {{t('video.subtitleProfile.create.title')}}
          </Button>
        </div>
      </div>
    </template>
    <template v-slot:footer v-if="saveProjectState.status === 'error'">
      <div class="error-text danger">
        <i class="fa fa-times" />
        {{saveProjectState.error?.message}}
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { onMounted, computed, ref } from 'vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import RadioButtonChoice from '@/modules/shared/components/atoms/radioButtonChoice/RadioButtonChoice.vue';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import { captionPublishConditionTranslationKey, serviceNameDict } from '@/modules/captionProfile';
import { getCaptionProfileList } from '../../../services';

const { t } = useI18n();
const emits = defineEmits(['modalClose']);

// Store
const store = useStore();
const currentProject = computed(() => store.state.project.currentProject);
const saveProjectState = computed(() => store.state.project.saveProjectState);

const loadCaptionProfileListState = ref(setStateLoadingStatusByType());
const captionProfileList = ref([]);
const captionProfileId = ref(currentProject.value?.autoGenerateCaptionSettingId);

const loadCaptionProfileList = async () => {
  try {
    loadCaptionProfileListState.value = setStateLoadingStatusByType({ type: 'loading' });
    const response = await getCaptionProfileList();
    captionProfileList.value = response.data.data;
    loadCaptionProfileListState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (error) {
    loadCaptionProfileListState.value = setStateLoadingStatusByType({ type: 'error', error: formatErrorObject(error, 'Subtitle Profile') });
  }
};

const onSelectCaptionProfile = (newcaptionProfileId) => {
  captionProfileId.value = newcaptionProfileId;
};

const onClickSave = async () => {
  const payload = {
    projectKey: currentProject.value.key,
    data: { ...currentProject.value, autoGenerateCaptionSettingId: captionProfileId.value },
  };
  await store.dispatch('editProject', payload);
  if (saveProjectState.value?.status === 'success') {
    emits('modalClose');
  }
};

onMounted(() => {
  loadCaptionProfileList();
});
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

:deep(.radio-choice) {
  margin-bottom: $spacing-base;
}

.profile-name {
  color: $grey-700;
  font-weight: $font-weight-bold;
  margin-bottom: $spacing-base;
}

.information-row {
  display: flex;
  &:not(:last-child) {
    margin-bottom: $spacing-24;
  }
}

.information-group {
  flex: 1;
}

.label {
  margin-bottom: $spacing-4;
}

.value {
  font-size: $font-level-7;
  color: $grey-800;

  &:not(:first-child) {
    margin-top: $spacing-4;
  }
}

.language-list {
  display: flex;
  flex-wrap: wrap;

  :not(:last-child) {
    margin-right: $spacing-8;
  }
  > * {
    margin-bottom: $spacing-4;
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-32 0;

  :deep(button) {
    margin-top: $spacing-24;
  }
}

.empty-title {
  color: $grey-600;
  margin-top: $spacing-24;
  margin-bottom: $spacing-8;
}

path {
  fill: $grey-600;
}
</style>
