<template>
  <Modal
    :title="t('video.uploader.chooseProject')"
    :primaryButtonText="t('project.newProject.title')"
    primaryButtonStyle="text-primary"
    :onClickPrimaryButton="onClickPrimaryButton"
    :isShowSecondaryButton="false"
    @modalClose="onCloseModal">
    <template v-slot:body>
      <div class="loading-state" v-if="loadProjectsState.status === 'loading'">
        <i class="fas fa-circle-notch fa-spin" />
      </div>
      <div v-if="loadProjectsState.status === 'success'">
        <div class="search-container">
          <Input
            class="form-control form-search"
            :placeholder="t('common:form.searchBy', {item: t('glossary:project')})"
            :onBlur="onSearch"
            :modelValue="searchValue"
            isSearchInput />
        </div>
        <div class="list-container" v-if="!isEmptyProjects">
          <div class="filter-result" v-if="searchValue">
            {{t('project.searchResult')}}
          </div>
          <router-link
            :to="`/${teamName}/projects/${project.key}/upload`"
            v-for="project in filterProjects"
            :key="project.id"
            class="card-container">
            <Card>
              <template v-slot:card-header>
                <h1 class="title">{{project.name}}</h1>
              </template>
              <div class="card-body">
                <p class="description">{{project.description}}</p>
              </div>
              <template v-slot:card-footer>
                <div class="detail">
                  <div class="detail-item text-capitalize">
                    {{total > 0 ? t('video.count', { count: total }) : t('video.noVideo')}}
                  </div>
                </div>
                <div class="last-update" v-if="project.updatedAt">
                  {{ t('common:time.latestUpdatedAt')}}
                  {{formatDistance( new Date(project.updatedAt), new Date())}}
                  ago
                </div>
              </template>
            </Card>
          </router-link>
        </div>
        <MessageBox
          v-if="isEmptyProjects && isEmptySearch"
          iconClassName="fas fa-box-open"
          :title="t('project.notFound.title')"
        >
          <template v-slot:description>
            <p>
              {{t('project.notFound.descLine1')}}<br/>
              {{t('project.notFound.descLine2')}}
            </p>
          </template>
        </MessageBox>
        <ErrorMessage
          v-if="isError"
          :title="loadProjectsState.error?.message"
          :statusCode="loadProjectsState.error?.statusCode" />
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import type { Filter } from '@/modules/shared/types/axios.type';
import type { Project } from '@/modules/shared/types/project.type';
import type { StatusState } from '@/modules/shared/types/state.type';
import { getQueryAsString } from '@/modules/shared/utils/query';
import _ from 'lodash';
import {
  formatDistance,
} from '@/modules/shared/utils/dateFormatter';
import { loadProjectsAPI } from '@/modules/shared/services/project';
import Card from '@/modules/shared/components/atoms/card/Card.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';

interface ModalProps {
  onCloseModal: () => void;
}

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const props = defineProps<ModalProps>();
const { onCloseModal } = toRefs(props);

const apiFilter: Filter = { size: '1000' };

const loadProjectsState = ref<StatusState>(setStateLoadingStatusByType());
const searchValue = ref<string>('');
const projects = ref<Project[]>([]);
const total = ref<number>(1);

const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
const filterProjects = computed(() => _.filter(projects.value, (project) => project.name.includes(searchValue.value)));
const isError = computed(() => loadProjectsState.value.status === 'error');
const isEmptyProjects = computed(() => !filterProjects.value || filterProjects.value.length === 0);
const isEmptySearch = computed(() => isEmptyProjects.value && searchValue.value !== '');

async function load(filter: Filter) {
  try {
    loadProjectsState.value = setStateLoadingStatusByType({ type: 'loading' });
    const response = await loadProjectsAPI(filter);
    projects.value = response.data?.data;
    total.value = response.data?.total;
    loadProjectsState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (error) {
    loadProjectsState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Project'),
    });
  }
}

function onClickPrimaryButton() {
  router.push(`/${teamName.value}/projects/create`);
}

function onSearch(event: FocusEvent) {
  if (event.target && event.target instanceof HTMLInputElement) {
    searchValue.value = event.target.value;
  }
}

watch(searchValue, () => load({ ...apiFilter, q: searchValue }));

onMounted(() => load(apiFilter));
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/themes/helpers';

.loading-state {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;

  i {
    font-size: 64px;
  }
}

.modal-body {
  padding-bottom: 0;
  min-height: 411px;
}

.search-container {
  margin-bottom: $spacing-base;

  .form-search {
    color: $grey-700;
  }
}

.modal-footer {
  justify-content: flex-start;

  :deep(.btn-create-project) {
    padding: $spacing-base * 0.25 0;
  }
}

.list-container {
  @extend .scrollbar;
  display: flex;
  flex-direction: column;

  height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: $spacing-base;

  .card-container {
    border: 1px solid $grey-200;
    border-radius: 4px;
    margin-bottom: $spacing-base * 0.5;

    .card {

      .card-header {
        padding: $spacing-base * 0.5 $spacing-base * 0.75 0;
      }

      .card-body {
        padding: $spacing-base * 0.5 $spacing-base * 0.75;
      }

      .card-footer {
        padding: $spacing-base * 0.5 $spacing-base * 0.75;
      }

      .description {
        margin-bottom: 0;
        height: 36px;
      }
    }

    &:hover {
      background-color: $grey-100;
      border-color: $ci-primary;
      text-decoration: none;
    }
  }
}
</style>
