import qs from 'qs';
import { axiosAPI, axiosFlareAPI } from '@/plugins/axios';
import { qsOption } from '@/services/constants';
import { Filter, ListApiResponse } from '@/modules/shared/types/axios.type';
import {
  TestWebhookPayload,
  Webhook,
  WebhookDeliveryData,
  WebhookDeliveryList,
} from '../types/Webhook.type';

export function loadWebhooks(filter: Filter) {
  const query = qs.stringify(filter, qsOption);
  return axiosFlareAPI.get<ListApiResponse<Webhook[]>>(`/webhooks/${query}`);
}

export function loadWebhookById(id: string) {
  return axiosFlareAPI.get<Webhook>(`/webhooks/${id}`);
}

export function getWebhookDeliveryById(id: string) {
  return axiosFlareAPI.get<WebhookDeliveryData>(`/webhook-deliveries/${id}`);
}

export function getWebhookDeliveries(filter: Filter) {
  const query = qs.stringify(filter, qsOption);
  return axiosFlareAPI.get<WebhookDeliveryList>(`/webhook-deliveries${query || ''}`);
}

export function addWebhook(webhook: Webhook) {
  return axiosFlareAPI.post('/webhooks/', webhook);
}

export function updateWebhookById(id: string, webhook: Webhook) {
  return axiosFlareAPI.put(`/webhooks/${id}`, webhook);
}

export function updateWebhookStatusById(id: string, status: boolean) {
  return axiosFlareAPI.put(`/webhooks/${id}/enabled`, { enabled: status });
}

export function updateWebhookName(id: string, name: string) {
  return axiosFlareAPI.put(`/webhooks/${id}/name`, { name });
}

export function testWebhookConfiguration(projectKey: string, payload: Partial<TestWebhookPayload>) {
  return axiosAPI.post(`/projects/${projectKey}/webhook-configurations`, payload);
}

export function testWebhook(videoId: string, payload: Partial<TestWebhookPayload>) {
  return axiosAPI.post(`/videos/${videoId}/webhook-deliveries`, {
    webhookId: payload.webhookId,
    eventName: payload.eventName,
  });
}

export function deleteWebhook(id: string) {
  return axiosFlareAPI.delete(`/webhooks/${id}`);
}
