import type { ActionContext } from 'vuex';
import { loadProjectsAPI } from '@/modules/shared';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateLoadingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import type { Filter } from '@/modules/shared/types/axios.type';
import type { RootState } from '@/store/type';
import type { LoadingState } from '@/modules/shared/types/state.type';
import type { Project } from '@/modules/shared/types/project.type';
import type { DeletedProject, ProjectListState } from '../types/projectList';
import { loadDeletedProjects } from '../services';

type ProjectListActionContext = ActionContext<ProjectListState, RootState>;

const initialState = (): ProjectListState => ({
  projectList: null,
  projectListLastPage: null,
  projectListTotal: null,
  loadProjectListState: setStateLoadingStatusByType(),
  deletedProjectList: null,
  deletedProjectListLastPage: 1,
  loadDeletedProjectListState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setProjectList(state: ProjectListState, projectList: Project[]) {
    state.projectList = projectList;
  },
  setProjectListLastPage(state: ProjectListState, lastPage: number) {
    state.projectListLastPage = lastPage;
  },
  setProjectListTotal(state: ProjectListState, total: number) {
    state.projectListTotal = total;
  },
  setLoadProjectListState(state: ProjectListState, loadingState: LoadingState) {
    state.loadProjectListState = setStateLoadingStatusByType(loadingState);
  },
  setDeletedProjectList(state: ProjectListState, value: DeletedProject[]) {
    state.deletedProjectList = value;
  },
  setLoadDeletedProjectListState(state: ProjectListState, loadingState: LoadingState) {
    state.loadDeletedProjectListState = setStateLoadingStatusByType(loadingState);
  },
  setDeletedProjectListLastPage(state: ProjectListState, value: number) {
    state.deletedProjectListLastPage = value;
  },
  destroyProjectList(state: ProjectListState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadProjectList({ commit }: ProjectListActionContext, filter: Filter) {
    try {
      commit('setLoadProjectListState', { type: 'loading' });
      const response = await loadProjectsAPI({ ...filter });
      commit('setProjectList', response.data.data);
      commit('setProjectListLastPage', response.data.lastPage);
      commit('setProjectListTotal', response.data.total);
      commit('setLoadProjectListState', { type: 'success' });
    } catch (error) {
      commit('setLoadProjectListState', { type: 'error', error: formatErrorObject(error, 'Projects') });
    }
  },
  async loadDeletedProjectList({ commit }: ProjectListActionContext, filter: Filter) {
    try {
      commit('setLoadDeletedProjectListState', { type: 'loading' });
      const response = await loadDeletedProjects({ ...filter });
      commit('setDeletedProjectList', response.data.data);
      commit('setDeletedProjectListLastPage', response.data.lastPage);
      commit('setLoadDeletedProjectListState', { type: 'success' });
    } catch (error) {
      commit('setLoadDeletedProjectListState', {
        type: 'error',
        error: formatErrorObject(error, 'Deleted Project List'),
      });
    }
  },
  destroyProjectList({ commit }: ProjectListActionContext) {
    commit('destroyProjectList');
  },
};

export default {
  state,
  mutations,
  actions,
};
