/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { Filter } from '@/modules/shared/types/axios.type';
import type { ActionContext } from 'vuex';
import type { RootState } from '@/store/type';
import type { Video } from '@/modules/shared/types/video.type';
import type { LoadingState } from '@/modules/shared/types/state.type';
import type { ExportFileListState } from '../types';
import { loadProjectExportFiles } from '../services';

type ProjectExportFileListActionContext = ActionContext<ExportFileListState, RootState>;

const initialState = (): ExportFileListState => ({
  projectExportFileList: [],
  projectExportFileListTotal: 0,
  projectExportFileListLastPage: 0,
  loadProjectExportFileListState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  destroyProjectExportFileList(state: ExportFileListState) {
    Object.assign(state, initialState());
  },
  setProjectExportFileList(state: ExportFileListState, videos: Video[]) {
    state.projectExportFileList = videos;
  },
  setProjectExportFileListLastPage(state: ExportFileListState, lastPage: number) {
    state.projectExportFileListLastPage = lastPage;
  },
  setProjectExportFileListTotal(state: ExportFileListState, total: number) {
    state.projectExportFileListTotal = total;
  },
  setLoadProjectExportFileListState(state: ExportFileListState, loadingState: LoadingState) {
    state.loadProjectExportFileListState = setStateLoadingStatusByType(loadingState);
  },
};

const actions = {
  async loadProjectExportFileList({ state, commit }: ProjectExportFileListActionContext, params: { projectKey: string, filter: Filter }) {
    if (!state.projectExportFileList?.length) {
      commit('setLoadProjectExportFileListState', { type: 'loading' });
    }
    try {
      const response = await loadProjectExportFiles(params.projectKey, params?.filter);
      commit('setProjectExportFileList', response.data?.docs);
      commit('setProjectExportFileListLastPage', response.data?.totalPages);
      commit('setProjectExportFileListTotal', response.data?.totalDocs);
      commit('setLoadProjectExportFileListState', { type: 'success' });
    } catch (error) {
      commit('setLoadProjectExportFileListState', { type: 'error', error: formatErrorObject(error, 'Export File List') });
    }
  },
  destroyProjectExportFileList({ commit }: ProjectExportFileListActionContext) {
    commit('destroyProjectExportFileList');
  },
  setPageScrollPosition({ commit }: ProjectExportFileListActionContext, position: number) {
    commit('setPageScrollPosition', position);
  },
};

export default {
  state,
  actions,
  mutations,
};
