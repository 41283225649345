import type { ActionContext } from 'vuex';
import { loadPresets } from '@/modules/preset/services';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateLoadingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import type { RootState } from '@/store/type';
import type { Preset, PresetListState } from '@/modules/preset/types';
import type { LoadingState } from '@/modules/shared/types/state.type';

type PresetListActionContext = ActionContext<PresetListState, RootState>

const initialState = (): PresetListState => ({
  presetList: null,
  loadPresetListState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setPresetList(state: PresetListState, presetList: Preset[]) {
    state.presetList = presetList;
  },
  setLoadPresetListState(state: PresetListState, loadingState: LoadingState) {
    state.loadPresetListState = setStateLoadingStatusByType(loadingState);
  },
  destroyPresetList(state: PresetListState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadPresetList({ commit }: PresetListActionContext, filter: {q: string}) {
    try {
      commit('setLoadPresetListState', { type: 'loading' });
      const response = await loadPresets({ ...filter, limit: '100' });
      commit('setPresetList', response.data.data);
      commit('setLoadPresetListState', { type: 'success' });
    } catch (error) {
      commit('setLoadPresetListState', { type: 'error', error: formatErrorObject(error, 'Presets') });
    }
  },
  destroyPresetList({ commit }: PresetListActionContext) {
    commit('destroyPresetList');
  },
};

export default {
  state,
  mutations,
  actions,
};
