import { FileWithValidator, Validator } from '@/modules/shared/types/file.type';
import _, { isNumber } from 'lodash';
import i18n from '@/plugins/lang/i18n';

const ENABLE_VALIDATE_VIDEO_FILES = true;

// const readFileItemType = (file) => FileType.fromBlob(file);

// const readFilesType = async (files) => {
//   const requests = _.map(files, (file) => readFileItemType(file));
//   return axios.all(requests);
// };

const isFileMimeTypeValid = (fileType: string): boolean => {
  if (!ENABLE_VALIDATE_VIDEO_FILES) {
    return true;
  }

  // For .mkv file (Apple M1)
  if (!fileType) {
    return true;
  }

  if (fileType.includes('video/') || fileType.includes('audio/') || fileType === 'application/mxf') {
    return true;
  }

  return false;
};

const isFileSizeValid = (size: number): boolean => isNumber(size) && size > 0;

const generateErrorValidateMessage = (validator: Validator): Array<string> => {
  const error = [];

  if (validator.size && !validator.size.isValid) {
    error.push(i18n.global.t('validation:file.byte'));
  }

  if (validator.type && !validator.type.isValid) {
    error.push(i18n.global.t('validation:file.videoOnly'));
  }

  return error;
};

const fileValidator = (files: FileList): Array<FileWithValidator> => _.map(files, (file) => {
  const validator: Validator = {
    size: {
      value: file.size,
      isValid: isFileSizeValid(file.size),
    },
    type: {
      value: file.type,
      isValid: isFileMimeTypeValid(file.type),
    },
  };

  return {
    file,
    validator,
    error: generateErrorValidateMessage(validator),
  };
});

const filterFiles = (files: Array<FileWithValidator>, isValid = true): Array<FileWithValidator> => {
  if (isValid) {
    return _.filter(files, (file) => !(file.error && file.error.length > 0));
  }

  return _.filter(files, (file) => file.error && file.error.length > 0);
};

export { fileValidator, filterFiles };
