import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "video-status-container"
}
const _hoisted_2 = {
  key: 0,
  class: "video-status video-pending"
}
const _hoisted_3 = {
  key: 1,
  class: "video-status video-pending"
}
const _hoisted_4 = {
  key: 2,
  class: "video-status video-retry"
}
const _hoisted_5 = {
  key: 3,
  class: "video-status video-failed"
}
const _hoisted_6 = { class: "text-danger" }
const _hoisted_7 = {
  key: 4,
  class: "video-status video-uploading"
}
const _hoisted_8 = { class: "progress-upload-percent" }
const _hoisted_9 = {
  key: 5,
  class: "video-status video-processing-info"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "warning-status"
}
const _hoisted_13 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 6,
  class: "video-status video-processing-info"
}
const _hoisted_17 = {
  key: 7,
  class: "video-status video-processing-info"
}
const _hoisted_18 = { class: "progress-circle-container" }
const _hoisted_19 = { class: "process-label" }

import { computed, toRefs } from 'vue';
import type { Video } from '@/modules/shared/types/video.type';
import type { Nullable } from '@/modules/shared/types/index.type';
import { isVideoUploadByKeyObject } from '@/modules/shared/utils/typeGuard';
import type { VideoUploadByKeyObject } from '@/modules/videoUpload';
import {
  getVideoTotalExpectedOutputs,
  getVideoTotalCompletedOutputs,
  getVideoProbeErrorType,
  isVideoSourceIngression,
  isVideoProbeError,
  isVideoProbeErrorFile,
  isVideoProcessing,
  isVideoPartiallyCompleted,
  isVideoUploadQueued,
  isVideoUploading,
  isVideoUploadRetry,
  isVideoUploadFailed,
  isVideoUploadingByOther,
  isVideoProcessedOverTime,
} from '@/modules/videoDetail/utils/videoManagement';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import { useStore } from '../../../store/hooks';

interface VideoItemStatusProps {
  video: Nullable<Video | VideoUploadByKeyObject>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoItemStatusV2',
  props: {
    video: {}
  },
  setup(__props: any) {

const fill = { color: '#2EA2E5' };

const props = __props;
const { video } = toRefs(props);

const store = useStore();

const deadlineTime = computed(() => store.state.videoUpload.deadlineTime);
const currentUser = computed(() => store.state.user.currentUser);

return (_ctx: any,_cache: any) => {
  const _component_vue_circle = _resolveComponent("vue-circle")!

  return (_unref(video) && currentUser.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(isVideoUploadingByOther)(_unref(video), currentUser.value))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('common:form.isUploadingFiles')), 1))
          : _createCommentVNode("", true),
        (_unref(isVideoUploadingByOther)(_unref(video), currentUser.value) && _unref(isVideoUploadQueued)(_unref(video)))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('video.uploader.enqueued')), 1))
          : _createCommentVNode("", true),
        (_unref(isVideoUploadRetry)(_unref(video)) && _unref(isVideoUploadByKeyObject)(_unref(video)))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('video.uploader.failed.retrying', {item: _unref(video).remainingRetryDelay})), 1))
          : _createCommentVNode("", true),
        (_unref(isVideoUploadFailed)(_unref(video)))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('video.uploader.failed.helpText')), 1)
            ]))
          : _createCommentVNode("", true),
        (_unref(isVideoUploading)(_unref(video)))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('common:form.isUploadingFiles')), 1)
            ]))
          : _createCommentVNode("", true),
        (_unref(isVideoSourceIngression)(_unref(video)))
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (!_unref(isVideoProbeError)(_unref(video)))
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                    (!_unref(isVideoProcessedOverTime)(_unref(video), deadlineTime.value))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('video.upload.checkingFile')), 1))
                      : _createCommentVNode("", true),
                    (_unref(isVideoProcessedOverTime)(_unref(video), deadlineTime.value))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('video.upload.checkingFileOverTime')), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_unref(isVideoProbeError)(_unref(video)))
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                    (_unref(isVideoProbeErrorFile)(_unref(video)))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t('video.upload.uploadedFileError.title')), 1))
                      : _createCommentVNode("", true),
                    (!_unref(isVideoProbeErrorFile)(_unref(video)))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                          _createTextVNode(_toDisplayString(_ctx.$t('video.upload.checkingFileError.helpText')) + " ", 1),
                          _cache[0] || (_cache[0] = _createElementVNode("a", {
                            href: "mailto:support@byteark.com",
                            target: "_top"
                          }, "support@byteark.com", -1))
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(Badge, {
                      title: `Code : ${_unref(getVideoProbeErrorType)(_unref(video))}`,
                      size: "small",
                      badgeStyle: "danger"
                    }, null, 8, ["title"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_unref(isVideoProcessing)(_unref(video)))
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$t('video.processingFiles')), 1))
          : _createCommentVNode("", true),
        (_unref(isVideoProcessing)(_unref(video)) && _unref(isVideoPartiallyCompleted)(_unref(video)))
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_vue_circle, {
                  progress: 50,
                  size: 16,
                  reverse: false,
                  "line-cap": "round",
                  fill: fill,
                  "empty-fill": "rgba(0, 0, 0, .1)",
                  "animation-start-value": 0.0,
                  "start-angle": -90,
                  "insert-mode": "append",
                  thickness: 3,
                  "show-percent": false,
                  class: "circle-desktop"
                }),
                _createVNode(_component_vue_circle, {
                  progress: 50,
                  size: 11,
                  reverse: false,
                  "line-cap": "round",
                  fill: fill,
                  "empty-fill": "rgba(0, 0, 0, .1)",
                  "animation-start-value": 0.0,
                  "start-angle": -90,
                  "insert-mode": "append",
                  thickness: 2,
                  "show-percent": false,
                  class: "circle-mobile"
                }),
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('video.processedFiles')) + " " + _toDisplayString(_unref(getVideoTotalCompletedOutputs)(_unref(video))) + " / " + _toDisplayString(_unref(getVideoTotalExpectedOutputs)(_unref(video))) + " " + _toDisplayString(_ctx.$t('glossary:video')), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})