/* eslint-disable no-shadow */
import { loadPlaybackChannels } from '@/modules/shared';
import { ActionContext } from 'vuex';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateLoadingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { RootState } from '@/store/type';
import type { LoadingState } from '@/modules/shared/types/state.type';
import type { PlaybackChannel, PlaybackChannelListState } from '../types/playbackChannel.type';

type PlaybackChannelListActionContext = ActionContext<PlaybackChannelListState, RootState>

const initialState = (): PlaybackChannelListState => ({
  playbackChannelList: [],
  loadPlaybackChannelListState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setPlaybackChannelList(state: PlaybackChannelListState, playbackChannelList: PlaybackChannel[]) {
    state.playbackChannelList = playbackChannelList;
  },
  setLoadPlaybackChannelListState(state: PlaybackChannelListState, loadingState: LoadingState) {
    state.loadPlaybackChannelListState = setStateLoadingStatusByType(loadingState);
  },
  destroyPlaybackChannelList(state: PlaybackChannelListState) {
    Object.assign(state, initialState());
  },
};

const actions = {
  async loadPlaybackChannelList({ commit }: PlaybackChannelListActionContext, filter: { q: string}) {
    try {
      commit('setLoadPlaybackChannelListState', { type: 'loading' });
      const response = await loadPlaybackChannels({ ...filter, limit: 100 });
      commit('setPlaybackChannelList', response.data.data);
      commit('setLoadPlaybackChannelListState', { type: 'success' });
    } catch (error) {
      commit('setLoadPlaybackChannelListState', { type: 'error', error: formatErrorObject(error, 'playbackChannelList') });
    }
  },
  destroyPlaybackChannelList({ commit }: PlaybackChannelListActionContext) {
    commit('destroyPlaybackChannelList');
  },
};

export default {
  state,
  mutations,
  actions,
};
