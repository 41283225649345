<template>
  <!-- TODO: Use SectionCollapse -->
  <div v-if="isEnableSubtitleFeature">
    <ChooseProjectCaptionProfileModal
      v-if="isShowChooseSubtitleModal"
      @modal-close="() => isShowChooseSubtitleModal = false"/>
    <Section
      title="Subtitle Profile Setting" v-if="captionProfile">
      <template v-slot:action>
        <Button
          v-if="canUpdateProject"
          type="button"
          buttonStyle="secondary"
          buttonClasses="change-preset-button"
          @click="() => isShowChooseSubtitleModal = true"
        >
          <template v-slot:content>
            <span class="button-text-full">
              Change Subtitle Profile
            </span>
            <span class="button-text-short">
              {{ t('common:button.change') }}
            </span>
          </template>
        </Button>
      </template>
      <ItemInformationCard
        v-if="!_.isNil(transcribeServiceList) && !_.isNil(captionProfile)"
        :title="captionProfile.name"
      >
        <div class="information-row">
          <div class="information-group">
            <div class="label">Transcribe Service ({{transcribeServiceList?.length}})</div>
            <div class="service-list">
              <div class="value" v-for="(service, index) in transcribeServiceList" :key="index">
                {{serviceNameDict.transcribe[service]}}
              </div>
            </div>
          </div>
          <div class="information-group">
            <div class="label">Status</div>
            <div class="value">
              {{t(captionPublishConditionTranslationKey[captionProfile.publishStatusSetting])}}
            </div>
          </div>
        </div>
        <div class="information-row">
          <div class="information-group">
            <div class="label">Auto-translate {{languageList?.length ? `(${languageList?.length})` : ''}}</div>
            <div class="language-list" v-if="languageList?.length">
              <div class="badge-list" v-for="(language, index) in languageList" :key="index">
                <Badge :title="language.name"/>
              </div>
            </div>
            <div v-else>
              -
            </div>
          </div>
        </div>
      </ItemInformationCard>
      <template v-slot:section-footer-with-border v-if="$attrs.isManager">
        <div class="preset-link">
          <router-link class="link" :to="`/${teamName}/manage/subtitle-profiles`">
            {{ t('project.manage.setting.subtitleProfile.title') }} <i class="fas fa-chevron-right" />
          </router-link>
        </div>
      </template>
    </Section>
    <Section title="Subtitle Profile Setting" v-if="!captionProfile">
      <MessageBox
        :title="t('project.defaultSettings.subtitle.noSubtitle')"
        :description="t('project.defaultSettings.subtitle.emptySuggestion')"
        :buttonTitle="canUpdateProject ? t('project.defaultSettings.subtitle.settingDefaultButton') : ''"
        buttonStyle="secondary"
        :onClickButton="() => isShowChooseSubtitleModal = true" />
      <template v-slot:section-footer-with-border v-if="$attrs.isManager">
        <div class="preset-link">
          <router-link class="link" :to="`/${teamName}/manage/subtitle-profiles`">
            {{t('project.defaultSettings.subtitle.settingButton')}} <i class="fas fa-chevron-right" />
          </router-link>
        </div>
      </template>
    </Section>
    <PreLoaderSection
      v-if="loadProjectCaptionProfileState.status === 'loading'"
      :options="{ height: '300' }">
    </PreLoaderSection>
  </div>
</template>

<script lang="ts" setup>
import { canAccessFeature } from '@/middlewares/guard';
import {
  computed,
  onMounted,
  ref,
  watch,
  useAttrs,
} from 'vue';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from '@/modules/projectDefaultUploadSetting/store/hooks';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import ItemInformationCard from '@/modules/shared/components/atoms/itemInformationCard/ItemInformationCard.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import { captionPublishConditionTranslationKey, type CaptionProfile } from '@/modules/captionProfile';
import ChooseProjectCaptionProfileModal from '../chooseProjectCaptionProfileModal/ChooseProjectCaptionProfileModal.vue';
import { getCaptionProfileByKey } from '../../../services';

const { t } = useI18n();
const route = useRoute();
const store = useStore();

const captionProfile = ref<CaptionProfile | null>(null);
const loadProjectCaptionProfileState = ref(setStateLoadingStatusByType());
const isShowChooseSubtitleModal = ref(false);

const { canUpdateProject } = useAttrs();

const teamName = computed(() => route.params.teamName);
const currentProject = computed(() => store.state.project.currentProject);

// Computed
const languageList = computed(() => captionProfile.value?.translate?.languages);
const transcribeServiceList = computed(() => captionProfile.value?.transcribe?.services);
const autoGenerateCaptionSettingId = computed(() => currentProject.value?.autoGenerateCaptionSettingId);
const isEnableSubtitleFeature = computed(() => canAccessFeature('auto-generate-caption'));

const loadCaptionProfileData = async (captionProfileId: string | null | undefined) => {
  if (_.isNil(captionProfileId)) {
    return;
  }

  try {
    loadProjectCaptionProfileState.value = setStateLoadingStatusByType({ type: 'loading' });
    const response = await getCaptionProfileByKey(captionProfileId);
    captionProfile.value = response?.data;
    loadProjectCaptionProfileState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (error) {
    loadProjectCaptionProfileState.value = setStateLoadingStatusByType({ type: 'error', error: formatErrorObject(error, 'Subtitle Profile') });
    // handle
  }
};

watch(autoGenerateCaptionSettingId, () => {
  if (autoGenerateCaptionSettingId.value) {
    loadCaptionProfileData(autoGenerateCaptionSettingId.value);
  }
}, { immediate: true });

onMounted(() => {
  loadCaptionProfileData(autoGenerateCaptionSettingId.value);
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.fa-chevron-right {
  margin-left: $spacing-4;
}

.language-list {
  display: flex;
  flex-wrap: wrap;

  :not(:last-child) {
    margin-right: $spacing-8;
  }
  > * {
    margin-bottom: $spacing-4;
  }
}

.information-row {
  display: flex;
  &:not(:last-child) {
    margin-bottom: $spacing-24;
  }
}

.information-group {
  flex: 1;
}

.label {
  margin-bottom: $spacing-8;
}

.value {
  font-size: $font-level-6;
  color: $grey-800;

  &:not(:first-child) {
    margin-top: $spacing-8;
  }
}

:deep(.section-prefetch) {
  margin-bottom: 16px;
}

:deep(.preset-profile) {
  margin-bottom: $spacing-base;
}

.button-text-short {
  display: none;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-base;
}

.empty-title {
  color: $grey-800;
  font-weight: $font-weight-bold;
}

.empty-description {
  margin-top: $spacing-8;
  margin-bottom: $spacing-base;
}

@media screen and (max-width: $max-layout-sm) {
  .button-text-short {
    display: inline;
  }
  .button-text-full {
    display: none;
  }
}
</style>
