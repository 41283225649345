import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-edit-container" }
const _hoisted_2 = { class: "inline-edit-wrapper" }
const _hoisted_3 = { class: "button-container" }

import _ from 'lodash';
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import { InputValue } from '@/modules/shared/types/input.type';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import { useI18n } from 'vue-i18n';

interface InlineEditProps {
  value: InputValue;
  onSubmit: (text: string) => void;
  onClose?: () => void;
  validateName?: (text: string) => string;
  validateNameErrorMessage?: {
    type: string;
    default: string;
  };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'InlineEdit',
  props: {
    value: {},
    onSubmit: { type: Function },
    onClose: { type: Function },
    validateName: { type: Function },
    validateNameErrorMessage: {}
  },
  setup(__props: any) {

const props = __props;
const {
  value,
  onSubmit,
  onClose,
  validateName,
  validateNameErrorMessage,
} = toRefs(props);

const { t } = useI18n();

const inputValue = ref<string>('');
const isTyped = ref<boolean>(false);
const invalidFeedback = ref<string>('');

const isEditNameValid = computed(() => {
  if (!inputValue.value) {
    return false;
  }
  return !invalidFeedback.value;
});

const nameValidationText = computed(() => {
  if (inputValue.value) {
    return validateNameErrorMessage.value || t('validation:name.validateDetault');
  }
  return t('validation:name.required', { item: 'Playback' });
});

const showDefaultError = computed(() => (!inputValue.value || !!invalidFeedback.value) && isTyped.value);

watch(inputValue, (newValue) => {
  isTyped.value = true;

  if (validateName.value) {
    invalidFeedback.value = validateName.value(newValue);
  }
});

onMounted(() => {
  const valueFromProp = _.cloneDeep(value.value);

  if (_.isNil(valueFromProp)) {
    inputValue.value = '';
  } else if (typeof valueFromProp === 'number') {
    inputValue.value = valueFromProp.toString(10);
  } else {
    inputValue.value = valueFromProp;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Input, {
        modelValue: inputValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
        isShowErrorMessage: showDefaultError.value,
        errorMessage: nameValidationText.value,
        onKeyup: _cache[1] || (_cache[1] = _withKeys(() => _unref(onSubmit)(inputValue.value), ["enter"]))
      }, null, 8, ["modelValue", "isShowErrorMessage", "errorMessage"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(Button, {
        buttonStyle: "primary",
        disabled: !isEditNameValid.value,
        onClick: _cache[2] || (_cache[2] = () => _unref(onSubmit)(inputValue.value))
      }, {
        "icon-prefix": _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("i", { class: "fa fa-check" }, null, -1)
        ])),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(Button, {
        buttonStyle: "secondary",
        onClick: _unref(onClose)
      }, {
        "icon-prefix": _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("i", { class: "fa fa-xmark" }, null, -1)
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}
}

})