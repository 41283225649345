import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import type {
  TeamFeaturesState, VideoState, LanguageListState,
  ProjectState,
} from '@/modules/shared';
import type { VideoUploadState } from '@/modules/videoUpload';
import type { TeamSettingState } from '@/modules/teamSetting';
import type { CollectionListState } from '@/modules/collectionList';
import type { GeoblockRuleListState } from '@/modules/geoblock';
import type { PlaybackChannelListState } from '@/modules/playbackChannel';

export const useStore = () => baseUseStore<{
  collectionList: CollectionListState;
  geoblockRuleList: GeoblockRuleListState;
  video: VideoState;
  project: ProjectState;
  videoUpload: VideoUploadState;
  teamFeatures: TeamFeaturesState;
  teamSetting: TeamSettingState;
  languageList: LanguageListState;
  playbackChannelList: PlaybackChannelListState;
}>(storeKey);
