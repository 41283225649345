import store from '@/store';
import type { RouteRecordRaw } from 'vue-router';
import { geoblockRuleList } from '@/modules/geoblock';
import { preset, presetList } from '@/modules/preset';
import { project } from '@/modules/shared';
import { videoUpload, videoUploaderDraggable } from '@/modules/videoUpload';
import { projectList } from '@/modules/projectList';
import { teamSetting } from '@/modules/teamSetting';
import { projectVideoList } from '@/modules/projectVideoList';
import { user } from '@/modules/user';
import multiDrm from '../store/multiDrm';

export default function createMultiDrmRoutes(): RouteRecordRaw[] {
  return [{
    path: 'multi-drm',
    name: 'multiDrm',
    component: () => import(/* webpackChunkName: "manageMultiDrm" */ '@/modules/multiDrm/pages/ManageMultiDrm.vue'),
    meta: { teamManagement: true },
    beforeEnter: (to, from, next) => {
      if (!store.hasModule('multiDrm')) {
        store.registerModule('multiDrm', multiDrm);
      }
      if (!store.hasModule('preset')) {
        store.registerModule('preset', preset);
      }
      if (!store.hasModule('presetList')) {
        store.registerModule('presetList', presetList);
      }
      if (!store.hasModule('geoblockRuleList')) {
        store.registerModule('geoblockRuleList', geoblockRuleList);
      }
      if (!store.hasModule('project')) {
        store.registerModule('project', project);
      }
      if (!store.hasModule('videoUpload')) {
        store.registerModule('videoUpload', videoUpload);
      }
      if (!store.hasModule('videoUploaderDraggable')) {
        store.registerModule('videoUploaderDraggable', videoUploaderDraggable);
      }
      if (!store.hasModule('projectVideoList')) {
        store.registerModule('projectVideoList', projectVideoList);
      }
      if (!store.hasModule('projectList')) {
        store.registerModule('projectList', projectList);
      }
      if (!store.hasModule('teamSetting')) {
        store.registerModule('teamSetting', teamSetting);
      }
      if (!store.hasModule('user')) {
        store.registerModule('user', user);
      }
      next();
    },
  }];
}
