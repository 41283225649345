<template>
  <div class="page-container">
    <div class="page">
      <div class="page-content" :class="{ 'page-scrolling': isScrolling }">
        <div class="page-navigation" v-if="$slots['page-navigation']">
          <slot name="page-navigation" />
        </div>
        <slot name="page-header" v-if="$slots['page-header']" />
        <div class="page-header" v-if="isPageLoading">
          <PreloaderBar height="30px" width="150px" />
          <PreloaderBar height="20px" width="200px" />
          <PreloaderBar height="20px" width="250px" />
        </div>
        <div class="page-header" v-if="isShowPageHeader && !isPageLoading">
          <div class="page-title-container">
            <div class="page-title">
              <slot name="page-title">{{ title ?? '' }}</slot>
            </div>
            <div class="page-action">
              <slot name="page-action" />
            </div>
          </div>
          <div v-if="projectName" class="page-project-name">
            <router-link v-if="projectKey" :to="`/${route.params?.teamName}/projects/${projectKey}`">
              <i class="fas fa-folder-open" />
              {{ projectName }}
            </router-link>
            <template v-else>
              <i class="fas fa-folder-open" />
              {{ projectName }}
            </template>
          </div>
          <div v-if="subtitle" class="page-description">{{ subtitle }}</div>
          <div v-if="badgeList" class="page-title-badge">
            <Badge
              v-for="(badge, index) in badgeList"
              :key="index"
              :title="badge.title"
              :badgeStyle="badge.badgeStyle"
              :theme="badge.theme"
              size="small"
              :class="{ clickable: badge.path || typeof badge.onClick === 'function' }"
              @click="() => onBadgeClick(badge)"
            >
              <template v-slot:icon-prefix v-if="badge.iconClassName">
                <i :class="badge.iconClassName" />
              </template>
            </Badge>
          </div>
        </div>

        <div class="page-body" :class="{ 'grid-layout': $slots['right-top'] }">
          <div class="main-content">
            <slot></slot>
          </div>

          <div class="right-top" id="right-top" v-if="$slots['right-top']">
            <slot name="right-top"></slot>
          </div>
          <div
            class="right-bottom"
            id="right-bottom"
            :style="{ height: isLGorMDscreen ? `${topContentHeight}px` : 'inherit' }"
            v-if="$slots['right-bottom']"
          >
            <slot name="right-bottom"></slot>
          </div>
        </div>
        <!-- <slot name="page-header"></slot> -->
      </div>
      <div class="page-footer">
        <div class="app-revision-container">
          <p>
            © {{ getCurrentYear() }} ByteArk Stream | All Rights Reserved |
            <span v-html="appRevision" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import {
  ref, computed, watch, onMounted, toRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { useBreakpoints } from '@/composables/useBreakpoint';
import { getCurrentYear } from '@/modules/shared/utils/dateFormatter';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import PreloaderBar from '@/modules/shared/components/atoms/preloaderBar/PreloaderBar.vue';
import type { PageBadge } from '@/modules/shared/types/page.type';

interface PageProps {
  title?: string;
  subtitle?: string;
  projectName?: string;
  projectKey?: string;
  badgeList?: PageBadge[];
  isShowPageHeader?: boolean;
}

const props = withDefaults(defineProps<PageProps>(), { isShowPageHeader: true });
const {
  title,
  subtitle,
  projectName,
  projectKey,
  badgeList,
  isShowPageHeader,
} = toRefs(props);

const router = useRouter();
const route = useRoute();
const store = useStore();
const { width } = useBreakpoints();

const isScrolling = ref<boolean>(false);
const topContentHeight = ref<number>(0);
const isLGorMDscreen = ref<boolean>(false);

const isPageLoading = computed(() => store.state.global?.isPageLoading);
const appRevision = computed(() => {
  const REVISION = process.env.VUE_APP_REVISION.replace(/"/g, '');
  const BRANCH = process.env.VUE_APP_BRANCH.replace(/"/g, '');
  const COMMITHASH = process.env.VUE_APP_COMMITHASH.replace(/"/g, '');

  if (process.env.NODE_ENV === 'production') {
    return `Version ${REVISION}#${COMMITHASH}`;
  }

  return `Working on Branch ${BRANCH}#${COMMITHASH}`;
});

const onBadgeClick = ({ path, onClick }: PageBadge) => {
  if (typeof onClick === 'function') {
    onClick();
  } else if (path) {
    router.push(path);
  }
};

const getTopContentHeight = () => {
  const element = document.getElementById('right-top');
  // Use "any" here because "instanceof" needs an "any" variable to work.
  const elementChild = _.get<HTMLElement, any>(element, 'children[0]');

  if (elementChild instanceof HTMLElement) {
    topContentHeight.value = elementChild.offsetHeight;
  }
};

onMounted(() => {
  getTopContentHeight();
  window.addEventListener('resize', () => getTopContentHeight());
});

watch(
  () => width.value,
  () => {
    if (width.value >= 576 && width.value <= 992 && !isLGorMDscreen.value) {
      isLGorMDscreen.value = true;
    } else if ((width.value > 992 || width.value < 576) && isLGorMDscreen.value) {
      isLGorMDscreen.value = false;
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

@keyframes placeHolderShimmer {
  0%{ background-position: -50% 0; }
  100%{ background-position: 50% 0; }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #efefef, #eaeaea, #efefef);
  background-size: 50% 104px;
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
}

.page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $min-layout-xxl) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: $max-layout-lg) {
    height: calc(100vh - 60px);
  }
  overflow: auto;
}

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  .page-content {
    @media screen and (min-width: $min-layout-xxl) {
      max-width: 1400px;
    }
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: $spacing-24 $spacing-base $spacing-8 $spacing-base;
    gap: $spacing-24;
    position: relative;

    .page-navigation{
      cursor:pointer;
    }

    .page-header {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: $spacing-8;

      .page-title-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .page-title {
          max-width: 70%;
          word-wrap: break-word;
          word-break: break-all;
          color: $grey-800;
          font-weight: $font-weight-bold;
          font-size: $font-level-4;
          text-transform: capitalize;
        }
      }

      .page-project-name {
        display: flex;
        gap: $spacing-8;
        align-items: center;

        a{
          color: inherit;
        }
      }

      .page-description {
        font-size: $font-level-6;
      }

      .page-title-badge {
        display: flex;
        gap: $spacing-8;
        flex-wrap: wrap;
        .clickable {
          cursor: pointer;
        }
      }
    }

    .page-body {
      flex: 1;
      width: 100%;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      &.grid-layout {
        display: grid;
        width: 100%;

        grid-template-columns: minmax(0, 1fr) 420px;
        grid-template-rows: auto 1fr 1fr;
        row-gap: $spacing-24;
        column-gap: $spacing-base;
      }
      .main-content {
        grid-row: 1/4;
        grid-column: 1/2;
      }

      .right-top {
        grid-row: 1/2;
        grid-column: 2/ 3;
      }

      .right-bottom {
        grid-row: 2/4;
        grid-column: 2 /3;
        max-height: inherit;
        overflow: auto;
      }

      //   @media screen and (max-width: $max-layout-xxl) {
      //   &.grid-layout {
      //     grid-template-columns: minmax(60%, 1fr) 1fr;
      //   }

      //   .right-top {
      //     width: 100%;
      //   }

      //   .right-bottom {
      //     width: 100%;
      //   }
      // }

      @media screen and (max-width: $max-layout-lg) {
        &.grid-layout {
          grid-template-rows: auto 1fr;
          grid-template-columns: 60% minmax(0, 1fr);
          row-gap: $spacing-base;
          column-gap: $spacing-base;

          .main-content {
            grid-row: 2/3;
            grid-column: 1/3;
          }
          .right-top {
            grid-row: 1/2;
            grid-column: 1/2;
          }

          .right-bottom {
            grid-row: 1/2;
            grid-column: 2/3;
          }
        }
      }

      @media screen and (max-width: $max-layout-sm) {
        &.grid-layout {
          grid-template-columns: minmax(0, 1fr);
          grid-template-rows: repeat(3, auto);

          .main-content {
            grid-row: 3/4;
            grid-column: 1/2;
          }

          .right-top {
            grid-row: 1/2;
            grid-column: 1/2;
          }

          .right-bottom {
            grid-row: 2/3;
            grid-column: 1/2;
          }
        }
      }
    }
  }
}

.page-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: $spacing-48;

  .app-revision-container {
    line-height: $line-height-description;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0px $spacing-base $spacing-8 $spacing-base;

    @media screen and (max-width: $max-layout-lg) {
      max-width: 80%;
      margin: 0px 0px $spacing-8 0px;
      text-align: center;
      justify-content: center;
    }
  }
}
</style>
