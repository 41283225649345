/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { createUrlSigningKeyAPI, deleteUrlSigningKeyAPI } from '@/modules/urlSigningKey/services/api';
import {
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';

import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { SavingState } from '@/modules/shared/types/state.type';
import { UrlSigningKeyState, UrlSigningKey } from '../types/UrlSigningKey.type';

type UrlSigningKeyActionContext = ActionContext<UrlSigningKeyState, RootState>

const initialState = (): UrlSigningKeyState => ({
  currentUrlSigningKey: null,
  saveUrlSigningKeyState: setStateSavingStatusByType(),
  deleteUrlSigningKeyState: setStateSavingStatusByType(),
});

const state = initialState();

const mutations = {
  setCurrentUrlSigningKey(state: UrlSigningKeyState, urlSigningKey: UrlSigningKey) {
    state.currentUrlSigningKey = urlSigningKey;
  },
  setSaveUrlSigningKeyState(state: UrlSigningKeyState, message: SavingState) {
    state.saveUrlSigningKeyState = setStateSavingStatusByType(message);
  },
  setDeleteUrlSigningKeyState(state: UrlSigningKeyState, message: SavingState) {
    state.deleteUrlSigningKeyState = setStateSavingStatusByType(message);
  },
  destroyDeleteUrlSigningKey(state: UrlSigningKeyState) {
    state.deleteUrlSigningKeyState = setStateSavingStatusByType();
  },
};

const actions = {
  async createUrlSigningKey({ commit }: UrlSigningKeyActionContext) {
    commit('setSaveUrlSigningKeyState', { type: 'loading' });
    try {
      const response = await createUrlSigningKeyAPI();
      commit('setCurrentUrlSigningKey', response.data);
      commit('setSaveUrlSigningKeyState', { type: 'success' });
    } catch (error) {
      commit('setSaveUrlSigningKeyState', { type: 'error', error: formatErrorObject(error, 'urlSigningKey') });
    }
  },
  async deleteUrlSigningKey({ commit }: UrlSigningKeyActionContext, deleteUrlSigningKeyId: string) {
    commit('setDeleteUrlSigningKeyState', { type: 'loading' });
    try {
      await deleteUrlSigningKeyAPI(deleteUrlSigningKeyId);
      commit('setCurrentUrlSigningKey', null);
      commit('setDeleteUrlSigningKeyState', { type: 'success' });
    } catch (error) {
      commit('setDeleteUrlSigningKeyState', { type: 'error', error: formatErrorObject(error, 'urlSigningKey') });
    }
  },
  destroyDeleteUrlSigningKey({ commit }: UrlSigningKeyActionContext) {
    commit('destroyDeleteUrlSigningKey');
  },
};

export default {
  state,
  actions,
  mutations,
};
