import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-container" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "input-label" }

import ErrorToast from '@/modules/shared/components/molecules/toasts/ErrorToast.vue';
import {
  computed, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { StatusState } from '@/modules/shared/types/state.type';
import { deleteAllVideos } from '../../../services';
import { useStore } from '../../../store/hooks';

interface deleteVideosModalProps {
  onClose: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteVideosModal',
  props: {
    onClose: { type: Function }
  },
  setup(__props: any) {

const props = __props;
const { onClose } = toRefs(props);

const store = useStore();
const router = useRouter();
const { t } = useI18n();

const confirmText = ref<string>('');
const deleteVideosState = ref<StatusState>(setStateLoadingStatusByType());

const videoCount = computed(() => store.state.project.currentProject?.videoCount ?? 0);
const projectName = computed(() => store.state.project.currentProject?.name ?? '');
const projectKey = computed(() => store.state.project.currentProject?.key ?? '');
const loadProjectListState = computed(() => store.state.projectList.loadProjectListState);

const confirmButtonKey = computed(() => (
  `project.deleteVideos.modal.button${videoCount.value > 1 ? '_plural' : ''}`
));

const isDisabledPrimaryButton = computed(() => (
  loadProjectListState.value.status === 'loading'
  || confirmText.value !== 'delete all videos'
  || deleteVideosState.value.status === 'loading'
));

async function onConfirmDeleteVideos() {
  if (isDisabledPrimaryButton.value || !projectKey.value) {
    return;
  }

  deleteVideosState.value = setStateLoadingStatusByType({ type: 'loading' });

  try {
    await deleteAllVideos(projectKey.value);
    store.commit('setAreProjectVideosDeleted', true);
    setTimeout(() => store.commit('setAreProjectVideosDeleted', false), 5000);

    deleteVideosState.value = setStateLoadingStatusByType({ type: 'success' });
    await router.push({ name: 'recentlyDeleted' });
  } catch (error) {
    console.error(error);
    deleteVideosState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Delete Videos'),
    });

    setTimeout(() => onClose.value(), 100);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (deleteVideosState.value.status === 'error' && deleteVideosState.value.error)
      ? (_openBlock(), _createBlock(ErrorToast, {
          key: 0,
          title: _unref(t)('response:errorTitle'),
          description: `${_unref(t)('response:deleteVideosError.title')} (Error code: ${deleteVideosState.value.error.statusCode})`,
          duration: 10000
        }, null, 8, ["title", "description"]))
      : _createCommentVNode("", true),
    _createVNode(Modal, {
      title: _unref(t)('project.deleteVideos.title'),
      onModalClose: _unref(onClose),
      size: "small",
      primaryButtonText: _unref(t)(confirmButtonKey.value, { count: videoCount.value }),
      onClickPrimaryButton: onConfirmDeleteVideos,
      primaryButtonStyle: "danger",
      isDisabledPrimaryButton: isDisabledPrimaryButton.value
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(Alert, {
            priority: "danger",
            alertText: _unref(t)('project.deleteVideos.modal.alert', { count: videoCount.value, projectName: projectName.value })
          }, null, 8, ["alertText"]),
          _createElementVNode("p", _hoisted_2, [
            _createTextVNode(_toDisplayString(_unref(t)('project.deleteVideos.modal.description1')) + " ", 1),
            _createVNode(_component_router_link, { to: { name: 'recentlyDeleted' } }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('project.deleteVideos.modal.description2')), 1)
              ]),
              _: 1
            }),
            _cache[1] || (_cache[1] = _createTextVNode(" " + _toDisplayString(' ') + " ")),
            _createElementVNode("span", {
              innerHTML: _unref(t)('project.deleteVideos.modal.description3')
            }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_4, [
              _createTextVNode(_toDisplayString(_unref(t)('project.deleteVideos.modal.description4')) + " “", 1),
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "code" }, "delete all videos", -1)),
              _createTextVNode("” " + _toDisplayString(_unref(t)('project.deleteVideos.modal.description5')), 1)
            ]),
            _createVNode(Input, {
              modelValue: confirmText.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((confirmText).value = $event)),
              onPressedEnter: onConfirmDeleteVideos
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "onModalClose", "primaryButtonText", "isDisabledPrimaryButton"])
  ], 64))
}
}

})