import { axiosAPI, axiosAPIv2 } from '@/plugins/axios';
import qs from 'qs';
import { FilterPlayer, IPlayer } from '../types/Player.type';

export const loadPlayers = (filter: FilterPlayer) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosAPI.get(`/players${query}`);
};

export const loadPlayerByIdAPI = (playerId:string) => axiosAPI.get<IPlayer>(`/players/${playerId}`);
export const createPlayerAPI = (payload: IPlayer) => axiosAPI.post('/players', payload);
export const updatePlayerByIdAPI = (playerId:string, payload: IPlayer) => axiosAPI.put(`/players/${playerId}`, payload);

export const updateLighthouseEnabled = (playerId: string, payload: { lighthouseEnabled: boolean }) => (
  axiosAPIv2.put(`/players/${playerId}/lighthouse-enabled`, payload)
);
