import type { GlobalState } from '@/store/type';
import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import type { VideoUploadState } from '@/modules/videoUpload';
import type { UserState } from '@/modules/user';
import type { ProjectListState } from '@/modules/projectList/types/projectList';
import type { ProjectState } from '@/modules/shared/types/project.type';
import type { CollectionListState } from '@/modules/collectionList/types';
import type { ProjectVideoCaptionIssueListState } from '../types/projectVideoCaptionIssueList.type';
import type { ProjectVideoListState } from '../types/projectVideoList.type';
import { ProjectDeletedVideoListState } from '../types/projectDeletedVideoList.type';

export const useStore = () => baseUseStore<{
  collectionList: CollectionListState;
  global: GlobalState;
  project: ProjectState;
  projectList: ProjectListState;
  projectVideoCaptionIssueList: ProjectVideoCaptionIssueListState;
  projectVideoList: ProjectVideoListState;
  projectDeletedVideoList: ProjectDeletedVideoListState;
  user: UserState;
  videoUpload: VideoUploadState;
}>(storeKey);
