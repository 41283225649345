/* eslint no-else-return: ["off"] */

import store from '@/store';
import { axiosAPI } from '@/plugins/axios';
import { configAxiosForWebConsole } from '@/plugins/axios/config';
import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
} from 'vue-router';
import { configTusUploader } from '../plugins/tus/config';

const baseURL = process.env.VUE_APP_ORIGIN || window.location.origin;

export const guardAuth = async (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const { teamName } = to.params;

    if (teamName) {
      configAxiosForWebConsole(teamName);
      configTusUploader(teamName);
      const { currentUser } = await store.getters;
      const { teamFeatures } = store.state.teamFeatures;

      if (!teamFeatures) {
        try {
          await store.dispatch('loadTeamFeatures');
        } catch (error) {
          // handle here
        }
      }
      if (!currentUser) {
        const account = await axiosAPI.get('/me');

        await store.dispatch('setCurrentUser', account.data);
        // await store.dispatch('loadCountryList');
        // if (to.matched.some((record) => record.meta.teamManagement)) {
        //   if (account.data?.role !== 'manager' && account.data?.role !== 'admin') {
        //     return next(`/${teamName}`);
        //   }
        // }
      }

      return next();
    } else {
      window.location.href = `${baseURL}/accounts/byteark`;
    }

    return undefined;
  } else {
    return next();
  }
};

export function canAccessFeature(key: string): boolean {
  const { teamFeatures } = store.state.teamFeatures;

  if (!teamFeatures) {
    /** No teamFeatures data */
    return false;
  }

  return teamFeatures[key] === true;
}
