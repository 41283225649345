import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_2 = {
  key: 1,
  class: "options-container"
}
const _hoisted_3 = { class: "preset-name" }
const _hoisted_4 = { class: "message-container" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_7 = {
  key: 2,
  class: "text-danger"
}
const _hoisted_8 = {
  key: 3,
  class: "text-danger"
}

import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { useStore } from '@/store';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import type { Filter } from '@/modules/shared/types/axios.type';
import type { Preset } from '@/modules/preset';
import type { StatusState } from '@/modules/shared/types/state.type';
import { loadPresets } from '@/modules/preset';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import RadioButtonChoice from '@/modules/shared/components/atoms/radioButtonChoice/RadioButtonChoice.vue';
import PresetSummaryInfo from '../../../../preset/components/molecules/presetSummaryInfo/PresetSummaryInfo.vue';

interface ChangePresetModalProps {
  presetData: Preset | null;
  onSubmit: (preset: Preset) => void;
  onClose: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangePresetModal',
  props: {
    presetData: {},
    onSubmit: { type: Function },
    onClose: { type: Function }
  },
  setup(__props: any) {

const props = __props;
const {
  presetData,
  onSubmit,
  onClose,
} = toRefs(props);

const { t } = useI18n();
const store = useStore();

const presets = ref<Preset[]>([]);
const selectedPreset = ref<Preset | null>(null);
const loadPresetState = ref<StatusState>({
  status: 'idle',
  error: null,
});

const saveProjectState = computed(() => store.state.project.saveProjectState);
const saveErrorCode = computed(() => _.get(saveProjectState.value, 'error.status', null));

async function load(filter: Filter) {
  loadPresetState.value.status = 'loading';
  try {
    const response = await loadPresets(filter);
    presets.value = response.data.data;
    loadPresetState.value.status = 'success';
  } catch (error) {
    loadPresetState.value.error = formatErrorObject(error, 'Presets');
    loadPresetState.value.status = 'error';
  }
}

function isCurrentPreset(preset: Preset) {
  const currentPresetId = _.get(selectedPreset.value, 'id', null);
  return preset.id === currentPresetId;
}

function onSelectedPreset(presetId: string) {
  selectedPreset.value = _.find(presets.value, { id: presetId }) ?? null;
}

async function onSubmitPreset() {
  if (!_.isNil(selectedPreset.value)) {
    onSubmit.value(selectedPreset.value);
  }
}

onMounted(() => {
  load({ limit: '100' });
  selectedPreset.value = _.cloneDeep(presetData.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    size: "medium",
    title: _unref(t)('project.defaultSettings.preset.change'),
    primaryButtonText: _unref(t)('common:button.save'),
    secondaryButtonText: _unref(t)('common:button.cancel'),
    isDisabledPrimaryButton: saveProjectState.value.status === 'error' || saveProjectState.value.status === 'saving',
    onClickPrimaryButton: onSubmitPreset,
    onClickSecondaryButton: _unref(onClose),
    onModalClose: _unref(onClose)
  }, {
    body: _withCtx(() => [
      (loadPresetState.value.status === 'loading')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
            _createElementVNode("i", { class: "fas fa-circle-notch fa-spin fa-4x" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (loadPresetState.value.status === 'success')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(presets.value, (preset, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "form-radio",
                key: index
              }, [
                _createVNode(RadioButtonChoice, {
                  modelValue: isCurrentPreset(preset),
                  data: preset.id,
                  "onUpdate:data": onSelectedPreset
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(preset.name), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(preset.profiles, (profile) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "preset-profile-wrapper",
                        key: profile.id
                      }, [
                        _createVNode(PresetSummaryInfo, { profile: profile }, null, 8, ["profile"])
                      ]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["modelValue", "data"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createTextVNode(_toDisplayString(saveErrorCode.value) + " ", 1),
        (saveProjectState.value.status === 'saving')
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fas fa-spinner fa-spin" }, null, -1)),
              _createTextVNode(_toDisplayString(_unref(t)('common:button.saving')), 1)
            ]))
          : _createCommentVNode("", true),
        (saveErrorCode.value === 404)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
              _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa fa-xmark" }, null, -1)),
              _createTextVNode(_toDisplayString(_unref(t)('response:error404.title', { item: 'project' })), 1)
            ]))
          : _createCommentVNode("", true),
        (saveErrorCode.value === 422)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
              _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa fa-xmark" }, null, -1)),
              _createTextVNode(_toDisplayString(_unref(t)('response:error422.title')), 1)
            ]))
          : _createCommentVNode("", true),
        (saveErrorCode.value === 500)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
              _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fa fa-xmark" }, null, -1)),
              _createTextVNode(_toDisplayString(_unref(t)('response:error500.title')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "primaryButtonText", "secondaryButtonText", "isDisabledPrimaryButton", "onClickSecondaryButton", "onModalClose"]))
}
}

})