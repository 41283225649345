import store from '@/store';
import type { RouteRecordRaw } from 'vue-router';
import { teamSetting } from '@/modules/teamSetting';
import player from '../stores/player';
import playerList from '../stores/playerList';

export default function createVideoEmbedPlayerRoutes(): RouteRecordRaw[] {
  return [{
    path: 'players',
    beforeEnter: (to, from, next) => {
      if (!store.hasModule('player')) {
        store.registerModule('player', player);
      }
      if (!store.hasModule('playerList')) {
        store.registerModule('playerList', playerList);
      }
      if (!store.hasModule('teamSetting')) {
        store.registerModule('teamSetting', teamSetting);
      }
      next();
    },
    children: [
      {
        path: '',
        name: 'playerList',
        meta: { teamManagement: true },
        component: () => import(/* webpackChunkName: 'ManagePlayer' */ '@/modules/videoEmbedPlayer/pages/ManagePlayer.vue'),
      },
      {
        name: 'createPlayer',
        path: 'create',
        meta: { teamManagement: true },
        component: () => import(/* webpackChunkName: "CreatePlayerModal" */ '@/modules/videoEmbedPlayer/components/organisms/createPlayerModal/CreatePlayerModal.vue'),
      },
      {
        path: ':playerId',
        name: 'playerEdit',
        meta: { teamManagement: true },
        component: () => import(/* webpackChunkName: 'ManagePlayerEdit' */ '@/modules/videoEmbedPlayer/pages/ManagePlayerEdit.vue'),
      },
    ],
  }];
}
