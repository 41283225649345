import qs from 'qs';
import type { Project } from '@/modules/shared/types/project.type';
import { axiosAPI } from '@/plugins/axios';
import { qsOption } from '@/services/constants';
import type { Filter, ListApiResponse } from '@/modules/shared/types/axios.type';

const defaultProjectListFilter = {
  // To fill 3 columns in the homepage. (Default: 20)
  limit: 21,
  includes: [
    'videosCount',
  ],
};

const defaultProjectFilter = {
  includes: [
    'videosCount',
    'memberships.account',
    'player',
    'creator',
  ],
};

export function loadProjectsAPI(filter?: Filter) {
  const query = qs.stringify({ ...defaultProjectListFilter, ...filter }, qsOption);
  return axiosAPI.get<ListApiResponse<Project[]>>(`/projects${query}`);
}

export function loadProjectByKey(projectKey: string, filter?: Filter) {
  const query = qs.stringify({ ...defaultProjectFilter, ...filter }, qsOption);
  return axiosAPI.get<Project>(`/projects/${projectKey}${query}`);
}

export function editProject(projectKey: string, payload: Project) {
  return axiosAPI.put<Project>(`/projects/${projectKey}`, payload);
}

export const loadTeamFeature = () => axiosAPI.get('/team-features');
