<template>
  <Field v-if="geoblockPolicy">
    <RadioButtonGroup
      :modelValue="selectedRuleChoice"
      :defaultValue="selectedRuleChoice"
      :options="geoblockRuleOptions"
      @change="(newValue: string) => onChangeGeoblockRuleChoice({ ...geoblockPolicy, type: newValue }, newValue)"
    >
      <template v-slot:option1Sub>
        <div v-show="loadGeoblockRuleState.status === 'loading'" class="geoblock-rule-loading">
          <PreLoaderSection :options="{ height: 50 }" />
        </div>
        <GeoblockRuleSelector
          v-show="loadGeoblockRuleState.status === 'success'"
          :modelValue="geoblockRuleData"
          @update:modelValue="onChangeExistingRule" />
      </template>
      <template v-slot:option2Sub>
        <div class="option">
          <div class="option-title">{{$t('video.geoBlock.allowedCountries')}}</div>
          <CountryCodesSelector
            :modelValue="geoblockPolicyCountryRule?.allowCountryCodes"
            :disabledCountryCode="geoblockPolicyCountryRule?.denyCountryCodes"
            @update:modelValue="(value) => onChangeCustomRule({ allowCountryCodes: value })"/>
        </div>
        <div class="option">
          <div class="option-title">{{$t('video.geoBlock.disallowedCountries')}}</div>
          <CountryCodesSelector
            :modelValue="geoblockPolicyCountryRule?.denyCountryCodes"
            :disabledCountryCode="geoblockPolicyCountryRule?.allowCountryCodes"
            @update:modelValue="(value) => onChangeCustomRule({ denyCountryCodes: value })" />
        </div>
      </template>
    </RadioButtonGroup>
  </Field>
</template>

<script setup lang="ts">
import _ from 'lodash';
import {
  computed,
  ref,
  watch,
  toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { Nullable } from '@/modules/shared/types/index.type';
import CountryCodesSelector from '@/modules/shared/components/molecules/countryCodesSelector/CountryCodesSelector.vue';
import {
  GeoblockRuleSelector,
  GeoblockRule,
  GeoblockPolicy,
  AppliedRule, BaseGeoblockRule,
  getGeoblockPolicyCountryRule,
} from '@/modules/geoblock';
import type { BaseGeoblockPolicy } from '@/modules/geoblock/types/Geoblock.type';
import { isGeoblockRule } from '@/modules/shared/utils/typeGuard';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import Field from '@/modules/shared/components/molecules/field/Field.vue';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import { StatusState } from '@/modules/shared/types/state.type';

interface UploadVideoGeoblockRuleSettingProps {
  geoblockRule?: Nullable<GeoblockRule>;
  defaultGeoblockRule?: Nullable<GeoblockRule | BaseGeoblockRule>;
  geoblockPolicy?: Nullable<GeoblockPolicy | BaseGeoblockPolicy>;
  loadGeoblockRuleState: StatusState;
}

const props = defineProps<UploadVideoGeoblockRuleSettingProps>();
const {
  geoblockRule,
  defaultGeoblockRule,
  geoblockPolicy,
  loadGeoblockRuleState,
} = toRefs(props);

const emit = defineEmits<{(e: 'update:geoblockPolicy', value: GeoblockPolicy | BaseGeoblockPolicy): void}>();

const { t } = useI18n();

const geoblockRuleOptions = [
  {
    label: t('video.geoBlock.chooseFromExistingRule'),
    value: 'existingRule',
    subFieldName: 'option1Sub',
  },
  {
    label: t('video.geoBlock.customGeoBlocking'),
    value: 'customRule',
    subFieldName: 'option2Sub',
  },
];

// data
const selectedRuleChoice = ref<string>('existingRule');

// computed
const geoblockPolicyCountryRule = computed(() => getGeoblockPolicyCountryRule(geoblockPolicy.value));
const geoblockRuleData = computed(() => geoblockRule.value || defaultGeoblockRule.value);

// methods
const setSelectedChoice = () => {
  if (!_.isNil(geoblockPolicy.value)) {
    selectedRuleChoice.value = geoblockPolicy.value.type;
  }
};

const mapGeoblockPolicyToValue = (newPolicy: GeoblockPolicy | BaseGeoblockPolicy) => {
  switch (newPolicy.type) {
    case 'existingRule':
      return {
        ...newPolicy,
      };
    case 'customRule':
      return {
        ...newPolicy,
        name: 'Custom Rule',
        ruleId: null,
      };
    default:
      return {
        ...newPolicy,
        ruleId: null,
      };
  }
};

const onUpdatedGeoblockPolicy = (policy: GeoblockPolicy | BaseGeoblockPolicy) => {
  emit('update:geoblockPolicy', mapGeoblockPolicyToValue(policy));
};

const onChangeGeoblockRuleChoice = (policy: GeoblockPolicy | BaseGeoblockPolicy, selectedChoice: string) => {
  // Clear geoblock policy value when chang choice
  if (selectedChoice !== selectedRuleChoice.value) {
    selectedRuleChoice.value = selectedChoice;
    if (selectedChoice === 'customRule') {
      onUpdatedGeoblockPolicy({
        ...policy,
        ruleId: null,
        name: 'Custom Rule',
        appliedRule: { allowCountryCodes: [], denyCountryCodes: [] },
        type: 'customRule',
      });
    } else if (isGeoblockRule(geoblockRuleData.value)) {
      onUpdatedGeoblockPolicy({
        type: 'existingRule',
        ruleId: geoblockRuleData.value.id,
        name: geoblockRuleData.value.name,
        appliedRule: {
          allowCountryCodes: geoblockRuleData.value.allowCountryCodes,
          denyCountryCodes: geoblockRuleData.value.denyCountryCodes,
        },
      });
    }
  }
};

const onChangeCustomRule = (customRule: AppliedRule): void => {
  if (!geoblockPolicy.value) {
    return;
  }

  let geoblockAppliedRule: AppliedRule = {
    allowCountryCodes: [],
    denyCountryCodes: [],
  };

  if (typeof geoblockPolicy.value === 'object' && 'appliedRule' in geoblockPolicy.value) {
    geoblockAppliedRule = geoblockPolicy.value.appliedRule;
  }

  const object: GeoblockPolicy | BaseGeoblockPolicy = {
    ...geoblockPolicy.value,
    type: 'customRule',
    appliedRule: {
      ...geoblockAppliedRule,
      ...customRule,
    },
  };

  onUpdatedGeoblockPolicy(object);
};

const onChangeExistingRule = (rule: GeoblockRule) => {
  onUpdatedGeoblockPolicy({
    ruleId: rule.id,
    name: rule.name,
    appliedRule: {
      allowCountryCodes: rule.allowCountryCodes,
      denyCountryCodes: rule.denyCountryCodes,
    },
    type: 'existingRule',
  });
};

watch(() => props.geoblockPolicy, () => {
  setSelectedChoice();
}, { immediate: true });
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

:deep(.radio-btn-group-container) {
  border: 0;
}

:deep(.radio-choice) {
  border-color: transparent;

  &:hover, &.checked:not(.disabled) {
    cursor: pointer;
    border-color: transparent;
    background-color: $grey-100;
  }
}

.geoblock-rule-loading {
  width: 100%;
  height: 86px;
  background-color: #FFF;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;

  :deep(.section-prefetch) {
    padding-top: 0;
    padding-left: 16px;
  }
}
:deep(.sub-field-wrapper) {
  > div:not(:last-child) {
    margin-bottom: $spacing-base;
  }
}
.option-title {
  margin-bottom: $spacing-8;
  color: #6C757D;
}
</style>
