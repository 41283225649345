import _ from 'lodash';
import { prettyFileSize } from '@/modules/shared/utils/unitFormatter';
import type { VideoUploadOptions } from '@/modules/shared/types/video.type';
import type { FileWithValidator } from '@/modules/shared/types/file.type';

const getVideoFileSize = (video: FileWithValidator): string => {
  const size = _.get(video, 'file.size', 0);
  return prettyFileSize(size);
};

const getVideoFileName = (video: FileWithValidator): string => _.get(video, 'file.name', '');

const getVideoFileError = (video: FileWithValidator) => _.head(video.error);

const prettyFileName = (fileName: string): string => fileName.substr(0, fileName.lastIndexOf('.')) || fileName;

const transformsVideoFilesToVideoPayload = (
  videoFiles: Array<FileWithValidator>, options: VideoUploadOptions,
): Record<string, any> => (
  _.map(videoFiles, (videoFile) => (
    {
      title: prettyFileName(videoFile.file.name),
      source: {
        type: videoFile.file.type,
        fileName: videoFile.file.name,
        size: videoFile.file.size,
      },
      ...options,
    }
  ))
);

export {
  getVideoFileSize,
  getVideoFileName,
  prettyFileName,
  getVideoFileError,
  transformsVideoFilesToVideoPayload,
};
