import type { BadWordListsItem } from '@/modules/badWordList/types';
import qs from 'qs';
import { axiosAPI } from '@/plugins/axios';
import type { Filter } from '@/modules/shared/types/axios.type';

export const getBadWordLists = (filter?: Filter) => {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axiosAPI.get(`/bad-word-lists${query}`);
};

export const createBadWordListsItem = (payload: BadWordListsItem) => axiosAPI.post('/bad-word-lists', payload);
export const getBadWordListsItemById = (badWordListId: string) => axiosAPI.get(`/bad-word-lists/${badWordListId}`);
export const updateBadWordListsItemById = (badWordListId: string, payload: BadWordListsItem) => axiosAPI.put(`/bad-word-lists/${badWordListId}`, payload);
export const deleteBadWordListsItemById = (badWordListId: string) => axiosAPI.delete(`/bad-word-lists/${badWordListId}`);
