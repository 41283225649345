/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import _ from 'lodash';
import i18n from '@/plugins/lang/i18n';
import { axiosKeyServerAPI } from '@/plugins/axios';

import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';

import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { LoadingState, SavingState } from '@/modules/shared/types/state.type';
import {
  AttentionObject,
  KeyServer,
  KeyServerState,
  KeyServerTestResult,
  TestKeyServerResultPayload,
  UpdateKeyServerPayload,
} from '../types/KeyServer.type';
import { loadKeyServersAPI } from '../services/api';

type KeyServerActionContext = ActionContext<KeyServerState, RootState>

const loadKeyServerById = (keyServerId: string) => axiosKeyServerAPI.get(`/key-server/${keyServerId}`);
const createKeyServer = (payload: { keyServerName: string }) => axiosKeyServerAPI.post('/key-server', payload);
const updateKeyServerById = (keyServerId: string, payload: UpdateKeyServerPayload) => axiosKeyServerAPI.put(`/key-server/${keyServerId}`, payload);
const testKeyServerById = (keyServerId: string, payload: TestKeyServerResultPayload) => axiosKeyServerAPI.post(`/key-server/${keyServerId}/test`, payload);
const activateKeyServerById = (keyServerId: string) => axiosKeyServerAPI.put(`/key-server/${keyServerId}/active`);

const initialState = (): KeyServerState => ({
  currentKeyServer: null,
  isCreateKeyServerError: false,
  currentKeyServerTab: 'info',
  keyServers: [],
  keyServerActiveModal: null,
  currentPage: 0,
  lastPage: 0,
  createKeyServerState: {
    status: 'idle',
    error: null,
  },
  loadKeyServerState: {
    status: 'idle',
    error: null,
  },
  loadKeyServerListState: {
    status: 'idle',
    error: null,
  },
  updateKeyServerEndpointState: {
    status: 'idle',
    error: null,
  },
  keyServerTestState: 'notTested',
  keyServerTestResults: {
    cacheControl: null,
    contentLength: null,
    contentType: null,
    encryptionKeyPersisted: null,
    statusCode: null,
  },
  isKeyServerTestResultOK: false,
  shouldRedirect: false,
});

const state = initialState();

const getPlaybackEncryptionKeyRequestApiEndpoint = (state: KeyServerState) => (
  _.get(state, 'currentKeyServer.encryption.playbackEncryptionKeyRequestApiEndpoint', null)
);

const getEncodingEncryptionKeyRequestApiEndpoint = (state: KeyServerState) => (
  _.get(state, 'currentKeyServer.encryption.encodingEncryptionKeyRequestApiEndpoint', null)
);

const getters = {
  keyServerAttentionText: (state: KeyServerState) => {
    const attentionObject: AttentionObject = {};
    if (state.currentKeyServer) {
      if (_.isEmpty(getPlaybackEncryptionKeyRequestApiEndpoint(state))
        || _.isEmpty(getEncodingEncryptionKeyRequestApiEndpoint(state))) {
        attentionObject.icon = 'fas fa-triangle-exclamation';
        attentionObject.text = i18n.global.t('keyServers.attentionText.configureAPI');
        attentionObject.goToTab = 'settings';
        attentionObject.color = 'warning';
      } else if (state.currentKeyServer.status !== 'active') {
        if (state.keyServerTestState !== 'tested') {
          attentionObject.icon = 'fas fa-circle-info';
          attentionObject.text = i18n.global.t('keyServers.attentionText.test');
          attentionObject.goToTab = 'test';
          attentionObject.color = 'info';
        } else if (state.keyServerTestState === 'tested' && !state.isKeyServerTestResultOK) {
          attentionObject.icon = 'fas fa-xmark';
          attentionObject.text = i18n.global.t('keyServers.attentionText.error');
          attentionObject.goToTab = 'settings';
          attentionObject.color = 'danger';
        } else {
          attentionObject.icon = 'fas fa-check';
          attentionObject.text = i18n.global.t('keyServers.attentionText.ready');
          attentionObject.goToTab = 'test';
          attentionObject.color = 'success';
        }
      }
    }
    return attentionObject;
  },
  keyServerHasAllEndpoints: (state: KeyServerState) => (
    !_.isEmpty(getPlaybackEncryptionKeyRequestApiEndpoint(state)) && !_.isEmpty(getEncodingEncryptionKeyRequestApiEndpoint(state))
  ),
};

const mutations = {
  setCurrentKeyServers(state: KeyServerState, keyServers: KeyServer[]) {
    state.keyServers = keyServers;
  },
  setCurrentKeyServer(state: KeyServerState, keyServerObject: KeyServer) {
    state.currentKeyServer = keyServerObject;
    const encryption = _.get(keyServerObject, 'encryption');
    if (!encryption
      || _.isEmpty(encryption.playbackEncryptionKeyRequestApiEndpoint)
      || _.isEmpty(encryption.encodingEncryptionKeyRequestApiEndpoint)
    ) {
      state.currentKeyServer.encryption = {
        playbackEncryptionKeyRequestApiEndpoint: '',
        encodingEncryptionKeyRequestApiEndpoint: '',
      };
    }
  },
  destroyKeyServers(state: KeyServerState) {
    state.keyServers = [];
  },
  setCreateKeyServerState(state: KeyServerState, savingState: SavingState) {
    state.createKeyServerState = setStateSavingStatusByType(savingState);
  },
  setLoadKeyServerState(state: KeyServerState, loadingState: LoadingState) {
    state.loadKeyServerState = setStateLoadingStatusByType(loadingState);
  },
  setLoadKeyServerListState(state: KeyServerState, loadingState: LoadingState) {
    state.loadKeyServerListState = setStateLoadingStatusByType(loadingState);
  },
  setUpdateKeyServerEndpointStateMessage(state: KeyServerState, savingState: SavingState) {
    state.updateKeyServerEndpointState = setStateSavingStatusByType(savingState);
  },
  setKeyServerActiveModal(state: KeyServerState, modalName: string) {
    state.keyServerActiveModal = modalName;
  },
  setCreateKeyServerError(state: KeyServerState, boolean: boolean) {
    state.isCreateKeyServerError = boolean;
  },
  setKeyServerCurrentTab(state: KeyServerState, tabName: string) {
    state.currentKeyServerTab = tabName;
  },
  setKeyServerTesting(state: KeyServerState) {
    state.keyServerTestState = 'testing';
  },
  async setKeyServerTestResults(state: KeyServerState, results: KeyServerTestResult) {
    state.keyServerTestState = 'tested';
    if (results) {
      state.keyServerTestResults = {
        cacheControl: results.cacheControl.pass,
        contentLength: results.contentLength.pass,
        contentType: results.contentType.pass,
        encryptionKeyPersisted: results.encryptionKeyPersisted.pass,
        statusCode: results.statusCode.pass,
      };

      if (_.every(results, { pass: true })) {
        state.isKeyServerTestResultOK = true;
      }
    } else {
      state.keyServerTestResults = {
        cacheControl: false,
        contentLength: false,
        contentType: false,
        encryptionKeyPersisted: false,
        statusCode: false,
      };
      state.isKeyServerTestResultOK = false;
    }
  },
  async activateKeyServer(state: KeyServerState) {
    if (state.currentKeyServer) {
      await activateKeyServerById(state.currentKeyServer.id);
      state.shouldRedirect = true;
    }
  },
  resetAllKeyServerStates(state: KeyServerState) {
    Object.assign(state, _.cloneDeep(initialState));
  },
};

const actions = {
  async loadKeyServers({ commit }: KeyServerActionContext, filter: { q: string, encryptionType: 'clearkey' | 'model-k' }) {
    commit('setLoadKeyServerListState', { type: 'loading' });
    try {
      const response = await loadKeyServersAPI(filter);
      commit('setCurrentKeyServers', response.data.data);
      commit('setLoadKeyServerListState', { type: 'success' });
    } catch (error) {
      commit('setLoadKeyServerListState', { type: 'error', error: formatErrorObject(error, 'Key Server') });
    }
  },
  async loadKeyServer({ commit }: KeyServerActionContext, keyServerId: string) {
    commit('setLoadKeyServerState', { type: 'loading' });
    try {
      const response = await loadKeyServerById(keyServerId);
      commit('setCurrentKeyServer', response.data);
      commit('setLoadKeyServerState', { type: 'success' });
    } catch (error) {
      commit('setLoadKeyServerState', { type: 'error', error: formatErrorObject(error, 'Key Server') });
    }
  },
  async createKeyServer({ commit }: KeyServerActionContext, payload: { keyServerName: string}) {
    commit('setCreateKeyServerState', { type: 'loading' });
    try {
      const response = await createKeyServer(payload);
      commit('setCurrentKeyServer', response.data);
      commit('setCreateKeyServerState', { type: 'success' });
    } catch (error) {
      commit('setCreateKeyServerState', { type: 'error', error: formatErrorObject(error, 'Key Server') });
    }
  },
  async updateKeyServerEndpoints({ commit }: KeyServerActionContext, payload: UpdateKeyServerPayload) {
    commit('setUpdateKeyServerEndpointStateMessage', { type: 'loading' });
    try {
      const response = await updateKeyServerById(payload.id, payload);
      commit('setCurrentKeyServer', response.data);
      commit('setUpdateKeyServerEndpointStateMessage', { type: 'success' });
      commit('setKeyServerCurrentTab', 'test');
    } catch (error) {
      commit('setUpdateKeyServerEndpointStateMessage', { type: 'error', error: formatErrorObject(error, 'Key Server') });
    }
  },
  async testKeyServer({ commit }: KeyServerActionContext, payload: TestKeyServerResultPayload) {
    commit('setKeyServerTesting');
    try {
      const response = await testKeyServerById(payload.id, payload);
      commit('setKeyServerTestResults', response.data.tests);
    } catch (error) {
      commit('setKeyServerTestResults', false);
    }
  },
  activateKeyServer({ commit }: KeyServerActionContext) {
    commit('activateKeyServer');
  },
  setKeyServerActiveModal({ commit }: KeyServerActionContext, modalName: string) {
    commit('setKeyServerActiveModal', modalName);
  },
  setCreateKeyServerError({ commit }: KeyServerActionContext, boolean: boolean) {
    commit('setCreateKeyServerError', boolean);
  },
  destroyKeyServers({ commit }: KeyServerActionContext) {
    commit('destroyKeyServers');
  },
  resetAllKeyServerStates({ commit }: KeyServerActionContext) {
    commit('resetAllKeyServerStates');
  },
  setKeyServerCurrentTab({ commit }: KeyServerActionContext) {
    commit('setKeyServerCurrentTab');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
