import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "user-container" }
const _hoisted_2 = {
  key: 0,
  class: "membership-list"
}
const _hoisted_3 = { key: 1 }

import type { MembershipPayload, ProjectMember } from '@/modules/projectMember/types';
import type { ObjectWithStringValue } from '@/modules/shared/types/index.type';
import _ from 'lodash';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { transformDateToDisplay } from '@/modules/shared/utils/dateFormatter';
import ProjectManageMemberToasts from '@/modules/projectMember/components/molecules/projectManageMemberToasts/ProjectManageMemberToasts.vue';
import CreateInvitationLinkModal from '@/modules/projectMember/components/organisms/createInvitationLinkModal/CreateInvitationLinkModal.vue';
import { getQueryAsString } from '@/modules/shared/utils/query';
import Page from '@/layouts/Page.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import ProjectMembershipChangeRoleModal from '@/modules/projectMember/components/organisms/changeProjectAccessLevelModal/ChangeProjectAccessLevelModal.vue';
import DeleteProjectMembershipModal from '@/modules/projectMember/components/organisms/deleteProjectMemberModal/DeleteProjectMemberModal.vue';
import AddProjectManageMembershipModal from '@/modules/projectMember/components/organisms/addProjectMemberModal/AddProjectMemberModal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import Logo from '@/modules/shared/components/atoms/logo/Logo.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import Dropdown from '@/modules/shared/components/atoms/dropdown/Dropdown.vue';
import DropdownItem from '@/modules/shared/components/atoms/dropdownItem/DropdownItem.vue';
import {
  getIfIsUserActivated,
  getMembershipRolesOptions,
  getPrettyRoleName,
  getRoleBadgeStyle,
} from '../utils';
import { useStore } from '../store/hooks';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectManageMember',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const q = ref<string>('');
const isProjectManageMembershipModalShowing = ref<boolean>(false);
const deleteMember = ref<ProjectMember | null>(null);
const editMember = ref<ProjectMember | null>(null);
const memberToCreateNewInvitation = ref<ProjectMember | null>(null);
const updateSuccess = ref<boolean>(false);
const isShowCreateInvitationModal = ref<boolean>(false);

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const currentProject = computed(() => store.state.project.currentProject);
const updateProjectMembershipsState = computed(() => store.state.projectMember.updateProjectMembershipsState);
const memberships = computed(() => currentProject.value?.memberships ?? []);

const projectPermission = computed(() => (
  currentProject.value?.actions ?? {
    updateMembership: false,
  }
));

const filterMemberships = computed<ProjectMember[]>(() => {
  const members = memberships.value ?? [];

  if (q.value.length === 0) {
    return members;
  }

  return members.filter((member) => {
    if (member && member.account) {
      return member.account.name.toLowerCase().includes(q.value.toLowerCase());
    }
    return false;
  });
});

const isEmptyMemberShips = computed(() => _.isEmpty(memberships.value));
const isEmptySearchResult = computed(() => _.isEmpty(filterMemberships.value));
const amountOfMember = computed(() => _.size(memberships.value));
const isOneAdminLeft = computed(() => {
  const amountOfAdmin = _.size(_.filter(memberships.value, { projectRole: 'project-manager' }));
  return amountOfAdmin > 1;
});

const isProjectManageMembershipDeleteModalShowing = computed(() => deleteMember.value);
const isProjectManageMembershipChangeRoleModalShowing = computed(() => editMember.value);

function onCloseProjectManageMembershipChangeRoleModal() {
  editMember.value = null;
}

function onChangeMemberRole(member: ProjectMember) {
  editMember.value = member;
}

function onCloseProjectManageMembershipDeleteModal() {
  deleteMember.value = null;
}

function onDeleteMember(member: ProjectMember) {
  deleteMember.value = member;
}

function onFilterUser(event: Event) {
  if (event.target && event.target instanceof HTMLInputElement) {
    q.value = event.target.value;
  }
}

function onCloseProjectManageMembershipModal() {
  isProjectManageMembershipModalShowing.value = false;
}

async function onSubmitMemberShips(payload: MembershipPayload[]) {
  await store.dispatch('inviteAndAddMembers', {
    projectKey: projectKey.value,
    memberships: payload,
  });
  onCloseProjectManageMembershipModal();
}

function onToggleProjectManageMembershipModal() {
  isProjectManageMembershipModalShowing.value = !isProjectManageMembershipModalShowing.value;
}

function getRoleByValue(value: string): ObjectWithStringValue | undefined {
  return _.find(getMembershipRolesOptions(), (role) => role.value === value);
}

function isManagerOfProject(member: ProjectMember): boolean {
  return member?.projectRole === getRoleByValue('project-manager')?.value ?? false;
}

function canUpdateProjectMemberships(member: ProjectMember) {
  if (isManagerOfProject(member)) {
    return isOneAdminLeft.value && projectPermission.value.updateMembership;
  }
  return projectPermission.value.updateMembership;
}

function canDeleteProjectMemberships(member: ProjectMember) {
  return canUpdateProjectMemberships(member) && amountOfMember.value > 1;
}

function canAddProjectMemberships() {
  return projectPermission.value.updateMembership;
}

function onUpdateSuccess() {
  updateSuccess.value = true;
  const timer = setTimeout(() => {
    store.commit('setUpdateProjectMembershipsState', { type: 'idle' });
    updateSuccess.value = false;
    clearTimeout(timer);
  }, 3000);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Page, {
    title: _unref(t)('project.manage.members.title'),
    projectName: currentProject.value?.name ?? ''
  }, {
    default: _withCtx(() => [
      _createVNode(ProjectManageMemberToasts),
      (updateSuccess.value)
        ? (_openBlock(), _createBlock(Alert, {
            key: 0,
            alertText: _unref(t)('response:savedSuccessfully'),
            priority: "success"
          }, null, 8, ["alertText"]))
        : _createCommentVNode("", true),
      (isProjectManageMembershipChangeRoleModalShowing.value)
        ? (_openBlock(), _createBlock(ProjectMembershipChangeRoleModal, {
            key: 1,
            onSuccess: onUpdateSuccess,
            member: editMember.value,
            onCloseModal: onCloseProjectManageMembershipChangeRoleModal
          }, null, 8, ["member"]))
        : _createCommentVNode("", true),
      (isProjectManageMembershipDeleteModalShowing.value)
        ? (_openBlock(), _createBlock(DeleteProjectMembershipModal, {
            key: 2,
            onSuccess: onUpdateSuccess,
            member: deleteMember.value,
            onCloseModal: onCloseProjectManageMembershipDeleteModal
          }, null, 8, ["member"]))
        : _createCommentVNode("", true),
      (isProjectManageMembershipModalShowing.value)
        ? (_openBlock(), _createBlock(AddProjectManageMembershipModal, {
            key: 3,
            memberships: memberships.value,
            onSubmit: onSubmitMemberShips,
            onCloseModal: onCloseProjectManageMembershipModal,
            updateProjectMembershipsState: updateProjectMembershipsState.value
          }, null, 8, ["memberships", "updateProjectMembershipsState"]))
        : _createCommentVNode("", true),
      (isShowCreateInvitationModal.value)
        ? (_openBlock(), _createBlock(CreateInvitationLinkModal, {
            key: 4,
            member: memberToCreateNewInvitation.value,
            onCloseModal: () => isShowCreateInvitationModal.value = false
          }, null, 8, ["member", "onCloseModal"]))
        : _createCommentVNode("", true),
      _createVNode(Section, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (!isEmptyMemberShips.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (!isEmptySearchResult.value)
                    ? (_openBlock(), _createBlock(DataTable, {
                        key: 0,
                        dataTest: "project-manage-member-table",
                        responsiveLayout: "a",
                        onSearchData: onFilterUser,
                        searchInputPlaceholder: _unref(t)('common:form.searchByMemberName'),
                        searchInputValue: q.value
                      }, _createSlots({
                        "table-action": _withCtx(() => [
                          (canAddProjectMemberships())
                            ? (_openBlock(), _createBlock(Button, {
                                key: 0,
                                type: "button",
                                buttonStyle: "primary",
                                dataTest: "button-add-member",
                                isFullWidth: "",
                                onClick: onToggleProjectManageMembershipModal
                              }, {
                                "icon-prefix": _withCtx(() => _cache[0] || (_cache[0] = [
                                  _createElementVNode("i", { class: "fas fa-plus mr-1" }, null, -1)
                                ])),
                                content: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_unref(t)('project.manage.members.add')), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, [
                        (!isEmptySearchResult.value)
                          ? {
                              name: "table-header",
                              fn: _withCtx(() => [
                                _createVNode(Row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(Column, { width: "40px" }),
                                    _createVNode(Column, null, {
                                      default: _withCtx(() => [
                                        _createVNode(HeaderCell, {
                                          text: _unref(t)('project.manage.member.table.header.name')
                                        }, null, 8, ["text"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(Column, null, {
                                      default: _withCtx(() => [
                                        _createVNode(HeaderCell, {
                                          text: _unref(t)('project.manage.member.table.header.email')
                                        }, null, 8, ["text"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(Column, { width: "120px" }, {
                                      default: _withCtx(() => [
                                        _createVNode(HeaderCell, {
                                          text: _unref(t)('project.manage.member.table.header.role')
                                        }, null, 8, ["text"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(Column, { width: "100px" }, {
                                      default: _withCtx(() => [
                                        _createVNode(HeaderCell, {
                                          text: _unref(t)('project.manage.member.table.header.date')
                                        }, null, 8, ["text"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(Column, {
                                      width: "100px",
                                      class: "inviter-column"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(HeaderCell, {
                                          text: _unref(t)('project.manage.member.table.header.inviter')
                                        }, null, 8, ["text"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(Column, { width: "100px" }, {
                                      default: _withCtx(() => [
                                        _createVNode(HeaderCell, {
                                          text: _unref(t)('project.manage.member.table.header.note')
                                        }, null, 8, ["text"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(Column, { width: "50px" })
                                  ]),
                                  _: 1
                                })
                              ]),
                              key: "0"
                            }
                          : undefined,
                        (!isEmptySearchResult.value)
                          ? {
                              name: "table-content",
                              fn: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterMemberships.value, (member, index) => {
                                  return (_openBlock(), _createBlock(Row, {
                                    key: index,
                                    verticalAlign: "center"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(Column, {
                                        width: "40px",
                                        dataTest: `column-member-account-${index}`
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(Logo, {
                                            user: member.account
                                          }, null, 8, ["user"])
                                        ]),
                                        _: 2
                                      }, 1032, ["dataTest"]),
                                      _createVNode(Column, {
                                        isPrimaryColumn: "",
                                        dataTest: `column-member-name-${index}`
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(TextCell, {
                                            text: member.account.name
                                          }, null, 8, ["text"])
                                        ]),
                                        _: 2
                                      }, 1032, ["dataTest"]),
                                      _createVNode(Column, {
                                        dataTest: `column-member-email-${index}`
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(TextCell, {
                                            text: member.account.email
                                          }, null, 8, ["text"])
                                        ]),
                                        _: 2
                                      }, 1032, ["dataTest"]),
                                      _createVNode(Column, {
                                        width: "120px",
                                        dataTest: `column-member-role-${index}`
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(Badge, {
                                            title: _unref(getPrettyRoleName)(member.projectRole),
                                            size: "small",
                                            badgeStyle: _unref(getRoleBadgeStyle)(member.projectRole)
                                          }, null, 8, ["title", "badgeStyle"])
                                        ]),
                                        _: 2
                                      }, 1032, ["dataTest"]),
                                      _createVNode(Column, {
                                        width: "100px",
                                        dataTest: `column-member-added-at-${index}`
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(TextCell, {
                                            text: member.addedAt ? _unref(transformDateToDisplay)(member.addedAt) : '-'
                                          }, null, 8, ["text"])
                                        ]),
                                        _: 2
                                      }, 1032, ["dataTest"]),
                                      _createVNode(Column, {
                                        width: "100px",
                                        class: "inviter-column",
                                        dataTest: `column-member-added-by-${index}`
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(TextCell, {
                                            text: member.addedBy?.name || '-'
                                          }, null, 8, ["text"])
                                        ]),
                                        _: 2
                                      }, 1032, ["dataTest"]),
                                      _createVNode(Column, {
                                        width: "100px",
                                        dataTest: `column-member-note-${index}`
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(TextCell, {
                                            text: _unref(getIfIsUserActivated)(member) ? '-' : _unref(t)('project.manage.member.waitForActivate')
                                          }, null, 8, ["text"])
                                        ]),
                                        _: 2
                                      }, 1032, ["dataTest"]),
                                      _createVNode(Column, {
                                        width: "50px",
                                        isActionColumn: ""
                                      }, {
                                        default: _withCtx(() => [
                                          (member.account?.role !== 'admin')
                                            ? (_openBlock(), _createBlock(Dropdown, {
                                                key: 0,
                                                iconClassName: "fas fa-ellipsis-vertical",
                                                dataTest: `dropdown-${index}`
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(DropdownItem, {
                                                    dataTest: "dropdown-invite-link",
                                                    disabled: _unref(getIfIsUserActivated)(member),
                                                    onClick: () => {
                        isShowCreateInvitationModal.value = true;
                        memberToCreateNewInvitation.value = member;
                      }
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_unref(t)('project.manage.members.inviteLink.button')), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["disabled", "onClick"]),
                                                  _createVNode(DropdownItem, {
                                                    dataTest: "dropdown-edit-role",
                                                    disabled: !canUpdateProjectMemberships(member),
                                                    onClick: () => (canUpdateProjectMemberships(member) ? onChangeMemberRole(member):null)
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_unref(t)('project.manage.member.editRole')), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["disabled", "onClick"]),
                                                  _createVNode(DropdownItem, {
                                                    dataTest: "dropdown-remove-member",
                                                    disabled: !canDeleteProjectMemberships(member),
                                                    onClick: () => (canDeleteProjectMemberships(member) ? onDeleteMember(member):null)
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_unref(getIfIsUserActivated)(member) ? _unref(t)('project.manage.member.remove') : _unref(t)('project.manage.member.removeNonMember')), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["disabled", "onClick"])
                                                ]),
                                                _: 2
                                              }, 1032, ["dataTest"]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              key: "1"
                            }
                          : undefined
                      ]), 1032, ["searchInputPlaceholder", "searchInputValue"]))
                    : _createCommentVNode("", true),
                  (isEmptySearchResult.value)
                    ? (_openBlock(), _createBlock(EmptySearch, {
                        key: 1,
                        keyword: q.value,
                        onClearSearch: () => { q.value = '' }
                      }, null, 8, ["keyword", "onClearSearch"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (isEmptyMemberShips.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(MessageBox, {
                    title: _unref(t)('project.manage.members.noMember'),
                    onClickButton: onToggleProjectManageMembershipModal,
                    buttonTitle: _unref(t)('project.manage.members.add'),
                    buttonPrefixIconClassName: "fas fa-plus"
                  }, null, 8, ["title", "buttonTitle"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "projectName"]))
}
}

})