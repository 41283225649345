<template>
  <Teleport to="body">
    <Modal
      v-if="isShowDeleteBumperModal"
      @modalClose="onCloseDeleteModal"
      :onClickPrimaryButton="onDeleteBumper"
      :title="t('bumperSchedule.bumper.delete.title')"
      primaryButtonStyle="danger"
      :primaryButtonText="t('common:button.remove')"
      size="small">
      <template v-slot:body>
        <div class="delete-text" v-html="getConfirmDeleteText(selectedItem)" />
      </template>
    </Modal>
    <ChooseBumperModal
      v-if="isShowChooseBumperModal"
      @modalClose="onCloseBumperModal"
      :bumper="selectedItem"
      :categoryName="name"
      :disabledCategories="disabledBumperPosition"
      @submit="(submitValue) => onEditBumper(submitValue)" />
  </Teleport>
  <div class="bumper-draggable-group">
    <div :aria-label="name" class="bumper-group-title">
      <div class="title-container">
        <slot></slot>
      </div>
      <div class="source-option-container">
        <div class="source-option-title">
          Mode:
          <Tooltip>
            <i class="fas fa-info-circle"></i>
            <template v-slot:tooltip-text>
              <div class="mode-information">
                Mode<br />
                <ul class="mode-list">
                  <li><strong>{{t('bumperSchedule.schedule.disabled.title')}}</strong> - {{t('bumperSchedule.schedule.disabled.description')}}</li>
                  <li><strong>{{t('bumperSchedule.schedule.custom.title')}}</strong> - {{t('bumperSchedule.schedule.custom.description')}}</li>
                  <li><strong>{{t('bumperSchedule.schedule.parent.title')}}</strong> - {{t('bumperSchedule.schedule.parent.description')}}</li>
                </ul>
              </div>
            </template>
          </Tooltip>
        </div>
        <SelectOption
          :options="options"
          :modelValue="selectedBumperOption"
          deselectLabel="Current"
          @update:modelValue="onChangeBumperSourceType" />
      </div>
    </div>
    <Alert
      v-if="showSaveErrorAlertBox[name.toLowerCase().replace(/\s/g, '')]"
      priority="danger"
      :alertText="t('bumperSchedule.schedule.error.empty.title')"
      :description="t('bumperSchedule.schedule.error.empty.description')" />
    <div :class="{ hasParentBumper: 'disabled' }" class="list-container">
      <DataTable>
        <template v-slot:table-header v-if="(hasParentBumper && clonedValue.length) || clonedValue?.length">
          <Row>
            <Column width="50px" horizontal-align="center">
              <HeaderCell :text="t('common:order')" />
            </Column>
            <Column>
              <HeaderCell :text="name.split(' ')[0]" />
            </Column>
            <Column width="90px">
              <HeaderCell :text="t('common:form.status')" />
            </Column>
            <Column> &nbsp; </Column>
            <Column isActionColumn width="120px" />
          </Row>
        </template>
        <template v-slot:table-content>
          <Row v-if="hasParentBumper && currentBumperSourceType === 'parent'">
            <div class="disabled-notice">
              <div class="disabled-text">
                {{t('bumperSchedule.schedule.currentlyUsed', {position: name})}}
                <a :href="`/${teamName}/manage/bumper-insertion/${parentBumper?.bumperDisplaySourceId}`" target="_blank" class="external-link">
                  {{parentBumpersDisplaySource?.name}} <i class="fas fa-external-link-alt"></i>
                </a>
              </div>
            </div>
          </Row>
          <draggable
            :aria-label="name"
            :class="{ empty: !clonedValue?.length }"
            class="list-item-container no-hover-action"
            v-model="clonedValue"
            group="section"
            @end="(e: onMoveEvent) => onMove(e)"
            :disabled="currentBumperSourceType !== 'custom'"
            item-key="fieldName">
            <template #item="{element, index}">
              <Row verticalAlign="center">
                <Column width="50px" horizontal-align="center" class="order-column">
                  <i v-if="currentBumperSourceType === 'custom'" class="fas fa-grip-vertical"></i>
                  <i v-if="currentBumperSourceType === 'parent'" class="fas fa-lock"></i>
                </Column>
                <Column class="bumper-info-column" verticalAlign="center">
                  <template v-if="element.video">
                    <div class="video-container">
                      <div class="video-image">
                        <ImageView :source="getVideoUploadCoverImage({
                          video: element.video,
                          currentUser
                        })" imageSize="small" />
                        <div class="video-duration" v-if="element.video?.duration">
                          {{ getVideoDuration(element.video) }}
                        </div>
                      </div>
                      <div class="ellipsis-menu">
                        <Dropdown iconClassName="fas fa-ellipsis-v"></Dropdown>
                      </div>
                      <div class="bumper-title">
                        <div
                          @click="goToBumperContent(element)"
                          target="_blank">
                          {{ element.name }}
                        </div>
                        <div class="bumper-remark">{{ element.remark }}</div>
                      </div>
                    </div>
                  </template>
                </Column>
                <Column class="bumper-title-column">
                  <div class="bumper-title">
                    <div
                      @click="goToBumperContent(element)"
                      target="_blank">
                      {{ element.name }}
                    </div>
                    <div class="bumper-remark">{{ element.remark }}</div>
                  </div>
                </Column>
                <Column class="status-column" width="90px" verticalAlign="center">
                  <Badge
                    size="small"
                    :badgeStyle="getBadgeStyle(element)"
                    :title="element.visibility?.status" />
                </Column>
                <Column class="date-column">
                  <div class="dates">
                    <div>
                      <div class="start-date">
                        <span class="bumper-pad">{{t('bumper.start.title')}}</span>:
                        {{
                          element.visibilitySetting?.startPublishAt
                            ? formatVideoDateTime(element.visibilitySetting?.startPublishAt)
                            : '-'
                        }}
                      </div>
                      <div class="end-date">
                        <span class="bumper-pad">{{t('bumper.end.title')}}</span>:
                        {{
                          element.visibilitySetting?.endPublishAt
                            ? formatVideoDateTime(element.visibilitySetting?.endPublishAt)
                            : '-'
                        }}
                      </div>
                    </div>
                    <div v-if="calculateBumperPeriod(element)" class="day-badge">
                      {{ calculateBumperPeriod(element) }}
                    </div>
                  </div>
                </Column>
                <Column isActionColumn width="120px" vertical-align="center">
                  <Button
                    v-if="currentBumperSourceType === 'custom'"
                    buttonStyle="text-secondary"
                    @click.stop="onClickEdit(index, element)"
                    is-square>
                    <i class="fas fa-pen"></i>
                  </Button>
                  <Button
                    v-if="currentBumperSourceType === 'custom'"
                    buttonStyle="text-secondary"
                    @click.stop="onClickDelete(element, index)"
                    is-square>
                    <i class="fas fa-trash-alt"></i>
                  </Button>
                </Column>
              </Row>
            </template>
            <template v-slot:footer>
              <div v-if="!clonedValue?.length" class="empty-container">
                <div class="empty-title-container">
                  <p class="empty-title" v-if="currentBumperSourceType === 'parent'">{{t('bumperSchedule.schedule.parent.empty')}}</p>
                  <p class="empty-title" v-if="currentBumperSourceType !== 'parent'">{{t('bumperSchedule.schedule.custom.empty')}}</p>
                </div>
                <div class="empty-description-container" v-if="currentTab !== 'expired'">
                  <p v-if="currentBumperSourceType === 'custom'" class="empty-description">
                    {{t('bumperSchedule.position.pleaseAdd')}}
                    <Button @click="onClickAddBumper" buttonStyle="link-primary">
                      + {{t('bumper.add')}}
                    </Button>
                    {{t('bumperSchedule.position.addTo', {position: name})}}
                  </p>
                  <p v-if="currentBumperSourceType !== 'custom'" class="empty-description">
                    {{t('bumperSchedule.schedule.changeToCustom')}}
                  </p>
                </div>
              </div>
            </template>
          </draggable>
        </template>
      </DataTable>
    </div>
    <div
      class="add-button"
      v-if="clonedValue?.length && selectedBumperOption.sourceType === 'custom'">
      <Button @click="onClickAddBumper" buttonStyle="secondary">
        <template v-slot:icon-prefix>
          <i class="fas fa-plus"></i>
        </template>
        {{t('bumper.add')}}
        {{t('bumperSchedule.position.addTo', {position: name})}}
      </Button>
    </div>
  </div>
</template>

<script lang="ts" setup>

import { loadVideoByKey } from '@/modules/videoDetail/services';
import _ from 'lodash';
import draggable from 'vuedraggable';
import {
  computed, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { Bumper } from '@/modules/bumper/types';
import {
  BumperSource,
  ChooseBumperModalSubmitPayload,
  SummaryBumperContent,
} from '@/modules/bumperDisplay/types';
import { getQueryAsString } from '@/modules/shared/utils/query';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import { getVideoUploadCoverImage, getVideoDuration } from '@/modules/videoDetail/utils/videoManagement';
import { formatVideoDateTime } from '@/modules/shared/utils/dateFormatter';
import { useBumper } from '@/modules/bumper/utils';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import ChooseBumperModal from '@/modules/bumperDisplay/components/organisms/chooseBumperModal/ChooseBumperModal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import Dropdown from '@/modules/shared/components/atoms/dropdown/Dropdown.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import { useStore } from '../../../store/hooks';

interface BumperDraggableGroupProps {
  value: Array<Bumper> | null;
  name?: string;
  parentBumper?: SummaryBumperContent | null;
  inheritedBumpers?: Array<Bumper>;
}

interface SourceOption {
  name: string;
  sourceType: string;
}

const props = withDefaults(defineProps<BumperDraggableGroupProps>(), {
  name: 'Intro 1',
});

const {
  value,
  name,
  parentBumper,
  inheritedBumpers,
} = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [value: unknown];
  add: [];
}>();

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const clonedValue = ref<Array<Bumper>>([]);
const customBumperData = ref<Array<Bumper>>([]);
const selectedItem = ref<Bumper | null>(null);
const selectedIndex = ref<number>(-1);
const isShowChooseBumperModal = ref<boolean>(false);
const isShowDeleteBumperModal = ref<boolean>(false);

const options: Array<SourceOption> = [
  { name: t('bumperSchedule.schedule.disabled.title'), sourceType: 'disabled' },
  { name: t('bumperSchedule.schedule.custom.title'), sourceType: 'custom' },
  { name: t('bumperSchedule.schedule.parent.title'), sourceType: 'parent' },
];

const selectedBumperOption = ref<SourceOption>(options[0]);

const bumperSources = computed<BumperSource[]>(() => store.state.bumperDisplay.bumperSources);
const showSaveErrorAlertBox = computed(() => store.state.bumperDisplay.showSaveErrorAlertBox);
const currentUser = computed(() => store.state.user.currentUser);
const hasParentBumper = computed(() => !_.isEmpty(parentBumper?.value));
const currentBumperSourceType = computed(() => selectedBumperOption.value?.sourceType);
const bumperSourceObject = computed(() => (
  bumperSources.value?.find((source) => source.positionName === name.value.toLowerCase().replace(/\s/g, ''))
));
const parentBumpersDisplaySource = computed(() => parentBumper?.value?.bumperDisplaySource);
const disabledBumperPosition = computed(() => {
  const disabledBumperList: string[] = [];
  if (bumperSources.value.length) {
    bumperSources.value.forEach((bumper) => {
      if (bumper.sourceType === 'parent' || bumper.sourceType === 'disabled') {
        disabledBumperList.push(bumper.positionName);
      }
    });
  }
  return disabledBumperList;
});

const teamName = computed(() => route.params.teamName);
const currentTab = computed(() => getQueryAsString(route.query, 'tab'));

function calculateBumperPeriod(bumper: Bumper) {
  const { calculatePeriod } = useBumper();
  const result = calculatePeriod(bumper);
  return result && `${result} ${t(`glossary:day${result > 1 ? '_plural' : ''}`)}`;
}

async function goToBumperContent({ video }: Bumper) {
  if (video?.project) {
    window.open(`/${teamName.value}/projects/${video?.project?.key}/videos/${video?.key}/information`, '_blank');
  } else if (video?.key) {
    try {
      const { data } = await loadVideoByKey(video?.key, { includes: 'project' });
      const projectKey = data.project?.key;
      window.open(`/${teamName.value}/projects/${projectKey}/videos/${video?.key}/information`, '_blank');
    } catch (err) {
      // console.log(err);
    }
  }
}

function onClickAddBumper() {
  emit('add');
}

function getConfirmDeleteText(bumper: Bumper | null): string {
  if (!bumper) {
    return '';
  }

  let text = t('bumperSchedule.bumper.delete.confirmText', { bumperName: bumper.name });
  text = text.replace(`“${bumper.name}”`, `<strong>“${bumper.name}”</strong>`);
  return text;
}

function onClickDelete(item: Bumper, index: number) {
  isShowDeleteBumperModal.value = true;
  selectedItem.value = item;
  selectedIndex.value = index;
}

function onClickEdit(item: Bumper, index: number) {
  isShowChooseBumperModal.value = true;
  selectedItem.value = item;
  selectedIndex.value = index;
}

// eslint-disable-next-line no-shadow
function updateDataToStore(type = name.value, value: Array<Partial<Bumper>> = [...clonedValue.value]) {
  if (type === 'Intro 1' || type === 'intro1') {
    store.commit('setDisplayIntro1', value);
  }
  if (type === 'Intro 2' || type === 'intro2') {
    store.commit('setDisplayIntro2', value);
  }
  if (type === 'Outro 1' || type === 'outro1') {
    store.commit('setDisplayOutro1', value);
  }
  if (type === 'Outro 2' || type === 'outro2') {
    store.commit('setDisplayOutro2', value);
  }
}

function onDeleteBumper() {
  clonedValue.value = _.cloneDeep(value.value ?? []);
  clonedValue.value.splice(selectedIndex.value, 1);
  selectedItem.value = null;
  selectedIndex.value = -1;
  isShowDeleteBumperModal.value = false;
  updateDataToStore();
}

function onCloseDeleteModal() {
  isShowDeleteBumperModal.value = false;
  selectedItem.value = null;
}

function onCloseBumperModal() {
  isShowChooseBumperModal.value = false;
  selectedItem.value = null;
}

function onEditBumper(submitValue: ChooseBumperModalSubmitPayload) {
  const { bumper, type } = submitValue;
  // remove from its current type
  onDeleteBumper();

  // // add to new type
  const newType = store.state.bumperDisplay[`${type}`];

  updateDataToStore(type, [...newType, bumper]);
  isShowChooseBumperModal.value = false;
}

interface onMoveEvent {
  newIndex: number;
  oldIndex:number;
  item: Record<string, string>;
  from: Record<string, string>;
  to: Record<string, string>;
}

function onMove(e: onMoveEvent) {
  const { newIndex, oldIndex } = e;
  // eslint-disable-next-line no-underscore-dangle
  const element = e.item._underlying_vm_;
  const fromCategory = e.from?.ariaLabel;
  const toCategory = e.to?.ariaLabel;
  store.dispatch('onMoveDisplay', {
    fromCategory,
    toCategory,
    element,
    newIndex,
    oldIndex,
  });
}

function onChangeBumperSourceType(sourceObject: SourceOption) {
  selectedBumperOption.value = sourceObject;
  const bumperSourceType = sourceObject.sourceType;

  if (bumperSourceType === 'disabled') {
    clonedValue.value = [];
  }
  if (bumperSourceType === 'custom' && inheritedBumpers?.value) {
    const currentBumperId = value.value?.map((bumper) => bumper.id);
    const parentBumperId = inheritedBumpers.value.map((bumper) => bumper.id);
    const isParentBumperData = _.isEqual(currentBumperId, parentBumperId) && !_.isEmpty(currentBumperId) && !_.isEmpty(parentBumperId);

    // To remove parent bumper when change to custom choice.
    if (!isParentBumperData) {
      clonedValue.value = !_.isNil(customBumperData.value) && !_.isEmpty(customBumperData.value) ? customBumperData.value : value.value ?? [];
    } else {
      clonedValue.value = [];
    }
  }
  if (bumperSourceType === 'parent') {
    if (hasParentBumper.value && inheritedBumpers?.value) {
      clonedValue.value = inheritedBumpers.value;
    } else {
      clonedValue.value = [];
    }
  }

  updateDataToStore();
}

function getBadgeStyle(bumper: Bumper) {
  const { getStatusBadgeStyle } = useBumper();
  return getStatusBadgeStyle(bumper);
}

watch(value, () => {
  clonedValue.value = _.cloneDeep(value.value ?? []);
  store.dispatch('setShowSaveErrorAlertBox', { positionName: name.value.toLowerCase().replace(/\s/g, ''), value: false });
}, { deep: true, immediate: true });

watch(bumperSourceObject, () => {
  if (bumperSourceObject.value) {
    selectedBumperOption.value = _.find(
      options,
      { sourceType: bumperSourceObject.value?.sourceType },
    ) ?? options[0];
  }
}, { deep: true, immediate: true });

watch(currentBumperSourceType, (newType, oldType) => {
  const payload = {
    positionName: name.value.toLowerCase().replace(/\s/g, ''),
    sourceType: newType,
  };
  store.dispatch('setBumperSourceByPositionName', payload);
  if (oldType === 'custom') {
    // save selected custom data
    customBumperData.value = _.cloneDeep(value.value ?? []);
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/breakpoint';

.bumper-group-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: $grey-800;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: $spacing-24;
  width: 100%;

  .title-container {
    :slotted(*) {
      color: $grey-400;
    }

    :slotted(.fa-info-circle) {
      margin-left: $spacing-8;
    }

  }

  :slotted(.tooltip-text) {
    position: absolute;
    top: 32px;
    left: 0;
    visibility: hidden;
    width: 380px;
    background-color: $grey-900;
    color: #fff;
    border-radius: 3px;
    padding: 8px 16px;
    z-index: 1;

    .bumper-feature {
      padding-inline-start: 0;
      padding-left: 14px;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 58px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $grey-900 transparent;
    }

    &.outro::after {
      left: 65px;
    }
  }

  :slotted(.fas):hover~.tooltip-text {
    visibility: visible;
  }

  .btn-reset-to-default {
    color: $ci-primary;
    padding: 0;
    text-decoration: underline;
    text-underline-offset: 20%;
  }
}

.source-option-container {
  display: flex;
  align-items: center;
  width: 300px;
  font-size: $font-size-base;
  font-weight: normal;

  .source-option-title {
    white-space: nowrap;
    margin-right: $spacing-8;
    font-size: $font-level-8;
  }

  .fa-info-circle {
    color: $grey-400;
  }
}

:deep(.popper) {
  max-width: 350px !important;
}

:deep(.mode-information) {
  text-align: left;
  font-size: $font-size-base;
  .mode-list {
    padding-left: 16px;
    max-width: 100%;
    overflow: hidden;
    white-space: normal;
    margin-top: $spacing-4;
  }
}

:deep(.delete-text) {
  white-space: break-spaces;
}

:deep(.list-body-container) .list-item-container {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  background-color: #fbfbfc !important;
  cursor: move;

  &.empty {
    display: block;
    cursor: default;
  }
}

.disabled {
  :deep(.list-body-container) .list-item-container {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .list-item-actions {
    opacity: 0;
  }
}

.day-badge {
  background-color: #6a7383;
  color: #fff;
  border-radius: 3px;
  padding: 2px 6px 0;
  font-size: 12px;
  margin-left: 24px;
  height: fit-content;
}

.status-dot {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin-right: 8px;

  &.active {
    background-color: #3fb772;
  }

  &.scheduled {
    background-color: #f0b542;
  }

  &.expired {
    background-color: #d0d4d8;
  }
}

.video-image {
  cursor: pointer;
  position: relative;
  flex: 0 0 86px;

  .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    color: $grey-200;
    font-size: $font-level-7;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 4px 6px;
  }
}

.order-column {
  height: unset;
  color: $grey-400;
}

.video-container {
  display: flex;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  gap: $spacing-12;
  align-items: center;
}

.bumper-title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  p{
    &:hover{
      text-decoration: underline;
      text-underline-offset: 20%;
    }
  }
}

.ellipsis-menu {
  display: none;
}

.column.bumper-title-column {
  display: none;
}

.status-column {
  text-transform: capitalize;
}

.empty-container {
  border-radius: 3px;
  padding: 32px;
  text-align: center;
  background: $grey-100;

  .empty-title {
    color: $grey-800;
    font-weight: 500;
    font-size: $font-level-7;
    margin-bottom: 8px;
  }

  .empty-description {
    color: $grey-600;
    display: flex;
    align-items: center;
    justify-content: center;

    :deep(.button) {
      margin: 0 $spacing-4;
      margin-top: 2px;
    }
  }
}

.disabled-notice {
  display: flex;
  justify-content: space-between;
  cursor: default !important;
  background-color: $grey-200;
  padding: 7.5px 16px;
  color: $grey-600;
  flex: 1 0 auto;

  .external-link {
    color: $grey-600;
    text-decoration: underline;
    text-underline-offset: 20%;
  }
}

.dates {
  display: flex;
  width: 300px;
  align-items: center;
}

.bumper-remark {
  margin-top: $spacing-4;
  font-size: $font-level-8;
}

.add-button{
  width: 100%;
  display:flex;
  justify-content: flex-end;
  margin-top: $spacing-8;
}

@media screen and (max-width: $max-layout-md) {
  .bumper-group-title {
    flex-direction: column;
    align-items: flex-start;
  }
  .source-option-container {
    width: 100%;
    margin-top: $spacing-base;
  }

  .empty-description{
    flex-direction: column;
  }
  :deep(.table-header .row) {
    display: grid;
    grid-template-columns: 64px auto;
    grid-auto-rows: fit-content;

    .column {
      &:nth-child(n + 3) {
        display: none;
      }
    }
  }

  :deep(.table-content) {

    .disabled-notice {
      display: grid;
      width: 100%;
    }

    .list-item-container {
      .row {
        display: grid;
        grid-template-columns: 64px 1fr;
        grid-auto-rows: fit-content;

        .column {
          &:last-child {
            display: none;
          }

          min-height: auto;
          padding-top: 0px;
          margin-top: 0px;
          margin-bottom: 0px;

          &:nth-child(2) {
            margin-top: $spacing-base;
            margin-bottom: $spacing-12;
          }

          &:nth-child(3) {
            margin-bottom: $spacing-base;
          }

          &:nth-child(4) {
            margin-bottom: $spacing-4;
          }

          &:nth-child(5) {
            margin-bottom: $spacing-12;
            min-width: unset;
          }
        }
      }
    }
  }

  .order-column {
    grid-row-start: 1;
    grid-row-end: 5;
    display: flex;
    height: 100%;
  }

  .bumper-info-column {
    grid-column-start: 2;

    .bumper-title, .bumper-remark {
      display: none;
    }

    .video-container {
      justify-content: space-between;
      align-items: flex-start;
    }

    .ellipsis-menu {
      display: block;
    }
  }

  .column.bumper-title-column {
    display: flex;
  }

  .status-column {
    grid-column-start: 2;
  }

  .date-column {
    grid-column-start: 2;
    grid-column-end: 3;

    .dates {
      min-width: fit-content;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
    }

    .day-badge{
      margin-left: 0px;
      margin-top: $spacing-4;
    }
  }
}
</style>
