import type {
  BadWordListsItem,
  BadWordListsItemState,
  UpdateBadWordListsItemPayload,
} from '@/modules/badWordList/types';
import { ActionContext } from 'vuex';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { RootState } from '@/store/type';
import { LoadingState } from '@/modules/shared/types/state.type';
import {
  getBadWordListsItemById,
  createBadWordListsItem,
  updateBadWordListsItemById,
  deleteBadWordListsItemById,
} from '../services';

type BadWordListsItemActionContext = ActionContext<BadWordListsItemState, RootState>;

const initialState = (): BadWordListsItemState => ({
  currentBadWordListsItem: null,
  loadCurrentBadWordListsItemState: setStateLoadingStatusByType(),
  saveBadWordListsItemState: setStateLoadingStatusByType(),
});

const state = initialState();

const mutations = {
  setCurrentBadWordListsItem(state: BadWordListsItemState, badWordListsItem: BadWordListsItem) {
    state.currentBadWordListsItem = badWordListsItem;
  },
  setLoadCurrentBadWordListsItemState(state: BadWordListsItemState, loadingState: LoadingState) {
    state.loadCurrentBadWordListsItemState = setStateLoadingStatusByType(loadingState);
  },
  setSaveBadWordListsItemState(state: BadWordListsItemState, loadingState: LoadingState) {
    state.saveBadWordListsItemState = setStateLoadingStatusByType(loadingState);
  },
  destroyCurrentBadWordListsItem(state: BadWordListsItemState) {
    Object.assign(state, initialState);
  },
};

const actions = {
  async loadCurrentBadWordListsItem({ commit }: BadWordListsItemActionContext, { id }: { id: string }) {
    commit('setSaveBadWordListsItemState', { type: 'idle' });
    try {
      commit('setLoadCurrentBadWordListsItemState', { type: 'loading' });
      const response = await getBadWordListsItemById(id);
      commit('setCurrentBadWordListsItem', response.data);
      commit('setLoadCurrentBadWordListsItemState', { type: 'success' });
    } catch (error) {
      commit('setLoadCurrentBadWordListsItemState', { type: 'error', error: formatErrorObject(error, 'BadWordListsItem') });
    }
  },
  async createBadWordListItem({ commit }: BadWordListsItemActionContext, payload: BadWordListsItem) {
    commit('setSaveBadWordListsItemState', { type: 'loading' });
    try {
      const response = await createBadWordListsItem(payload);
      commit('setCurrentBadWordListsItem', response.data);
      commit('setSaveBadWordListsItemState', { type: 'success' });
    } catch (error) {
      commit('setSaveBadWordListsItemState', { type: 'error', error: formatErrorObject(error, 'badWordListsItem') });
    }
  },
  async saveBadWordListItem({ commit }: BadWordListsItemActionContext, { id, payload }: UpdateBadWordListsItemPayload) {
    commit('setSaveBadWordListsItemState', { type: 'loading' });
    try {
      const response = await updateBadWordListsItemById(id, payload);
      commit('setCurrentBadWordListsItem', response.data);
      commit('setSaveBadWordListsItemState', { type: 'success' });
    } catch (error) {
      commit('setSaveBadWordListsItemState', { type: 'error', error: formatErrorObject(error, 'badWordListsItem') });
    }
  },
  async deleteBadWordListItem({ commit }: BadWordListsItemActionContext, { id }: { id: string }) {
    commit('setSaveBadWordListsItemState', { type: 'loading' });
    try {
      await deleteBadWordListsItemById(id);
      commit('setSaveBadWordListsItemState', { type: 'success' });
    } catch (error) {
      commit('setSaveBadWordListsItemState', { type: 'error', error: formatErrorObject(error, 'badWordListsItem') });
    }
  },
  destroySaveBadWordListsItemState({ commit }: BadWordListsItemActionContext) {
    commit('setSaveBadWordListsItemState', { type: 'idle' });
  },
  destroyCurrentBadWordListsItem({ commit }: BadWordListsItemActionContext) {
    commit('destroyCurrentBadWordListsItem');
  },
};

export default {
  state,
  mutations,
  actions,
};
