import { SavingState, LoadingState, StatusState } from '@/modules/shared/types/state.type';

export const setStateSavingStatusByType = (savingState: SavingState = { type: 'idle' }): StatusState => {
  switch (savingState.type) {
    case 'saving':
      return {
        status: 'saving',
        error: null,
      };
    case 'success':
      return {
        status: 'success',
        error: null,
      };
    case 'error':
      return {
        status: 'error',
        error: savingState.error || null,
      };
    default:
      return {
        status: 'idle',
        error: null,
      };
  }
};

export const setStateLoadingStatusByType = (loadingState: LoadingState = { type: 'idle' }): StatusState => {
  switch (loadingState.type) {
    case 'loading':
      return {
        status: 'loading',
        error: null,
      };
    case 'success':
      return {
        status: 'success',
        error: null,
      };
    case 'error':
      return {
        status: 'error',
        error: loadingState.error || null,
      };
    default:
      return {
        status: 'idle',
        error: null,
      };
  }
};
